import { all } from "typed-redux-saga";
import userSagas from "./User/sagas";
import talentSagas from "./Talent/sagas";
import requestorSagas from "./Requestor/sagas";
import activationCodeSagas from "./ActivationCode/sagas";
import applicationSagas from "./Application/sagas";
import categorySagas from "./Category/sagas";
import consumerSagas from "./Consumer/sagas";
import bookingSagas from "./Booking/sagas";
import refundSagas from "./Refund/sagas";
import payoutSagas from "./Payout/sagas";
import couponSagas from "./Coupon/sagas";
import specialRequestSagas from "./SpecialRequest/sagas";
import experienceSagas from "./Experience/sagas";
import commonSagas from "./Common/sagas";
import productSagas from "./Product/sagas";
import tagSagas from "./Tags/sagas";
import timeRangeSagas from "./TimeRange/sagas";

export default function* rootSaga(): IterableIterator<any> {
  yield* all([
    userSagas(),
    talentSagas(),
    requestorSagas(),
    activationCodeSagas(),
    applicationSagas(),
    categorySagas(),
    consumerSagas(),
    bookingSagas(),
    refundSagas(),
    payoutSagas(),
    couponSagas(),
    specialRequestSagas(),
    experienceSagas(),
    commonSagas(),
    productSagas(),
    tagSagas(),
    timeRangeSagas(),
  ]);
}
