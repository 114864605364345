import { Experience } from "redux/Experience/types";
import { Api } from "services/api";
import { Response } from "../../redux/Common/types";

export default class ExperienceCommonService {
  constructor(protected api: Api) {
    this.api = api;
  }

  bulkUpdateExperiences = (
    payload: Experience[]
  ): Promise<Response<Experience[]>> => {
    return this.api.bulkUpdateExperiences(payload);
  };
}
