import * as React from "react";
import Typography from "antd/lib/typography";
import { TextProps as TextAntdProps } from "antd/lib/typography/Text";
import { TypographyPresets, presets } from "./Preset";
const TextAntd = Typography.Text;
interface TextProps extends TextAntdProps {
  preset?: TypographyPresets;
  className?: string;
}

export const Text = (props: TextProps) => {
  const { preset, className, ...rest } = props;
  const namePreset = preset && presets[preset];
  const mainClass = `text ${namePreset ? namePreset : ""} ${className}`;
  return <TextAntd className={mainClass} {...rest} />;
};
