import { Col, Input, Menu, Row, Typography } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTypedSelector } from "redux/rootReducer";
import { logoutActions } from "redux/User/actions";
import { selectUserData } from "redux/User/selector";
import "./MainHeader.scss";

const { Search } = Input;
const { Text } = Typography;

type MainHeaderProps = {
  isDarkMode?: boolean;
  isPhoneLayout?: boolean;
  isExperienceFlow?: boolean;
};

const MainHeader = ({
  isDarkMode,
  isPhoneLayout,
  isExperienceFlow,
}: MainHeaderProps) => {
  const history = useHistory();
  const user = useTypedSelector(selectUserData);
  const dispatch = useDispatch();

  const menu = (
    <Menu className="main-header__dropdown-menu">
      <Menu.Item
        onClick={() => {
          dispatch(logoutActions.REQUEST());
          history.push("/signin");
        }}
      >
        <Text className="text--red">Logout</Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Row className="main-header" align="middle">
        <Col className="d--flex cursor-pointer m__r--60">
          <img
            onClick={() => history.push("/")}
            src={"/images/logo.svg"}
            alt="logo"
          />
        </Col>
        {/* <Col flex="auto">
          <Row align="middle" justify="space-between">
            <Col flex="1">
              {!isPhoneLayout && (
                <Search className="m__l--24" placeholder="Search" />
              )}
            </Col>
            {user ? (
              <Col className={isDarkMode ? "dark-mode" : ""}>
                <Row align="middle">
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <Button type="text" onClick={e => e.preventDefault()}>
                      <AvatarProfile
                        avatar={user?.talentProfile?.avatar || ""}
                        name={`${user?.talentProfile?.firstName || ""} ${
                          user?.lastName || ""
                        }`}
                        showInfo={isPhoneLayout}
                      />
                    </Button>
                  </Dropdown>
                </Row>
              </Col>
            ) : (
              <Col>
                <Row gutter={45}>
                  <Col>
                    <Button type="text" onClick={() => history.push("/signin")}>
                      Login
                    </Button>
                  </Col>
                  <Col>
                    <Button type="primary">Join Today</Button>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Col> */}
      </Row>
    </>
  );
};

export default MainHeader;
