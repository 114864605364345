import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { specialRequestService } from "services";
import {
  acceptSpecialRequestActions,
  declineSpecialRequestActions,
  getListSpecialRequestsActions,
  getSpecialRequestByIdActions,
} from "./actions";
import { GetSpecialRequests } from "./types";
import { Booking } from "redux/Booking/types";

function* getListSpecialRequests({ payload }: Action<GetSpecialRequests>) {
  const result: any = yield* call(specialRequestService.getList, payload);
  if (result.ok) {
    yield* put(getListSpecialRequestsActions.SUCCESS(result.response));
  } else {
    yield* put(getListSpecialRequestsActions.FAILURE(result.message));
  }
}

function* getSpecialRequestById({ payload }: Action<string>) {
  const result: any = yield* call(specialRequestService.getById, payload);
  if (result.ok) {
    yield* put(getSpecialRequestByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getSpecialRequestByIdActions.FAILURE(result.message));
  }
}

function* acceptRequest({ payload }: Action<Booking["id"]>) {
  const result: any = yield* call(specialRequestService.accept, payload);
  if (result.ok) {
    yield* put(acceptSpecialRequestActions.SUCCESS(result.response));
  } else {
    yield* put(acceptSpecialRequestActions.FAILURE(result.message));
  }
}

function* declineRequest({ payload }: Action<Booking>) {
  const result: any = yield* call(specialRequestService.decline, payload);
  if (result.ok) {
    yield* put(declineSpecialRequestActions.SUCCESS(result.response));
  } else {
    yield* put(declineSpecialRequestActions.FAILURE(result.message));
  }
}

export default function* specialRequestSagas(): SagaIterator {
  yield* takeLatest(
    getListSpecialRequestsActions.REQUEST,
    getListSpecialRequests
  );
  yield* takeLatest(
    getSpecialRequestByIdActions.REQUEST,
    getSpecialRequestById
  );
  yield* takeLatest(acceptSpecialRequestActions.REQUEST, acceptRequest);
  yield* takeLatest(declineSpecialRequestActions.REQUEST, declineRequest);
}
