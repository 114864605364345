import {
  Booking,
  GetBookingRequest,
  RejectBookingRequest,
} from "redux/Booking/types";
import { Pagination, Response } from "../../redux/Common/types";
import { Api } from "../api";

export default abstract class BookingService {
  constructor(protected api: Api) {
    this.api = api;
  }
  abstract getBooking(
    payload: GetBookingRequest
  ): Promise<Response<Pagination<Booking[]>>>;

  abstract getBookingById(payload: string): Promise<Response<Booking>>;

  abstract rejectBooking(
    payload: RejectBookingRequest
  ): Promise<Response<Booking>>;
}
