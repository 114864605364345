import { Action, handleActions } from "redux-actions";
import {
  createEcommerceActions,
  updateEcommerceActions,
  getEcommerceByIdActions,
  deleteEcommerceByIdActions,
  updateEcommerceStateActions,
} from "./actions";
import { ProductState } from "./types";

const handlers = {
  // create e_commerce
  [createEcommerceActions.REQUEST]: (state: ProductState): ProductState => ({
    ...state,
    error: null,
    loading: true,
    productDetail: null,
  }),
  [createEcommerceActions.SUCCESS]: (
    state: ProductState,
    { payload }: Action<any>
  ): ProductState => ({
    ...state,
    productDetail: payload,
    loading: false,
  }),
  [createEcommerceActions.FAILURE]: (
    state: ProductState,
    { payload }: Action<any>
  ): ProductState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // update e_commerce
  [updateEcommerceActions.REQUEST]: (state: ProductState): ProductState => ({
    ...state,
    error: null,
    loading: true,
  }),
  [updateEcommerceActions.SUCCESS]: (
    state: ProductState,
    { payload }: Action<any>
  ): ProductState => ({
    ...state,
    loading: false,
    productDetail: payload,
  }),
  [updateEcommerceActions.FAILURE]: (
    state: ProductState,
    { payload }: Action<any>
  ): ProductState => ({
    ...state,
    error: payload,
    loading: false,
  }),

  // get e_commerce by id
  [getEcommerceByIdActions.REQUEST]: (state: ProductState): ProductState => ({
    ...state,
    error: null,
    loading: true,
    productDetail: null,
    fetchProductDetail: null,
  }),
  [getEcommerceByIdActions.SUCCESS]: (
    state: ProductState,
    { payload }: Action<any>
  ): ProductState => ({
    ...state,
    loading: false,
    fetchProductDetail: payload,
  }),
  [getEcommerceByIdActions.FAILURE]: (
    state: ProductState,
    { payload }: Action<any>
  ): ProductState => ({
    ...state,
    error: payload,
    loading: false,
  }),

  // delete e_commerce by id
  [deleteEcommerceByIdActions.REQUEST]: (
    state: ProductState
  ): ProductState => ({
    ...state,
    error: null,
    loading: true,
    status: null,
  }),
  [deleteEcommerceByIdActions.SUCCESS]: (
    state: ProductState,
    { payload }: Action<any>
  ): ProductState => ({
    ...state,
    loading: false,
    status: payload,
  }),
  [deleteEcommerceByIdActions.FAILURE]: (
    state: ProductState,
    { payload }: Action<any>
  ): ProductState => ({
    ...state,
    error: payload,
    loading: false,
  }),

  [updateEcommerceStateActions.REQUEST]: (
    state: ProductState,
    { payload }: Action<any>
  ) => ({
    ...state,
    payload,
  }),
};

const initialState: ProductState = {
  error: null,
  loading: false,
  productDetail: null,
  status: null,
  fetchProductDetail: null,
  products: null,
};

export const productReducer = handleActions<ProductState>(
  handlers,
  initialState
);
