import { Response, ListQueryParams } from "../../redux/Common/types";
import { Api } from "../api";
import {
  Contents1To1Request,
  UpdateExperience1To1Request,
  GetExperienceByIdRequest,
} from "../../redux/Experience/types";

export default abstract class ExperienceService {
  constructor(protected api: Api) {
    this.api = api;
  }

  abstract getAllExperiences(
    userId: string,
    params: ListQueryParams
  ): Promise<Response<any>>;

  abstract getExperiences(
    userId: string,
    params: ListQueryParams
  ): Promise<Response<any>>;

  abstract getExperienceById(
    payload: GetExperienceByIdRequest
  ): Promise<Response<any>>;

  abstract createExperience(
    payload: Contents1To1Request
  ): Promise<Response<any>>;
  
  abstract updateExperience(
    payload: UpdateExperience1To1Request
  ): Promise<Response<any>>;

  abstract publishExperience(payload: string): Promise<Response<any>>;
}
