import { Category } from "redux/Category/types";
import { Pagination } from "redux/Common/types";
import { Talent } from "redux/Talent/types";

export type ApplicationType = Talent["talentProfile"] & {
  firstName: string;
  lastName: string;
  id: string;
  email: string;
  username: string;
  categories: Category[];
  activationCode: string;
};

export enum ApplicationStatusType {
  ALL = "all",
  NEW = "new",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  REJECTED = "rejected",
}

export type ApplicationState = {
  applications?: Pagination<Talent[]>;
  application?: Talent;
  error?: string;
  loading: boolean;
  type?: string;
};
