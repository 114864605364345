import { Action, handleActions } from "redux-actions";
import {
  createActivationCodeActions,
  createActivationCodeTypes,
  getActivationCodeByIdActions,
  getActivationCodeByIdTypes,
  getListActivationCodeActions,
  getListActivationCodeTypes,
  getTalentsOfActivationCodeActions,
  updateActivationCodeByIdActions,
  updateActivationCodeByIdTypes,
} from "./actions";
import { ActivationCodeState } from "./types";

const handlers = {
  // get list activation code
  [getListActivationCodeActions.REQUEST]: (
    state: ActivationCodeState
  ): ActivationCodeState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [getListActivationCodeActions.SUCCESS]: (
    state: ActivationCodeState,
    { payload }: Action<any>
  ): ActivationCodeState => ({
    ...state,
    listActivationCode: payload,
    loading: false,
    type: getListActivationCodeTypes.SUCCESS,
  }),
  [getListActivationCodeActions.FAILURE]: (
    state: ActivationCodeState,
    { payload }: Action<any>
  ): ActivationCodeState => ({
    ...state,
    error: payload,
    loading: false,
    type: getListActivationCodeTypes.FAILURE,
  }),
  // get list talents of activation code
  [getTalentsOfActivationCodeActions.REQUEST]: (
    state: ActivationCodeState
  ): ActivationCodeState => ({
    ...state,
    error: undefined,
    loadingTalents: true,
  }),
  [getTalentsOfActivationCodeActions.SUCCESS]: (
    state: ActivationCodeState,
    { payload }: Action<any>
  ): ActivationCodeState => ({
    ...state,
    talents: payload,
    loadingTalents: false,
  }),
  [getTalentsOfActivationCodeActions.FAILURE]: (
    state: ActivationCodeState,
    { payload }: Action<any>
  ): ActivationCodeState => ({
    ...state,
    error: payload,
    loadingTalents: false,
  }),
  // get activation code by id
  [getActivationCodeByIdActions.REQUEST]: (
    state: ActivationCodeState
  ): ActivationCodeState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [getActivationCodeByIdActions.SUCCESS]: (
    state: ActivationCodeState,
    { payload }: Action<any>
  ): ActivationCodeState => ({
    ...state,
    activationCode: payload,
    type: getActivationCodeByIdTypes.SUCCESS,
    loading: false,
  }),
  [getActivationCodeByIdActions.FAILURE]: (
    state: ActivationCodeState,
    { payload }: Action<any>
  ): ActivationCodeState => ({
    ...state,
    error: payload,
    loading: false,
    type: getActivationCodeByIdTypes.FAILURE,
  }),
  // update activation code by id
  [updateActivationCodeByIdActions.REQUEST]: (
    state: ActivationCodeState
  ): ActivationCodeState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [updateActivationCodeByIdActions.SUCCESS]: (
    state: ActivationCodeState,
    { payload }: Action<any>
  ): ActivationCodeState => ({
    ...state,
    activationCode: payload,
    loading: false,
    type: updateActivationCodeByIdTypes.SUCCESS,
  }),
  [updateActivationCodeByIdActions.FAILURE]: (
    state: ActivationCodeState,
    { payload }: Action<any>
  ): ActivationCodeState => ({
    ...state,
    error: payload,
    loading: false,
    type: updateActivationCodeByIdTypes.FAILURE,
  }),
  // create activation code by id
  [createActivationCodeActions.REQUEST]: (
    state: ActivationCodeState
  ): ActivationCodeState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [createActivationCodeActions.SUCCESS]: (
    state: ActivationCodeState,
    { payload }: Action<any>
  ): ActivationCodeState => ({
    ...state,
    activationCode: payload,
    loading: false,
    type: createActivationCodeTypes.SUCCESS,
  }),
  [createActivationCodeActions.FAILURE]: (
    state: ActivationCodeState,
    { payload }: Action<any>
  ): ActivationCodeState => ({
    ...state,
    error: payload,
    loading: false,
    type: createActivationCodeTypes.FAILURE,
  }),
};

const initialState: ActivationCodeState = {
  listActivationCode: undefined,
  activationCode: undefined,
  talents: undefined,
  error: undefined,
  loading: false,
  loadingTalents: false,
};

export const activationCodeReducer = handleActions<ActivationCodeState>(
  handlers,
  initialState
);
