import { Response, ListQueryParams } from "./../redux/Common/types";
import { Api } from "./api";

interface ITagService {
  getTags(payload: ListQueryParams): Promise<Response<any>>;
}

export default class TagService implements ITagService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getTags = (
    payload: ListQueryParams & Required<{ search: string }>
  ): Promise<Response<any>> => {
    return this.api.getTags(payload);
  };
}
