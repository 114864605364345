import React, { ReactNode, Suspense } from "react";
import { Layout } from "antd";
import Loading from "components/Loading";

const { Content } = Layout;

interface IProps {
  children: ReactNode;
}
const EmptyLayout = ({ children }: IProps) => {
  return (
    <Layout className="empty-layout">
      <Suspense fallback={<Loading />}>
        <Content>{children}</Content>
      </Suspense>
    </Layout>
  );
};

export default EmptyLayout;
