import {
  getListRefundActions,
  getRefundByIdActions,
  acceptRefundActions,
  declineRefundActions,
  getListRefundTypes,
  getRefundByIdTypes,
  acceptRefundTypes,
  declineRefundTypes,
} from "./actions";
import { Action, handleActions } from "redux-actions";
import { RefundState } from "./types";

const handlers = {
  // get list refunds
  [getListRefundActions.REQUEST]: (state: RefundState): RefundState => ({
    ...state,
    refunds: null,
    loading: true,
    type: null,
  }),
  [getListRefundActions.SUCCESS]: (
    state: RefundState,
    { payload }: Action<any>
  ): RefundState => ({
    ...state,
    refunds: payload,
    loading: false,
    type: getListRefundTypes.SUCCESS,
  }),
  [getListRefundActions.FAILURE]: (
    state: RefundState,
    { payload }: Action<any>
  ): RefundState => ({
    ...state,
    error: payload,
    loading: false,
    type: getListRefundTypes.FAILURE,
  }),

  // get refund data by id
  [getRefundByIdActions.REQUEST]: (state: RefundState): RefundState => ({
    ...state,
    loading: true,
    type: null,
    refundData: null,
  }),
  [getRefundByIdActions.SUCCESS]: (
    state: RefundState,
    { payload }: Action<any>
  ): RefundState => ({
    ...state,
    refundData: payload,
    loading: false,
    type: getRefundByIdTypes.SUCCESS,
  }),
  [getRefundByIdActions.FAILURE]: (
    state: RefundState,
    { payload }: Action<any>
  ): RefundState => ({
    ...state,
    error: payload,
    loading: false,
    type: getRefundByIdTypes.FAILURE,
  }),

  // accept refund
  [acceptRefundActions.REQUEST]: (state: RefundState): RefundState => ({
    ...state,
    loading: true,
  }),
  [acceptRefundActions.SUCCESS]: (
    state: RefundState,
    { payload }: Action<any>
  ): RefundState => ({
    ...state,
    refundData: payload,
    loading: false,
    type: acceptRefundTypes.SUCCESS,
  }),
  [acceptRefundActions.FAILURE]: (
    state: RefundState,
    { payload }: Action<any>
  ): RefundState => ({
    ...state,
    error: payload,
    loading: false,
    type: acceptRefundTypes.FAILURE,
  }),
  // decline refund
  [declineRefundActions.REQUEST]: (state: RefundState): RefundState => ({
    ...state,
    loading: true,
  }),
  [declineRefundActions.SUCCESS]: (
    state: RefundState,
    { payload }: Action<any>
  ): RefundState => ({
    ...state,
    refundData: payload,
    loading: false,
    type: declineRefundTypes.SUCCESS,
  }),
  [declineRefundActions.FAILURE]: (
    state: RefundState,
    { payload }: Action<any>
  ): RefundState => ({
    ...state,
    error: payload,
    loading: false,
    type: declineRefundTypes.FAILURE,
  }),
};

const initialState: RefundState = {
  error: null,
  loading: false,
  refunds: null,
  refundData: null,
  type: null,
};

export const refundReducer = handleActions<RefundState>(handlers, initialState);
