import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "typed-redux-saga";
import { ListQueryParams } from "redux/Common/types";
import { getRequestorsActions } from "./actions";
import { data } from "./mocks";

function* getRequestors({ payload }: Action<ListQueryParams>) {
  //   const result: any = yield* call(talentService.getTalentApplications, payload);
  //   if (result.ok) {
  //     yield* put(getRequestorsActions.SUCCESS(result.response));
  //   } else {
  //     yield* put(getRequestorsActions.FAILURE(result.message));
  //   }
  yield* put(getRequestorsActions.SUCCESS(data));
}

export default function* requestSagas(): SagaIterator {
  yield* takeLatest(getRequestorsActions.REQUEST, getRequestors);
}
