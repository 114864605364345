import {
  EVENT_WEBVIEW_MESSAGE,
  EVENT_WEBVIEW_DATA,
  NOTIFICATION_WEBVIEW,
  ALERT_PARAMS,
  WEBVIEW_ERROR_MESSAGE_TYPE,
  WEBVIEW_ERROR_MESSAGE_TX,
} from "constants/webview";
import { EXPERIENCE_TYPE, Experience } from "redux/Experience/types";

interface IRNWebView {
  trackStep: (step: number) => any;
  trackLoadingStage: (isLoading: boolean) => any;
  done: () => any;
  registraionTalentCompleted: () => any;
  selectContentType: (contentType: EXPERIENCE_TYPE | undefined) => any;
  notification: (message: NOTIFICATION_WEBVIEW) => any;
  openWebBrowser: (webBrowser: string) => any;
  alert: (params: ALERT_PARAMS) => any;
  recordExpData: (experienceData: {
    id: string | undefined;
    data: Experience;
  }) => any;
  logErrorMessage: (
    message: WEBVIEW_ERROR_MESSAGE_TX | undefined,
    type: WEBVIEW_ERROR_MESSAGE_TYPE
  ) => any;
}

class WebViewMessage implements IRNWebView {
  protected ele: any;

  constructor(ele: any) {
    this.ele = ele;
  }

  private postMessage = (
    message: EVENT_WEBVIEW_MESSAGE,
    data?: EVENT_WEBVIEW_DATA
  ) => {
    this.ele?.ReactNativeWebView?.postMessage(
      JSON.stringify({ message, data: data && JSON.stringify(data) })
    );
  };

  public isWebView = (): boolean => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const ios = /iphone|ipod|ipad/.test(userAgent);

    if (userAgent.includes("wv") || ios) return true;
    return false;
  };

  public trackStep = (step: number): void => {
    this.postMessage(EVENT_WEBVIEW_MESSAGE.STEP_TRACK, { step });
  };

  public trackLoadingStage = (isLoading: boolean): void => {
    this.postMessage(EVENT_WEBVIEW_MESSAGE.LOADING, { isLoading });
  };

  public done = () => {
    this.postMessage(EVENT_WEBVIEW_MESSAGE.DONE);
  };

  public registraionTalentCompleted = () => {
    this.postMessage(EVENT_WEBVIEW_MESSAGE.REGISTRATION_TALENT_COMPLETED);
  };

  public selectContentType = (
    contentType: EXPERIENCE_TYPE | undefined
  ): void => {
    this.postMessage(EVENT_WEBVIEW_MESSAGE.SELECT_COTENT_TYPE, { contentType });
  };

  public notification = (message: NOTIFICATION_WEBVIEW): void => {
    this.postMessage(EVENT_WEBVIEW_MESSAGE.NOTIFICATION, {
      notification: message,
    });
  };

  public openWebBrowser = (webBrowser: string): void => {
    this.postMessage(EVENT_WEBVIEW_MESSAGE.OPEN_WEB_BROWSER, {
      webBrowser,
    });
  };

  public alert = (alert: ALERT_PARAMS): void => {
    this.postMessage(EVENT_WEBVIEW_MESSAGE.ALERT, {
      alert,
    });
  };

  public recordExpData = (experienceData: {
    id: string | undefined;
    data: Experience;
  }): void => {
    this.postMessage(EVENT_WEBVIEW_MESSAGE.RECORD_EXP_DATA, {
      experienceData: {
        id: experienceData?.id,
        data: experienceData?.data,
      },
    });
  };

  public logErrorMessage = (
    message: WEBVIEW_ERROR_MESSAGE_TX | undefined,
    type: WEBVIEW_ERROR_MESSAGE_TYPE
  ) => {
    this.postMessage(EVENT_WEBVIEW_MESSAGE.LOG_ERROR_MESSAGE, {
      errorMessage: {
        [type]: message,
      },
    });
  };
}

export const webviewMessage = new WebViewMessage(window);
