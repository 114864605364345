import { Action, handleActions } from "redux-actions";
import { getListActivationCodeTypes } from "./../ActivationCode/actions";
import {
  acceptSpecialRequestActions,
  acceptSpecialRequestTypes,
  declineSpecialRequestActions,
  declineSpecialRequestTypes,
  getListSpecialRequestsActions,
  getSpecialRequestByIdActions,
  getSpecialRequestByIdTypes,
} from "./actions";
import { SpecialRequestState } from "./types";

const handlers = {
  // get list special requests
  [getListSpecialRequestsActions.REQUEST]: (
    state: SpecialRequestState
  ): SpecialRequestState => ({
    ...state,
    requests: null,
    loading: true,
    type: null,
  }),
  [getListSpecialRequestsActions.SUCCESS]: (
    state: SpecialRequestState,
    { payload }: Action<any>
  ): SpecialRequestState => ({
    ...state,
    requests: payload,
    loading: false,
    type: getListActivationCodeTypes.SUCCESS,
  }),
  [getListSpecialRequestsActions.FAILURE]: (
    state: SpecialRequestState,
    { payload }: Action<any>
  ): SpecialRequestState => ({
    ...state,
    error: payload,
    loading: false,
    type: getListActivationCodeTypes.FAILURE,
  }),

  // get special request by id
  [getSpecialRequestByIdActions.REQUEST]: (
    state: SpecialRequestState
  ): SpecialRequestState => ({
    ...state,
    loading: true,
    type: null,
    requestData: null,
  }),
  [getSpecialRequestByIdActions.SUCCESS]: (
    state: SpecialRequestState,
    { payload }: Action<any>
  ): SpecialRequestState => ({
    ...state,
    requestData: payload,
    loading: false,
    type: getSpecialRequestByIdTypes.SUCCESS,
  }),
  [getSpecialRequestByIdActions.FAILURE]: (
    state: SpecialRequestState,
    { payload }: Action<any>
  ): SpecialRequestState => ({
    ...state,
    error: payload,
    loading: false,
    type: getSpecialRequestByIdTypes.FAILURE,
  }),

  // accept special request
  [acceptSpecialRequestActions.REQUEST]: (
    state: SpecialRequestState
  ): SpecialRequestState => ({
    ...state,
    loading: true,
  }),
  [acceptSpecialRequestActions.SUCCESS]: (
    state: SpecialRequestState,
    { payload }: Action<any>
  ): SpecialRequestState => ({
    ...state,
    requestData: payload,
    loading: false,
    type: acceptSpecialRequestTypes.SUCCESS,
  }),
  [acceptSpecialRequestActions.FAILURE]: (
    state: SpecialRequestState,
    { payload }: Action<any>
  ): SpecialRequestState => ({
    ...state,
    error: payload,
    loading: false,
    type: acceptSpecialRequestTypes.FAILURE,
  }),
  // decline refund
  [declineSpecialRequestActions.REQUEST]: (
    state: SpecialRequestState
  ): SpecialRequestState => ({
    ...state,
    loading: true,
  }),
  [declineSpecialRequestActions.SUCCESS]: (
    state: SpecialRequestState,
    { payload }: Action<any>
  ): SpecialRequestState => ({
    ...state,
    requestData: payload,
    loading: false,
    type: declineSpecialRequestTypes.SUCCESS,
  }),
  [declineSpecialRequestActions.FAILURE]: (
    state: SpecialRequestState,
    { payload }: Action<any>
  ): SpecialRequestState => ({
    ...state,
    error: payload,
    loading: false,
    type: declineSpecialRequestTypes.FAILURE,
  }),
};

const initialState: SpecialRequestState = {
  error: null,
  loading: false,
  requests: null,
  requestData: null,
  type: null,
};

export const specialRequestReducer = handleActions<SpecialRequestState>(
  handlers,
  initialState
);
