import { createGenericTypes, createGenericActions } from "utils/createAction";

export const getTalentsTypes = createGenericTypes("GET_TALENTS");
export const getTalentsActions = createGenericActions(getTalentsTypes);

export const getApplicationsTypes = createGenericTypes("GET_APPLICATIONS");
export const getApplicationsActions = createGenericActions(
  getApplicationsTypes
);

export const getApplicationByIdTypes = createGenericTypes(
  "GET_APPLICATION_BY_ID"
);
export const getApplicationByIdActions = createGenericActions(
  getApplicationByIdTypes
);

export const updateApplicationByIdTypes = createGenericTypes(
  "UPDATE_APPLICATION_BY_ID"
);
export const updateApplicationByIdActions = createGenericActions(
  updateApplicationByIdTypes
);
