import { Category, CreateCategoryRequest } from "redux/Category/types";
import { ListQueryParams } from "redux/Common/types";
import { Pagination, Response } from "./../redux/Common/types";
import { Api } from "./api";

export interface ICategoryService {
  getCategories(
    params: ListQueryParams
  ): Promise<Response<Pagination<Category[]>>>;
}
export default class CategoryService implements ICategoryService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getCategories = (
    params: ListQueryParams
  ): Promise<Response<Pagination<Category[]>>> => {
    return this.api.getCategories(params);
  };

  createCategory = (
    payload: CreateCategoryRequest
  ): Promise<Response<Category>> => {
    return this.api.createCategory(payload);
  };

  updateCategory = (payload: Category): Promise<Response<Category>> => {
    return this.api.updateCategory(payload);
  };

  deleteCategory = (id: Category["id"]): Promise<Response<Category>> => {
    return this.api.deleteCategory(id);
  };

  bulkUpdateCategories = (
    payload: Category[]
  ): Promise<Response<Category[]>> => {
    return this.api.bulkUpdateCategories(payload);
  };
}
