import { GeneralApiProblem } from "./../../services/api/apiProblem";
import { User } from "redux/User/types";
import { Experience } from "redux/Experience/types";
import moment from "moment-timezone";

export type ListQueryParams = {
  page: number;
  limit: number;
  sort?: string;
  filter?: string;
  search?: any;
  status?: string | string[];
  account_status?: string;
  trending?: boolean;
  categoryTrending?: boolean;
  categoryId?: number;
};

export type Pagination<T> = {
  items: T;
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: string;
    totalPages: number;
    currentPage: string;
  };
  links: {
    first: string;
    previous: string;
    next: string;
    last: string;
  };
};

export type Media = {
  src: string;
  type: string;
};

export type Response<T> =
  | GeneralApiProblem
  | {
      ok: any;
      response: T;
    }
  | null;

export enum StatusType {
  ALL = "all",
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
  UNCOMPLETED_PROFILE = "uncompleted_profile",
  ACTIVATE = "active",
  DEACTIVATE = "deactivated",
  EXPIRED = "expired",
}

export enum AccountStatusType {
  ALL = "all",
  ACTIVATE = "active",
  INACTIVE = "inactive",
  DEACTIVATE = "deactivated",
  BANNED = "banned",
  SUSPENDED = "suspended",
}

export type SearchResult = {
  experts: Pagination<User[]>;
  experiences: Pagination<Experience[]>;
};

export type MediaUpload = {
  id?: number;
  fileName?: string;
  loading?: boolean;
  url?: string | ArrayBuffer;
  poster?: string;
  duration?: number;
  percent?: number;
  size?: number;
  name?: string;
  isDeleted?: boolean;
};

export type CommonState = {
  isLoading: boolean;
  error?: string | null;
  result?: SearchResult | null;
};

export type TIME_ZONE = {
  tz: string;
  tx: string;
  sectionId?: number;
};

export const TIME_ZONE_DATA: Array<{
  id: number;
  tx: string;
  data: Array<TIME_ZONE>;
}> = [
  {
    id: 1,
    tx: "timeZone.currentTime",
    data: [{ tz: moment.tz.guess(), tx: moment.tz.guess(), sectionId: 1 }],
  },
  {
    id: 2,
    tx: "timeZone.usaTime",
    data: [
      {
        tz: "Pacific/Honolulu",
        tx: "timeZone.hst",
        sectionId: 2,
      },
      {
        tz: "America/Anchorage",
        tx: "timeZone.akdt",
        sectionId: 3,
      },
      {
        tz: "America/Boise",
        tx: "timeZone.pdt",
        sectionId: 4,
      },
      {
        tz: "America/Cambridge_Bay",
        tx: "timeZone.mst",
        sectionId: 5,
      },
      {
        tz: "America/Bahia_Banderas",
        tx: "timeZone.mdt",
        sectionId: 6,
      },
      {
        tz: "America/Atikokan",
        tx: "timeZone.cdt",
        sectionId: 7,
      },
      {
        tz: "America/Anguilla",
        tx: "timeZone.edt",
        sectionId: 8,
      },
    ],
  },
  {
    id: 3,
    tx: "timeZone.europeTime",
    data: [
      {
        tz: "Europe/Rome",
        tx: "timeZone.bst",
        sectionId: 9,
      },
      {
        tz: "Europe/Tallinn",
        tx: "timeZone.cest",
        sectionId: 10,
      },
      {
        tz: "Europe/Berlin",
        tx: "timeZone.cet",
        sectionId: 11,
      },
      {
        tz: "Europe/Simferopol",
        tx: "timeZone.eest",
        sectionId: 12,
      },
      {
        tz: "Europe/Vilnius",
        tx: "timeZone.eet",
        sectionId: 13,
      },
      {
        tz: "Europe/Kirov",
        tx: "timeZone.fet",
        sectionId: 14,
      },
      {
        tz: "Europe/Saratov",
        tx: "timeZone.get",
        sectionId: 15,
      },
      {
        tz: "Europe/Lisbon",
        tx: "timeZone.gmt",
        sectionId: 16,
      },
      {
        tz: "Europe/Dublin",
        tx: "timeZone.ist",
        sectionId: 17,
      },
      {
        tz: "Europe/Samara",
        tx: "timeZone.kuyt",
        sectionId: 18,
      },
      {
        tz: "Europe/Minsk",
        tx: "timeZone.msd",
        sectionId: 19,
      },
      {
        tz: "Europe/Minsk",
        tx: "timeZone.msk",
        sectionId: 20,
      },
      {
        tz: "Europe/Ulyanovsk",
        tx: "timeZone.samt",
        sectionId: 21,
      },
      {
        tz: "Europe/Istanbul",
        tx: "timeZone.trt",
        sectionId: 22,
      },
      {
        tz: "Europe/Oslo",
        tx: "timeZone.west",
        sectionId: 23,
      },
      {
        tz: "Europe/Guernsey",
        tx: "timeZone.wet",
        sectionId: 24,
      },
    ],
  },
];
