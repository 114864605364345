import { notification } from "antd";
import { ConfigProps, IconType } from "antd/lib/notification";
import { webviewMessage } from "./webviewMessage";
import { WEBVIEW_MESSAGE_TYPE } from "../constants/webview";

const defaultConfig: ConfigProps = {
  placement: "topRight",
  top: 50,
  duration: 2,
};

type MessageParams = {
  message: string;
  description?: string;
};

class Notifiaction {
  constructor(config: ConfigProps) {
    notification.config(config);
  }

  private notify(type: IconType, params: MessageParams) {
    notification[type](params);
  }

  error(params: MessageParams) {
    if (webviewMessage.isWebView()) {
      webviewMessage.notification({
        type: WEBVIEW_MESSAGE_TYPE.ERROR,
        message: params.message,
      });
    } else this.notify("error", params);
  }

  warn(params: MessageParams) {
    if (webviewMessage.isWebView()) {
      webviewMessage.notification({
        type: WEBVIEW_MESSAGE_TYPE.WARNING,
        message: params.message,
      });
    } else this.notify("warning", params);
  }

  success(params: MessageParams) {
    if (webviewMessage.isWebView()) {
      webviewMessage.notification({
        type: WEBVIEW_MESSAGE_TYPE.SUCCESS,
        message: params.message,
      });
    } else this.notify("success", params);
  }

  info(params: MessageParams) {
    this.notify("info", params);
  }
}

export default new Notifiaction(defaultConfig);
