import { ApplicationStatusType } from "redux/Application/types";
import { AccountStatusType } from "redux/Common/types";

export enum CreatedBy {
  TALENT_APP = "talent-app",
  ADMIN = "admin",
}

export const CreatedByText: { [string: string]: string } = {
  [CreatedBy.TALENT_APP]: "Talent App",
  [CreatedBy.ADMIN]: "Admin",
};

export const ApplicationStatusTitle: { [string: string]: string } = {
  [ApplicationStatusType.ALL]: "All Status",
  [ApplicationStatusType.NEW]: "New",
  [ApplicationStatusType.IN_PROGRESS]: "In Progress",
  [ApplicationStatusType.COMPLETED]: "Completed",
  [ApplicationStatusType.REJECTED]: "Rejected",
};

export const AccountStatusTitle: { [string: string]: string } = {
  [AccountStatusType.ALL]: "All Status",
  [AccountStatusType.ACTIVATE]: "Online",
  [AccountStatusType.BANNED]: "Banned",
  [AccountStatusType.INACTIVE]: "Offline",
  [AccountStatusType.DEACTIVATE]: "Offline",
  [AccountStatusType.SUSPENDED]: "Suspended",
};
