import { Action, handleActions } from "redux-actions";
import {
  getBookings1To1Actions,
  getBooking1To1ByIdActions,
  rejectBookingActions,
} from "./actions";
import { BookingState } from "./types";

const handlers = {
  // get 1 to 1 bookings
  [getBookings1To1Actions.REQUEST]: (state: BookingState): BookingState => ({
    ...state,
    bookings1To1: null,
    loading: true,
  }),
  [getBookings1To1Actions.SUCCESS]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    bookings1To1: payload,
    loading: false,
  }),
  [getBookings1To1Actions.FAILURE]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    error: payload,
    loading: false,
  }),

  // get 1 to 1 booking by id
  [getBooking1To1ByIdActions.REQUEST]: (state: BookingState): BookingState => ({
    ...state,
    loading: true,
  }),
  [getBooking1To1ByIdActions.SUCCESS]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    booking1To1Data: payload,
    loading: false,
  }),
  [getBooking1To1ByIdActions.FAILURE]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    error: payload,
    loading: false,
  }),

  // reject booking
  [rejectBookingActions.REQUEST]: (state: BookingState): BookingState => ({
    ...state,
    loading: true,
  }),
  [rejectBookingActions.SUCCESS]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    booking1To1Data: payload,
    loading: false,
  }),
  [rejectBookingActions.FAILURE]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    error: payload,
    loading: false,
  }),
};

const initialState: BookingState = {
  error: null,
  loading: false,
  bookings1To1: null,
  booking1To1Data: null,
};

export const bookingReducer = handleActions<BookingState>(
  handlers,
  initialState
);
