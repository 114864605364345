import { MediaUpload, ListQueryParams, Pagination } from "redux/Common/types";
import { Talent } from "redux/Talent/types";
import { User } from "redux/User/types";

export type ProductImage = {
  name: string;
  link: string;
  image: string;
  price: string;
};

export type Contents1To1Request = {
  covers?: Array<string>;
  name?: "string";
  description?: "string";
  duration?: "string";
  price?: number;
  interactive?: boolean;
  tags: Array<string>;
};

export type PublishExperienceRequest = {
  id: string;
  type: EXPERIENCE_TYPE;
};

export type UpdateExperience1To1Request = {
  requestBody: Contents1To1Request;
  id: string;
};

export type GetExperienceByIdRequest = {
  expId: string;
};

export enum CREATE_EXPERIENCE_STEP {
  PHOTOS_VIDEOS = "1",
  EXPERIENCE_DETAILS = "2",
  AVAILABILITY_TIME = "3",
  E_COMMERCE = "4",
}

export enum EXPERIENCE_TYPE {
  ALL = "all",
  LIVE_CLASS = "live_class",
  ONE_TO_ONE = "one_to_one",
  EXCLUSIVE_CONTENT = "exclusive_content",
  E_COMMERCE = "e_commerce",
  COURSE = "course",
  SPECIAL_REQUEST = "special_request",
  INTERACTIVE_LIVE_CLASS = "interactive_live_class",
  BUNDLE = "bundle"
}

export enum EXPERIENCE_CREATED_BY {
  ALL = "all",
  KOMI_TEAM = "komi_admin",
  TALENT = "expert",
}

export enum EXPERIENCE_STATUS {
  DRAFT = "draft",
  PUBLISHED = "published",
  UNPUBLISHED = "unpublished",
}

export enum EXPERIENCE_PROCESS_STATUS {
  EMPTY = "EMPTY",
  DRAFTED = "DRAFTED",
  CREATED = "CREATED",
  UPDATED = "UPDATED",
  PUBLISHED = "PUBLISHED",
}

export type GetExperiencePayload = {
  type: EXPERIENCE_TYPE;
  params: ListQueryParams;
};

export type UpdateStagingCoversPayload = {
  uploadItemIndex: string;
  photo: Experience["media"];
};

export type TimeRange = {
  id?: string;
  startTime: string;
  endTime: string;
  occurrence: string[];
};

export type DateOff = {
  id?: string;
  date: string;
};

export type StateExperienceLocation = {
  data: Experience;
  contentType: EXPERIENCE_TYPE;
};

export type LessonType = {
  name?: string;
  description?: string;
  video: {
    url?: string;
    metadata?: any;
  };
  order?: number;
};

export type Experience = {
  id?: string;
  media?: MediaUpload[];
  description?: string;
  status?: string;
  name?: string;
  price?: number;
  duration?: number;
  rating?: number;
  totalRatings?: number;
  prerequisites?: Array<string>;
  date?: string;
  covers?: Array<string>;
  link?: string;
  attachments?: Array<{
    url: string;
    metadata?: {
      name: string;
      size: number;
    };
  }>;
  tags?: {
    id: number;
    name: string;
  }[];
  type?: EXPERIENCE_TYPE;
  createdAt?: string;
  updatedAt?: string;
  scheduleTime?: string;
  creator?: Talent;
  timeRanges?: TimeRange[];
  dateOffs?: DateOff[];
  video?: string;
  image?: string;
  products?: {
    id: string;
    image: string;
    link: string;
    name: string;
    price: number;
  }[];
  isBooked?: boolean;
  maxAttendees?: number;
  lessons?: LessonType[];
  createdBy?: User;
  trendingOrder?: number;
  categoryTrendingOrder?: number;
};

export type ExperienceState = {
  loading: boolean;
  error?: string | null;
  publishError?: string | null;
  contentProcessStatus: EXPERIENCE_PROCESS_STATUS;
  stagingCovers: { [key in string]: MediaUpload };
  productImages: { [key in string]: MediaUpload };
  lessons: { [key in string]: MediaUpload };
  attachments: { [key in string]: MediaUpload };
  experience: Pagination<Experience[]> | null;
  experiencesInCategory: Pagination<Experience[]> | null;
  experienceDetail: Experience | null;
  fetchExperienceDetail: Experience | null;
  trendingExperiences: Experience[] | null;
  trendingCategoryExperiences: Experience[] | null;
  type: string | null;
};
