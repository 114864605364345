let { komi } = (window as any);

if (!komi) {
  komi = {};

  Object.defineProperty(window, 'komi', {
    value: komi,
    configurable: false
  });
}

const config = {
  version: window.env.REACT_APP_SERVICE_VERSION,

  api: {
    url: window.env.REACT_APP_API_URL
  },
  google: {
    oauthClientId: window.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID
  },
  service: {
    env: window.env.REACT_APP_NODE_ENV,
    url: window.env.REACT_APP_URL,
    name: window.env.REACT_APP_SERVICE_NAME,
    version: window.env.REACT_APP_SERVICE_VERSION
  },
  talent: {
    url: window.env.REACT_APP_TALENT_URL,
    domain: new URL(window.env.REACT_APP_TALENT_URL).hostname,
  },
  flags: {
    host: window.env.REACT_APP_FEATURE_FLAG_HOST,
    key: window.env.REACT_APP_FEATURE_FLAG_KEY,
  },
}

Object.defineProperty(komi, 'admin', {
  value: Object.freeze(config),
  configurable: false
});

export default config;
