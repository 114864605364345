import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { ListQueryParams } from "redux/Common/types";
import { categoryService } from "services";
import {
  bulkUpdateCategoriesActions,
  createCategoryActions,
  deleteCategoryActions,
  getCategoriesActions,
  updateCategoryActions,
} from "./actions";
import { Category, CreateCategoryRequest } from "./types";

function* getCategories({ payload }: Action<ListQueryParams>) {
  const result: any = yield* call(categoryService.getCategories, payload);
  if (result.ok) {
    yield* put(getCategoriesActions.SUCCESS(result.response));
  } else {
    yield* put(getCategoriesActions.FAILURE(result.message));
  }
}

function* createCategory({ payload }: Action<CreateCategoryRequest>) {
  const result: any = yield* call(categoryService.createCategory, payload);
  if (result.ok) {
    yield* put(createCategoryActions.SUCCESS(result.response));
  } else {
    yield* put(createCategoryActions.FAILURE(result.message));
  }
}

function* updateCategory({ payload }: Action<Category>) {
  const result: any = yield* call(categoryService.updateCategory, payload);
  if (result.ok) {
    yield* put(updateCategoryActions.SUCCESS(result.response));
  } else {
    yield* put(updateCategoryActions.FAILURE(result.message));
  }
}

function* deleteCategory({ payload }: Action<Category["id"]>) {
  const result: any = yield* call(categoryService.deleteCategory, payload);
  if (result.ok) {
    yield* put(deleteCategoryActions.SUCCESS(result.response));
  } else {
    yield* put(deleteCategoryActions.FAILURE(result.message));
  }
}
function* bulkUpdateCategories({ payload }: Action<Category[]>) {
  const result: any = yield* call(
    categoryService.bulkUpdateCategories,
    payload
  );
  if (result.ok) {
    yield* put(bulkUpdateCategoriesActions.SUCCESS(result.response));
  } else {
    yield* put(bulkUpdateCategoriesActions.FAILURE(result.message));
  }
}

export default function* categorySagas(): SagaIterator {
  yield* takeLatest(getCategoriesActions.REQUEST, getCategories);
  yield* takeLatest(createCategoryActions.REQUEST, createCategory);
  yield* takeLatest(updateCategoryActions.REQUEST, updateCategory);
  yield* takeLatest(deleteCategoryActions.REQUEST, deleteCategory);
  yield* takeLatest(bulkUpdateCategoriesActions.REQUEST, bulkUpdateCategories);
}
