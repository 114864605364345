import { ApplicationType } from "redux/Application/types";
import { ListQueryParams } from "redux/Common/types";
import { Pagination, Response } from "./../redux/Common/types";
import { Api } from "./api";
import { CreateTalentData, Talent, TalentAnalytics } from "redux/Talent/types";
import { SignInResponse } from "../redux/User/types";

export interface ITalentService {
  getApplications(
    params: ListQueryParams
  ): Promise<Response<Pagination<ApplicationType[]>>>;
}
export default class TalentService implements ITalentService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getTalents = (
    params: ListQueryParams
  ): Promise<Response<Pagination<Talent[]>>> => {
    return this.api.getTalents(params);
  };

  getTalentById = (id: string): Promise<Response<Talent>> => {
    return this.api.getTalentById(id);
  };

  getApplications = (
    params: ListQueryParams
  ): Promise<Response<Pagination<ApplicationType[]>>> => {
    return this.api.getApplications(params);
  };

  getApplicationById = (id: string): Promise<Response<Talent>> => {
    return this.api.getApplicationById(id);
  };

  updateApplicationById = (
    payload: ApplicationType
  ): Promise<Response<ApplicationType>> => {
    return this.api.updateApplicationById(payload);
  };

  getTalentAnalytics = (id: string): Promise<Response<TalentAnalytics>> => {
    return this.api.getTalentAnalytics(id);
  };

  bulkUpdateTalents = (
    payload: Talent["talentProfile"][]
  ): Promise<Response<Talent["talentProfile"][]>> => {
    return this.api.bulkUpdateTalents(payload);
  };
  createTalent = (data: CreateTalentData): Promise<Response<Talent>> => {
    return this.api.createTalent(data);
  };
  deleteTalent = (id: string): Promise<Response<any>> => {
    return this.api.deleteTalent(id);
  };
  getAccessToken = (id: string): Promise<Response<SignInResponse>> => {
    return this.api.getAccessToken(id);
  };
}
