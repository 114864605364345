import { createGenericTypes, createGenericActions } from "utils/createAction";

export const getListActivationCodeTypes = createGenericTypes(
  "GET_LIST_ACTIVATION_CODE"
);
export const getListActivationCodeActions = createGenericActions(
  getListActivationCodeTypes
);

export const getTalentsOfActivationCodeTypes = createGenericTypes(
  "GET_TALENTS_OF_ACTIVATION_CODE"
);
export const getTalentsOfActivationCodeActions = createGenericActions(
  getTalentsOfActivationCodeTypes
);

export const getActivationCodeByIdTypes = createGenericTypes(
  "GET_ACTIVATION_CODE_BY_ID"
);
export const getActivationCodeByIdActions = createGenericActions(
  getActivationCodeByIdTypes
);

export const updateActivationCodeByIdTypes = createGenericTypes(
  "UPDATE_ACTIVATION_CODE_BY_ID"
);
export const updateActivationCodeByIdActions = createGenericActions(
  updateActivationCodeByIdTypes
);

export const createActivationCodeTypes = createGenericTypes(
  "CREATE_ACTIVATION_CODE"
);
export const createActivationCodeActions = createGenericActions(
  createActivationCodeTypes
);
