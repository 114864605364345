import {
  ListQueryParams,
  Pagination,
  Response,
  SearchResult,
} from "./../redux/Common/types";
import {
  AcceptCollaboratorInvitationRequest,
  CancelInviteCollaboratorRequest,
  CheckUserExistedRequest,
  CheckUserExistedResult,
  InviteCollaboratorRequest,
  RevokeInviteCollaboratorRequest,
  User,
} from "./../redux/User/types";
import { Api } from "./api";
import { ConsumerAnalytics } from "redux/Consumer/types";

interface IUserService {
  getUserProfile(): Promise<Response<User>>;
  getConsumers(param: ListQueryParams): Promise<Response<Pagination<User[]>>>;
  getConsumerById(id: string): Promise<Response<User>>;
  updateConsumer(payload: User): Promise<Response<User>>;
  getUserAnalytics(id: User["id"]): Promise<Response<User>>;
  checkUserExisted(
    payload: CheckUserExistedRequest
  ): Promise<Response<CheckUserExistedResult>>;
  acceptCollaboratorInvitation(
    payload: AcceptCollaboratorInvitationRequest
  ): Promise<Response<any>>;
  getCollaborator(): Promise<Response<any>>;
  inviteCollaborator(
    payload: InviteCollaboratorRequest
  ): Promise<Response<any>>;
  cancelInviteCollaborator(
    payload: CancelInviteCollaboratorRequest
  ): Promise<Response<any>>;
  revokeInviteCollaborator(
    payload: RevokeInviteCollaboratorRequest
  ): Promise<Response<any>>;
}

export default class UserService implements IUserService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getUserProfile = (): Promise<Response<User>> => {
    return this.api.getUserProfile();
  };

  getConsumers = (
    params: ListQueryParams
  ): Promise<Response<Pagination<User[]>>> => {
    return this.api.getConsumers(params);
  };

  getConsumerById = (id: string): Promise<Response<User>> => {
    return this.api.getConsumerById(id);
  };

  updateConsumer = (payload: User): Promise<Response<User>> => {
    return this.api.updateConsumerById(payload);
  };

  getUserAnalytics = (id: User["id"]): Promise<Response<ConsumerAnalytics>> => {
    return this.api.getUserAnalytics(id);
  };

  handleSearch = (payload: string): Promise<Response<SearchResult>> => {
    return this.api.handleSearch(payload);
  };
  checkUserExisted = (
    params: CheckUserExistedRequest
  ): Promise<Response<CheckUserExistedResult>> => {
    return this.api.checkUserExisted(params);
  };
  // admin collaborator
  getCollaborator = () => {
    return this.api.getCollaborator();
  };

  inviteCollaborator = (payload: InviteCollaboratorRequest) => {
    return this.api.inviteCollaborator(payload);
  };

  cancelInviteCollaborator = (payload: CancelInviteCollaboratorRequest) => {
    return this.api.cancelInviteCollaborator(payload);
  };

  revokeInviteCollaborator = (payload: RevokeInviteCollaboratorRequest) => {
    return this.api.revokeInviteCollaborator(payload);
  };
  acceptCollaboratorInvitation = (
    payload: AcceptCollaboratorInvitationRequest
  ): Promise<Response<any>> => {
    return this.api.acceptCollaboratorInvitation(payload);
  };
}
