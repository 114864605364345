import { Action, handleActions } from "redux-actions";
import { searchActions } from "./actions";
import { CommonState } from "./types";

const handlers = {
  // handle search
  [searchActions.REQUEST]: (state: CommonState): CommonState => {
    return {
      ...state,
      error: null,
      isLoading: true,
      result: null,
    };
  },
  [searchActions.SUCCESS]: (state: CommonState, { payload }: Action<any>): CommonState => {
    return {
      ...state,
      error: null,
      isLoading: false,
      result: payload,
    };
  },
  [searchActions.FAILURE]: (state: CommonState, { payload }: Action<any>): CommonState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
};

const defaultState: CommonState = {
  isLoading: false,
  error: null,
  result: null,
};

export const commonReducer = handleActions<CommonState>(handlers, defaultState);
