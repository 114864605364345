import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { booking1To1Service } from "services";
import {
  getBooking1To1ByIdActions,
  getBookings1To1Actions,
  rejectBookingActions,
} from "./actions";
import { GetBookingRequest, RejectBookingRequest } from "./types";

function* getBookings1To1({ payload }: Action<GetBookingRequest>) {
  const result: any = yield* call(booking1To1Service.getBooking, payload);
  if (result.ok) {
    yield* put(getBookings1To1Actions.SUCCESS(result.response));
  } else {
    yield* put(getBookings1To1Actions.FAILURE(result.message));
  }
}

function* getBooking1To1ById({ payload }: Action<string>) {
  const result: any = yield* call(booking1To1Service.getBookingById, payload);
  if (result.ok) {
    yield* put(getBooking1To1ByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getBooking1To1ByIdActions.FAILURE(result.message));
  }
}

function* rejectBooking({ payload }: Action<RejectBookingRequest>) {
  const result: any = yield* call(booking1To1Service.rejectBooking, payload);
  if (result.ok) {
    yield* put(rejectBookingActions.SUCCESS(result.response));
  } else {
    yield* put(rejectBookingActions.FAILURE(result.message));
  }
}

export default function* bookingSagas(): SagaIterator {
  yield* takeLatest(getBookings1To1Actions.REQUEST, getBookings1To1);
  yield* takeLatest(getBooking1To1ByIdActions.REQUEST, getBooking1To1ById);
  yield* takeLatest(rejectBookingActions.REQUEST, rejectBooking);
}
