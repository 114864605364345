import { ListQueryParams } from "redux/Common/types";
import { Pagination, Response } from "../redux/Common/types";
import { Api } from "./api";
import {
  CouponType,
  CreateCouponType,
  GetUsersOfCouponType,
} from "redux/Coupon/types";
import { User } from "redux/User/types";

export interface ICouponService {
  getCoupons(
    params: ListQueryParams
  ): Promise<Response<Pagination<CouponType[]>>>;

  getCouponById(id: string): Promise<Response<CouponType>>;
  createCoupon(payload: CreateCouponType): Promise<Response<CouponType>>;
  updateCouponById(payload: CreateCouponType): Promise<Response<CouponType>>;
  getUsersOfCoupon(
    payload: GetUsersOfCouponType
  ): Promise<Response<Pagination<User[]>>>;
}
export default class CouponService implements ICouponService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getCoupons = (
    params: ListQueryParams
  ): Promise<Response<Pagination<CouponType[]>>> => {
    return this.api.getCoupons(params);
  };

  getCouponById = (id: string): Promise<Response<CouponType>> => {
    return this.api.getCouponById(id);
  };

  createCoupon = (payload: CreateCouponType): Promise<Response<CouponType>> => {
    return this.api.createCoupon(payload);
  };

  updateCouponById = (
    payload: CreateCouponType
  ): Promise<Response<CouponType>> => {
    return this.api.updateCouponById(payload);
  };

  getUsersOfCoupon = (
    payload: GetUsersOfCouponType
  ): Promise<Response<Pagination<User[]>>> => {
    return this.api.getUsersOfCoupon(payload);
  };
}
