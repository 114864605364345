import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { ListQueryParams } from "redux/Common/types";
import { activationCodeService } from "services";
import {
  createActivationCodeActions,
  getActivationCodeByIdActions,
  getListActivationCodeActions,
  updateActivationCodeByIdActions,
  getTalentsOfActivationCodeActions,
} from "./actions";
import {
  ActivationCodeType,
  CreateActivationCodeType,
  GetTalentsOfActivationCodeType,
} from "./types";

function* getListActivationCode({ payload }: Action<ListQueryParams>) {
  const result: any = yield* call(
    activationCodeService.getActivationCodes,
    payload
  );
  if (result.ok) {
    yield* put(getListActivationCodeActions.SUCCESS(result.response));
  } else {
    yield* put(getListActivationCodeActions.FAILURE(result.message));
  }
}

function* getActivationCodeById({ payload }: Action<string>) {
  const result: any = yield* call(
    activationCodeService.getActivationCodeById,
    payload
  );
  if (result.ok) {
    yield* put(getActivationCodeByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getActivationCodeByIdActions.FAILURE(result.message));
  }
}

function* updateActivationCodeById({ payload }: Action<ActivationCodeType>) {
  const result: any = yield* call(
    activationCodeService.updateActivationCodeById,
    payload
  );
  if (result.ok) {
    yield* put(updateActivationCodeByIdActions.SUCCESS(result.response));
  } else {
    yield* put(updateActivationCodeByIdActions.FAILURE(result.message));
  }
}

function* createActivationCode({ payload }: Action<CreateActivationCodeType>) {
  const result: any = yield* call(
    activationCodeService.createActivationCode,
    payload
  );
  if (result.ok) {
    yield* put(createActivationCodeActions.SUCCESS(result.response));
  } else {
    yield* put(createActivationCodeActions.FAILURE(result.message));
  }
}

function* getTalentsOfActivationCode({
  payload,
}: Action<GetTalentsOfActivationCodeType>) {
  const result: any = yield* call(
    activationCodeService.getTalentsOfActivationCode,
    payload
  );
  if (result.ok) {
    yield* put(getTalentsOfActivationCodeActions.SUCCESS(result.response));
  } else {
    yield* put(getTalentsOfActivationCodeActions.FAILURE(result.message));
  }
}

export default function* activationCodeSagas(): SagaIterator {
  yield* takeLatest(
    getListActivationCodeActions.REQUEST,
    getListActivationCode
  );
  yield* takeLatest(
    getActivationCodeByIdActions.REQUEST,
    getActivationCodeById
  );
  yield* takeLatest(
    updateActivationCodeByIdActions.REQUEST,
    updateActivationCodeById
  );
  yield* takeLatest(createActivationCodeActions.REQUEST, createActivationCode);
  yield* takeLatest(
    getTalentsOfActivationCodeActions.REQUEST,
    getTalentsOfActivationCode
  );
}
