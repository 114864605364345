import { Pagination, Response } from "../redux/Common/types";
import { Api } from "./api";
import { GetPayoutsRequest, Payout } from "redux/Payout/types";

export interface IPayoutService {
  getListPayout(
    params: GetPayoutsRequest
  ): Promise<Response<Pagination<Payout[]>>>;
  getPayoutById(id: string): Promise<Response<Payout>>;
  updatePayoutById(payout: Payout): Promise<Response<Payout>>;
}
export default class PayoutService implements IPayoutService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getListPayout = (
    params: GetPayoutsRequest
  ): Promise<Response<Pagination<Payout[]>>> => {
    return this.api.getListPayout(params);
  };

  getPayoutById = (id: string): Promise<Response<Payout>> => {
    return this.api.getPayoutById(id);
  };

  updatePayoutById = (payout: Payout): Promise<Response<Payout>> => {
    return this.api.updatePayoutById(payout);
  };
}
