import { createGenericTypes, createGenericActions } from "utils/createAction";

export const getListRefundTypes = createGenericTypes("GET_LIST_REFUND");
export const getListRefundActions = createGenericActions(getListRefundTypes);

export const getRefundByIdTypes = createGenericTypes("GET_REFUND_DETAIL_BY_ID");
export const getRefundByIdActions = createGenericActions(getRefundByIdTypes);

export const acceptRefundTypes = createGenericTypes("ACCEPT_REFUND");
export const acceptRefundActions = createGenericActions(acceptRefundTypes);

export const declineRefundTypes = createGenericTypes("DECLINE_REFUND");
export const declineRefundActions = createGenericActions(declineRefundTypes);
