import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { ApplicationType } from "redux/Application/types";
import { ListQueryParams } from "redux/Common/types";
import { talentService } from "services";
import { call, put, takeLatest } from "typed-redux-saga";
import {
  bulkUpdateTalentsActions,
  createTalentsActions,
  deleteTalentsActions,
  getTalentAnalyticsActions,
  getTalentByIdActions,
  getTalentsActions,
  getTalentsInCategoryActions,
  getTrendingCategoryTalentsActions,
  getTrendingTalentsActions,
  updateTalentByIdActions,
} from "./actions";
import { CreateTalentData, Talent } from "./types";

function* getTalents({ payload }: Action<ListQueryParams>) {
  const result: any = yield* call(talentService.getTalents, payload);
  if (result.ok) {
    yield* put(getTalentsActions.SUCCESS(result.response));
  } else {
    yield* put(getTalentsActions.FAILURE(result.message));
  }
}

function* getTalentsInCategory({ payload }: Action<ListQueryParams>) {
  const result: any = yield* call(talentService.getTalents, payload);
  if (result.ok) {
    yield* put(getTalentsInCategoryActions.SUCCESS(result.response));
  } else {
    yield* put(getTalentsInCategoryActions.FAILURE(result.message));
  }
}

function* getTrendingTalents() {
  const result: any = yield* call(talentService.getTalents, {
    page: 1,
    limit: 100,
    trending: true,
  });
  if (result.ok) {
    yield* put(getTrendingTalentsActions.SUCCESS(result.response));
  } else {
    yield* put(getTrendingTalentsActions.FAILURE(result.message));
  }
}

function* getTrendingCategoryTalents({ payload }: Action<ListQueryParams>) {
  const result: any = yield* call(talentService.getTalents, payload);
  if (result.ok) {
    yield* put(getTrendingCategoryTalentsActions.SUCCESS(result.response));
  } else {
    yield* put(getTrendingCategoryTalentsActions.FAILURE(result.message));
  }
}

function* getTalentById({ payload }: Action<string>) {
  const result: any = yield* call(talentService.getTalentById, payload);
  if (result.ok) {
    yield* put(getTalentByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getTalentByIdActions.FAILURE(result.message));
  }
}

function* updateTalentById({ payload }: Action<ApplicationType>) {
  const result: any = yield* call(talentService.updateApplicationById, payload);
  if (result.ok) {
    yield* put(updateTalentByIdActions.SUCCESS(result.response));
  } else {
    yield* put(updateTalentByIdActions.FAILURE(result.message));
  }
}

function* getTalentAnalytics({ payload }: Action<string>) {
  const result: any = yield* call(talentService.getTalentAnalytics, payload);
  if (result.ok) {
    yield* put(getTalentAnalyticsActions.SUCCESS(result.response));
  } else {
    yield* put(getTalentAnalyticsActions.FAILURE(result.message));
  }
}

function* bulkUpdateTalents({ payload }: Action<Talent["talentProfile"][]>) {
  const result: any = yield* call(talentService.bulkUpdateTalents, payload);
  if (result.ok) {
    yield* put(bulkUpdateTalentsActions.SUCCESS(result.response));
  } else {
    yield* put(bulkUpdateTalentsActions.FAILURE(result.message));
  }
}
function* createActivationCode({ payload }: Action<CreateTalentData>) {
  const result: any = yield* call(talentService.createTalent, payload);
  if (result.ok) {
    yield* put(createTalentsActions.SUCCESS(result.response));
  } else {
    yield* put(createTalentsActions.FAILURE(result.message));
  }
}

function* deleteTalent({ payload }: Action<string>) {
  const result: any = yield* call(talentService.deleteTalent, payload);
  if (result.ok) {
    yield* put(deleteTalentsActions.SUCCESS(result.response));
  } else {
    yield* put(deleteTalentsActions.FAILURE(result.message));
  }
}

export default function* talentSagas(): SagaIterator {
  yield* takeLatest(getTalentsActions.REQUEST, getTalents);
  yield* takeLatest(getTalentByIdActions.REQUEST, getTalentById);
  yield* takeLatest(updateTalentByIdActions.REQUEST, updateTalentById);
  yield* takeLatest(getTalentAnalyticsActions.REQUEST, getTalentAnalytics);
  yield* takeLatest(bulkUpdateTalentsActions.REQUEST, bulkUpdateTalents);
  yield* takeLatest(getTrendingTalentsActions.REQUEST, getTrendingTalents);
  yield* takeLatest(
    getTrendingCategoryTalentsActions.REQUEST,
    getTrendingCategoryTalents
  );
  yield* takeLatest(getTalentsInCategoryActions.REQUEST, getTalentsInCategory);
  yield* takeLatest(createTalentsActions.REQUEST, createActivationCode);
  yield* takeLatest(deleteTalentsActions.REQUEST, deleteTalent);
}
