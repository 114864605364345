import {
  getListPayoutActions,
  getListPayoutTypes,
  getPayoutByIdActions,
  getPayoutByIdTypes,
  updatePayoutByIdActions,
  updatePayoutByIdTypes,
} from "./actions";
import { Action, handleActions } from "redux-actions";
import { PayoutState } from "./types";

const handlers = {
  // get list payouts
  [getListPayoutActions.REQUEST]: (state: PayoutState): PayoutState => ({
    ...state,
    payouts: null,
    loading: true,
    type: null,
  }),
  [getListPayoutActions.SUCCESS]: (
    state: PayoutState,
    { payload }: Action<any>
  ): PayoutState => ({
    ...state,
    payouts: payload,
    loading: false,
    type: getListPayoutTypes.SUCCESS,
  }),
  [getListPayoutActions.FAILURE]: (
    state: PayoutState,
    { payload }: Action<any>
  ): PayoutState => ({
    ...state,
    error: payload,
    loading: false,
    type: getListPayoutTypes.FAILURE,
  }),

  // get payout data by id
  [getPayoutByIdActions.REQUEST]: (state: PayoutState): PayoutState => ({
    ...state,
    loading: true,
    type: null,
    payoutData: null,
  }),
  [getPayoutByIdActions.SUCCESS]: (
    state: PayoutState,
    { payload }: Action<any>
  ): PayoutState => ({
    ...state,
    payoutData: payload,
    loading: false,
    type: getPayoutByIdTypes.SUCCESS,
  }),
  [getPayoutByIdActions.FAILURE]: (
    state: PayoutState,
    { payload }: Action<any>
  ): PayoutState => ({
    ...state,
    error: payload,
    loading: false,
    type: getPayoutByIdTypes.FAILURE,
  }),

  // update payout by id
  [updatePayoutByIdActions.REQUEST]: (state: PayoutState): PayoutState => ({
    ...state,
    loading: true,
  }),
  [updatePayoutByIdActions.SUCCESS]: (
    state: PayoutState,
    { payload }: Action<any>
  ): PayoutState => ({
    ...state,
    payoutData: payload,
    loading: false,
    type: updatePayoutByIdTypes.SUCCESS,
  }),
  [updatePayoutByIdActions.FAILURE]: (
    state: PayoutState,
    { payload }: Action<any>
  ): PayoutState => ({
    ...state,
    error: payload,
    loading: false,
    type: updatePayoutByIdTypes.FAILURE,
  }),
};

const initialState: PayoutState = {
  error: null,
  loading: false,
  payouts: null,
  payoutData: null,
  type: null,
};

export const payoutReducer = handleActions<PayoutState>(handlers, initialState);
