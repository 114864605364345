import { RootState } from "./../rootReducer";
import {
  signInActions,
  getUserProfileActions,
  signInWithGoogleTypes,
  inviteCollaboratorActions,
  revokeInviteCollaboratorActions,
  cancelInviteCollaboratorActions,
} from "./actions";

export const selectUserData = (rootState: RootState) =>
  rootState.userState?.user;

export const selectUserId = (rootState: RootState) =>
  rootState.userState?.user?.id;

export const selectUserLoading = (rootState: RootState) =>
  rootState.userState?.loading;

export const selectUserError = (rootState: RootState) =>
  rootState.userState?.error;

export const selectSignInError = (rootState: RootState) =>
  rootState.userState?.error &&
  rootState.userState?.errorType === signInActions.FAILURE;

export const selectGetUserProfileError = (rootState: RootState) =>
  rootState.userState?.error &&
  rootState.userState?.errorType === getUserProfileActions.FAILURE;

export const selectColl = (rootState: RootState) =>
  rootState.userState?.error &&
  rootState.userState?.errorType === getUserProfileActions.FAILURE;

export const selectCollaborators = (rootState: RootState) =>
  rootState.userState?.collaborators;

export const selectErrorLoginGoogle = (rootState: RootState) =>
  rootState.userState?.error &&
  rootState.userState.errorType === signInWithGoogleTypes.FAILURE;
export const selectErrorInvite = (rootState: RootState) =>
  rootState.userState?.error &&
  rootState.userState.errorType === inviteCollaboratorActions.FAILURE;
export const selectErrorCancelInvite = (rootState: RootState) =>
  rootState.userState?.error &&
  rootState.userState.errorType === cancelInviteCollaboratorActions.FAILURE;
export const selectErrorRevokeInvite = (rootState: RootState) =>
  rootState.userState?.error &&
  rootState.userState.errorType === revokeInviteCollaboratorActions.FAILURE;
export const selectType = (rootState: RootState) => rootState.userState?.type;
