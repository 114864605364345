import { createGenericTypes, createGenericActions } from "utils/createAction";

export const getBookings1To1Types = createGenericTypes("GET_BOOKINGS_1_TO_1");
export const getBookings1To1Actions = createGenericActions(
  getBookings1To1Types
);

export const getBooking1To1ByIdTypes = createGenericTypes(
  "GET_BOOKING_1_TO_1_BY_ID"
);
export const getBooking1To1ByIdActions = createGenericActions(
  getBooking1To1ByIdTypes
);

export const rejectBookingTypes = createGenericTypes("REJECT_BOOKING");
export const rejectBookingActions = createGenericActions(rejectBookingTypes);
