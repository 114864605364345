import { Layout } from "antd";
import Sidebar from "components/Sidebar";
import React, { ReactNode, Suspense } from "react";
import "./DashboardLayout.scss";
import Loading from "components/Loading";

const { Content } = Layout;

interface IProps {
  children: ReactNode;
}

const DashboardLayout = ({ children }: IProps) => {
  return (
    <Layout>
      <Sidebar />
      <Layout className="dashboard-layout">
        <Suspense fallback={<Loading />}>
          <Content>{children}</Content>
        </Suspense>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
