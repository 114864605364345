import { SagaIterator } from "redux-saga";
import { Action } from "redux-actions";
import { put, takeLatest, call } from "typed-redux-saga";
import {
  getListRefundActions,
  getRefundByIdActions,
  acceptRefundActions,
  declineRefundActions,
} from "./actions";
import { refundService } from "services";
import { GetRefundsRequest, DeclineRefundRequest } from "./types";

function* getListRefund({ payload }: Action<GetRefundsRequest>) {
  const result: any = yield* call(refundService.getListRefunds, payload);
  if (result.ok) {
    yield* put(getListRefundActions.SUCCESS(result.response));
  } else {
    yield* put(getListRefundActions.FAILURE(result.message));
  }
}

function* getRefundById({ payload }: Action<string>) {
  const result: any = yield* call(refundService.getRefundById, payload);
  if (result.ok) {
    yield* put(getRefundByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getRefundByIdActions.FAILURE(result.message));
  }
}

function* acceptRefund({ payload }: Action<string>) {
  const result: any = yield* call(refundService.acceptRefund, payload);
  if (result.ok) {
    yield* put(acceptRefundActions.SUCCESS(result.response));
  } else {
    yield* put(acceptRefundActions.FAILURE(result.message));
  }
}

function* declineRefund({ payload }: Action<DeclineRefundRequest>) {
  const result: any = yield* call(refundService.declineRefund, payload);
  if (result.ok) {
    yield* put(declineRefundActions.SUCCESS(result.response));
  } else {
    yield* put(declineRefundActions.FAILURE(result.message));
  }
}

export default function* refundSagas(): SagaIterator {
  yield* takeLatest(getListRefundActions.REQUEST, getListRefund);
  yield* takeLatest(getRefundByIdActions.REQUEST, getRefundById);
  yield* takeLatest(acceptRefundActions.REQUEST, acceptRefund);
  yield* takeLatest(declineRefundActions.REQUEST, declineRefund);
}
