import {
  ActivationCodeType,
  CreateActivationCodeType,
  GetTalentsOfActivationCodeType,
} from "redux/ActivationCode/types";
import { ListQueryParams } from "redux/Common/types";
import { Pagination, Response } from "./../redux/Common/types";
import { Api } from "./api";
import { Talent } from "redux/Talent/types";

export interface IActivationCodeService {
  getActivationCodes(
    params: ListQueryParams
  ): Promise<Response<Pagination<ActivationCodeType[]>>>;

  getActivationCodeById(id: string): Promise<Response<ActivationCodeType>>;
  createActivationCode(
    payload: CreateActivationCodeType
  ): Promise<Response<ActivationCodeType>>;
  updateActivationCodeById(
    payload: ActivationCodeType
  ): Promise<Response<ActivationCodeType>>;
  getTalentsOfActivationCode(
    payload: GetTalentsOfActivationCodeType
  ): Promise<Response<Pagination<Talent[]>>>;
}
export default class ActivationCodeService implements IActivationCodeService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getActivationCodes = (
    params: ListQueryParams
  ): Promise<Response<Pagination<ActivationCodeType[]>>> => {
    return this.api.getActivationCodes(params);
  };

  getActivationCodeById = (
    id: string
  ): Promise<Response<ActivationCodeType>> => {
    return this.api.getActivationCodeById(id);
  };

  createActivationCode = (
    payload: CreateActivationCodeType
  ): Promise<Response<ActivationCodeType>> => {
    return this.api.createActivationCode(payload);
  };

  updateActivationCodeById = (
    payload: ActivationCodeType
  ): Promise<Response<ActivationCodeType>> => {
    return this.api.updateActivationCodeById(payload);
  };

  getTalentsOfActivationCode = (
    payload: GetTalentsOfActivationCodeType
  ): Promise<Response<Pagination<Talent[]>>> => {
    return this.api.getTalentsOfActivationCode(payload);
  };
}
