import { Requestor } from "./types";
import { StatusType, Pagination } from "redux/Common/types";

export const data: Pagination<Requestor[]> = {
  items: [
    {
      id: "1",
      firstName: "Quy",
      lastName: "Pham",
      email: "pbquy96@gmail.com",
      totalRequest: 10,
      totalSpend: 20,
      status: StatusType.ACTIVATE,
    },
    {
      id: "2",
      firstName: "I",
      lastName: "Le",
      email: "ile@gmail.com",
      totalRequest: 20,
      totalSpend: 30,
      status: StatusType.DEACTIVATE,
    },
  ],
  meta: {
    totalItems: 2,
    itemCount: 2,
    itemsPerPage: "2",
    totalPages: 1,
    currentPage: "1",
  },
  links: {
    first: "",
    previous: "",
    next: "",
    last: "",
  },
};
