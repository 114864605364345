import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { payoutService } from "services";
import {
  getListPayoutActions,
  getPayoutByIdActions,
  updatePayoutByIdActions,
} from "./actions";
import { GetPayoutsRequest, Payout } from "./types";

function* getListPayout({ payload }: Action<GetPayoutsRequest>) {
  const result: any = yield* call(payoutService.getListPayout, payload);
  if (result.ok) {
    yield* put(getListPayoutActions.SUCCESS(result.response));
  } else {
    yield* put(getListPayoutActions.FAILURE(result.message));
  }
}

function* getPayoutById({ payload }: Action<string>) {
  const result: any = yield* call(payoutService.getPayoutById, payload);
  if (result.ok) {
    yield* put(getPayoutByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getPayoutByIdActions.FAILURE(result.message));
  }
}

function* updatePayoutById({ payload }: Action<Payout>) {
  const result: any = yield* call(payoutService.updatePayoutById, payload);
  if (result.ok) {
    yield* put(updatePayoutByIdActions.SUCCESS(result.response));
  } else {
    yield* put(updatePayoutByIdActions.FAILURE(result.message));
  }
}

export default function* payoutSagas(): SagaIterator {
  yield* takeLatest(getListPayoutActions.REQUEST, getListPayout);
  yield* takeLatest(getPayoutByIdActions.REQUEST, getPayoutById);
  yield* takeLatest(updatePayoutByIdActions.REQUEST, updatePayoutById);
}
