import { createGenericTypes, createGenericActions } from "utils/createAction";

export const getListCouponTypes = createGenericTypes("GET_LIST_COUPON_CODE");
export const getListCouponActions = createGenericActions(getListCouponTypes);

export const getUsersOfCouponTypes = createGenericTypes(
  "GET_USERS_OF_COUPON_CODE"
);
export const getUsersOfCouponActions = createGenericActions(
  getUsersOfCouponTypes
);

export const getCouponByIdTypes = createGenericTypes("GET_COUPON_CODE_BY_ID");
export const getCouponByIdActions = createGenericActions(getCouponByIdTypes);

export const updateCouponByIdTypes = createGenericTypes(
  "UPDATE_COUPON_CODE_BY_ID"
);
export const updateCouponByIdActions = createGenericActions(
  updateCouponByIdTypes
);

export const createCouponTypes = createGenericTypes("CREATE_COUPON_CODE");
export const createCouponActions = createGenericActions(createCouponTypes);
