import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { userService } from "services";
import { searchActions } from "./actions";

function* handleSearch({ payload }: Action<string>) {
  const data: any = yield* call(userService.handleSearch, payload);
  if (data.ok) {
    yield* put(searchActions.SUCCESS(data.response));
  } else {
    yield* put(searchActions.FAILURE(data?.message || null));
  }
}

export default function* commonSagas(): SagaIterator {
  yield* takeLatest(searchActions.REQUEST, handleSearch);
}
