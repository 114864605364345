import { notification } from "antd";
import "antd/dist/antd.less";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTypedSelector } from "redux/rootReducer";
import { getUserProfileActions } from "redux/User/actions";
import { selectGetUserProfileError } from "redux/User/selector";
import Routes from "routes";
import { authService } from "services";
import "video.js/dist/video-js.css";
import "./App.scss";
import "./i18n";
import "./styles/icomoon/style.scss";

function App() {
  const dispatch = useDispatch();
  const error = useTypedSelector(selectGetUserProfileError);
  const history = useHistory();

  useEffect(() => {
    if (
      authService.authenticated &&
      error &&
      !["/signin", "/api/auth/callback/google", "/invitation"].includes(
        history?.location?.pathname
      )
    ) {
      notification.error({
        message: "Invalid token",
      });
      history.push("/signin");
    }
  }, [error, history]);

  useEffect(() => {
    if (authService.authenticated) {
      dispatch(getUserProfileActions.REQUEST());
    }
  }, [dispatch]);

  return <Routes />;
}

export default App;
