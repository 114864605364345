import { Button, Col, Dropdown, Layout, Menu, Row, Typography } from "antd";
import { ReactComponent as DownIcon } from "icons/arrow/down.svg";
import { ReactComponent as CouponIcon } from "icons/coupon.svg";
import { ReactComponent as RequestIcon } from "icons/request.svg";
import { ReactComponent as RequestorIcon } from "icons/requestor.svg";
import { ReactComponent as SRightIcon } from "icons/sright.svg";
import { ReactComponent as TalentIcon } from "icons/talent.svg";
import { ReactComponent as TeamMembersIcon } from "icons/team.svg";
import findLastIndex from "lodash/findLastIndex";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTypedSelector } from "redux/rootReducer";
import { logoutActions } from "redux/User/actions";
import { selectUserData } from "redux/User/selector";
import { UserRole, userRoleOptions } from "../../constants/user";
import { Paragraph } from "components/Typography";
import { KomiLogo } from "@komi-app/components";

import "./Sidebar.scss";

const { Sider } = Layout;
const { Text } = Typography;
const { SubMenu } = Menu;

interface MenuItem {
  title: string;
  route: string;
  icon?: ReactNode;
  parentKey?: string;
  children?: MenuItem[];
}

const MENU_ITEMS: MenuItem[] = [
  {
    icon: <TalentIcon />,
    title: "Talent Database",
    route: "/talents",
  },
  {
    icon: <RequestIcon />,
    title: "Experiences",
    route: "/experiences",
    parentKey: "experiences",
  },
  {
    icon: <RequestorIcon />,
    title: "List of consumers",
    route: "/consumers",
    parentKey: "consumers",
  },
  {
    icon: <RequestIcon />,
    title: "Purchase List",
    route: "/purchase-order",
    parentKey: "purchase-order",
  },

  {
    icon: <CouponIcon />,
    title: "Coupons",
    route: "/coupons",
  },
  {
    icon: <RequestIcon />,
    title: "Special Requests",
    route: "/special-requests",
    parentKey: "special-requests",
  },
];

const Sidebar = () => {
  const history = useHistory();
  const user = useTypedSelector(selectUserData);
  const [menuItems, setMenuItems] = useState<MenuItem[]>(MENU_ITEMS);
  const isAdmin = user?.roles?.some((item) => item.name === UserRole.ADMIN);
  const isExternal = user?.roles?.some(
    (item) => item.name === UserRole.EXTERNAL
  );
  const role = useMemo(() => {
    if (isAdmin) {
      return "Administrator";
    }
    if (isExternal) {
      return "External";
    }

    return "Editor";
  }, [isAdmin, isExternal]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAdmin) {
      setMenuItems([
        ...MENU_ITEMS,
        {
          icon: <TeamMembersIcon />,
          title: "Team members",
          route: "/team-members",
          parentKey: "team-members",
        },
      ]);
      return;
    }
    if (isExternal) {
      setMenuItems([
        {
          icon: <TalentIcon />,
          title: "Talent Database",
          route: "/talents",
        },
      ]);
    }
  }, [isAdmin, isExternal]);
  const getActiveItem = useCallback(() => {
    const flattenMenuItems = menuItems
      .map((item) => (item.children ? item.children : item))
      .flat(1);

    const index =
      history.location.pathname === "/"
        ? 0
        : findLastIndex(
            [...flattenMenuItems],
            (item) =>
              item.route !== "/" &&
              history.location.pathname.search(item.route) > -1
          );
    if (index > -1) {
      return {
        defaultOpenKeys: [flattenMenuItems[index].parentKey || ""],
        defaultSelectedKeys: [flattenMenuItems[index].route],
      };
    }

    return {
      defaultOpenKeys: [],
      defaultSelectedKeys: [],
    };
  }, [history]);

  useEffect(() => {
    if (!user) {
      history.push("/signin");
    }
  }, [user])

  const [openKeys, setOpenKeys] = useState<string[]>(
    getActiveItem().defaultOpenKeys
  );

  function clickLogout () {
    dispatch(logoutActions.REQUEST());
  }

  const menu = (
    <Menu className="main-header__dropdown-menu">
      <Menu.Item
        onClick={clickLogout}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Sider
      width={250}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
      }}
    >
      <Row className="sidebar-wrapper">
        <Col className="logo-wrapper text--center">
          <KomiLogo color="black" height={24} />
        </Col>
        <Col flex={1}>
          <Dropdown className="m__b--32" overlay={menu} trigger={["click"]}>
            <Button
              type="text"
              className="p--0 full-width full-height m__t--34"
              onClick={(e) => e.preventDefault()}
            >
              <Row
                className="profile-info full-width p__x--16"
                align="middle"
                justify="space-between"
              >
                <Col className="full-width">
                  <Paragraph
                    ellipsis={{ rows: 1 }}
                    className="profile-info__name m__b--0"
                  >
                    {`${user?.email || ""}`}
                  </Paragraph>
                  <Row>
                    <Text className="user-role-name text--left">
                      {role || ""}
                    </Text>
                  </Row>
                </Col>
              </Row>
            </Button>
          </Dropdown>

          <Menu
            mode="inline"
            onOpenChange={(keys: any) => setOpenKeys(keys)}
            onSelect={() => setOpenKeys(getActiveItem().defaultOpenKeys)}
            openKeys={openKeys}
            defaultSelectedKeys={getActiveItem().defaultSelectedKeys}
          >
            {menuItems.map((item) =>
              item.children ? (
                <SubMenu
                  key={item.route}
                  title={
                    <Row align="middle">
                      {item.icon}
                      <Text className="m__l--8">{item.title}</Text>
                    </Row>
                  }
                  icon={<SRightIcon className="ant-menu-submenu-icon" />}
                >
                  {item.children &&
                    (item.children as MenuItem[]).map((childMenu) => (
                      <Menu.Item key={childMenu.route}>
                        <Link to={childMenu.route} className="p__l--24">
                          {childMenu.title}
                        </Link>
                      </Menu.Item>
                    ))}
                </SubMenu>
              ) : (
                <Menu.Item key={item.route}>
                  <Link to={item.route}>
                    <Row align="middle">
                      {item.icon}
                      <Text className="m__l--12">{item.title}</Text>
                    </Row>
                  </Link>
                </Menu.Item>
              )
            )}
          </Menu>
        </Col>
      </Row>
    </Sider>
  );
};

export default Sidebar;
