import { getRequestorsActions } from "./actions";
import { RequestorState } from "./types";
import { handleActions, Action } from "redux-actions";

const handlers = {
  [getRequestorsActions.REQUEST]: (state: RequestorState): RequestorState => ({
    ...state,
    loading: true,
  }),
  [getRequestorsActions.SUCCESS]: (
    state: RequestorState,
    { payload }: Action<any>
  ): RequestorState => ({
    ...state,
    requestors: payload,
    loading: false,
  }),
  [getRequestorsActions.FAILURE]: (
    state: RequestorState,
    { payload }: Action<any>
  ): RequestorState => ({
    ...state,
    error: payload,
    loading: false,
  }),
};

const initialState: RequestorState = {
  requestors: undefined,
  error: undefined,
  loading: false,
};

export const requestorReducer = handleActions<RequestorState>(
  handlers,
  initialState
);
