import * as React from "react";
import Typography from "antd/lib/typography";
import { ParagraphProps as ParagraphAntdProps } from "antd/lib/typography/Paragraph";
import { TypographyPresets, presets } from "./Preset";

const ParagraphAntd = Typography.Paragraph;
interface ParagraphProps extends ParagraphAntdProps {
  preset?: TypographyPresets;
  className?: string;
}

export const Paragraph = (props: ParagraphProps) => {
  const { preset, className, ...rest } = props;
  const namePreset = preset && presets[preset];
  const mainClass = `text ${namePreset ? namePreset : ""} ${className}`;
  return <ParagraphAntd className={mainClass} {...rest} />;
};
