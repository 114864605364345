import { Action, handleActions } from "redux-actions";
import {
  getApplicationsActions,
  getApplicationByIdActions,
  updateApplicationByIdActions,
} from "./actions";
import { ApplicationState } from "./types";

const handlers = {
  // get list applications
  [getApplicationsActions.REQUEST]: (
    state: ApplicationState
  ): ApplicationState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [getApplicationsActions.SUCCESS]: (
    state: ApplicationState,
    { payload }: Action<any>
  ): ApplicationState => ({
    ...state,
    applications: payload,
    loading: false,
    type: getApplicationsActions.SUCCESS,
  }),
  [getApplicationsActions.FAILURE]: (
    state: ApplicationState,
    { payload }: Action<any>
  ): ApplicationState => ({
    ...state,
    error: payload,
    loading: false,
    type: getApplicationsActions.FAILURE,
  }),
  // get application by id
  [getApplicationByIdActions.REQUEST]: (
    state: ApplicationState
  ): ApplicationState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [getApplicationByIdActions.SUCCESS]: (
    state: ApplicationState,
    { payload }: Action<any>
  ): ApplicationState => ({
    ...state,
    application: payload,
    loading: false,
    type: getApplicationByIdActions.SUCCESS,
  }),
  [getApplicationByIdActions.FAILURE]: (
    state: ApplicationState,
    { payload }: Action<any>
  ): ApplicationState => ({
    ...state,
    error: payload,
    loading: false,
    type: getApplicationByIdActions.FAILURE,
  }),
  // update application by id
  [updateApplicationByIdActions.REQUEST]: (
    state: ApplicationState
  ): ApplicationState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [updateApplicationByIdActions.SUCCESS]: (
    state: ApplicationState,
    { payload }: Action<any>
  ): ApplicationState => ({
    ...state,
    application: payload,
    loading: false,
    type: updateApplicationByIdActions.SUCCESS,
  }),
  [updateApplicationByIdActions.FAILURE]: (
    state: ApplicationState,
    { payload }: Action<any>
  ): ApplicationState => ({
    ...state,
    error: payload,
    loading: false,
    type: updateApplicationByIdActions.FAILURE,
  }),
};

const initialState: ApplicationState = {
  applications: undefined,
  application: undefined,
  error: undefined,
  loading: false,
  type: undefined,
};

export const applicationReducer = handleActions<ApplicationState>(
  handlers,
  initialState
);
