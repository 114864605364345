import { Action, handleActions } from "redux-actions";
import {
  getConsumersActions,
  getConsumerByIdActions,
  updateConsumerActions,
  getConsumersTypes,
  getConsumerByIdTypes,
  updateConsumerTypes,
  getConsumerAnalyticsActions,
} from "./actions";
import { ConsumerState } from "./types";

const handlers = {
  // get list consumers
  [getConsumersActions.REQUEST]: (state: ConsumerState): ConsumerState => ({
    ...state,
    error: undefined,
    loading: true,
    type: null,
  }),
  [getConsumersActions.SUCCESS]: (
    state: ConsumerState,
    { payload }: Action<any>
  ): ConsumerState => ({
    ...state,
    consumers: payload,
    loading: false,
    type: getConsumersTypes.SUCCESS,
  }),
  [getConsumersActions.FAILURE]: (
    state: ConsumerState,
    { payload }: Action<any>
  ): ConsumerState => ({
    ...state,
    error: payload,
    loading: false,
    type: getConsumersTypes.FAILURE,
  }),
  // get consumer by id
  [getConsumerByIdActions.REQUEST]: (state: ConsumerState): ConsumerState => ({
    ...state,
    error: undefined,
    loading: true,
    type: null,
  }),
  [getConsumerByIdActions.SUCCESS]: (
    state: ConsumerState,
    { payload }: Action<any>
  ): ConsumerState => ({
    ...state,
    consumer: payload,
    loading: false,
    type: getConsumerByIdTypes.SUCCESS,
  }),
  [getConsumerByIdActions.FAILURE]: (
    state: ConsumerState,
    { payload }: Action<any>
  ): ConsumerState => ({
    ...state,
    error: payload,
    loading: false,
    type: getConsumerByIdTypes.FAILURE,
  }),
  // update consumer
  [updateConsumerActions.REQUEST]: (state: ConsumerState): ConsumerState => ({
    ...state,
    error: undefined,
    loading: true,
    type: null,
  }),
  [updateConsumerActions.SUCCESS]: (
    state: ConsumerState,
    { payload }: Action<any>
  ): ConsumerState => ({
    ...state,
    consumer: payload,
    loading: false,
    type: updateConsumerTypes.SUCCESS,
  }),
  [updateConsumerActions.FAILURE]: (
    state: ConsumerState,
    { payload }: Action<any>
  ): ConsumerState => ({
    ...state,
    error: payload,
    loading: false,
    type: updateConsumerTypes.FAILURE,
  }),
  // get talent analytics
  [getConsumerAnalyticsActions.REQUEST]: (
    state: ConsumerState
  ): ConsumerState => ({
    ...state,
    error: undefined,
    loading: true,
    analytics: undefined,
  }),
  [getConsumerAnalyticsActions.SUCCESS]: (
    state: ConsumerState,
    { payload }: Action<any>
  ): ConsumerState => ({
    ...state,
    analytics: payload,
    loading: false,
    type: getConsumerAnalyticsActions.SUCCESS,
  }),
  [getConsumerAnalyticsActions.FAILURE]: (
    state: ConsumerState,
    { payload }: Action<any>
  ): ConsumerState => ({
    ...state,
    error: payload,
    loading: false,
    type: getConsumerAnalyticsActions.FAILURE,
  }),
};

const initialState: ConsumerState = {
  consumers: undefined,
  consumer: undefined,
  error: undefined,
  loading: false,
  analytics: undefined,
};

export const consumerReducer = handleActions<ConsumerState>(
  handlers,
  initialState
);
