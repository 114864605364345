import {
  Contents1To1Request,
  Experience,
  EXPERIENCE_TYPE,
  UpdateExperience1To1Request,
  GetExperienceByIdRequest,
} from "redux/Experience/types";
import { ListQueryParams, Response } from "../../redux/Common/types";
import ExperienceService from "./ExperienceService";

export default class Experience1To1Service extends ExperienceService {
  getAllExperiences = (
    userId: string,
    params: ListQueryParams
  ): Promise<Response<Experience[]>> => {
    return this.api.getExperiences(null, userId, params);
  };

  getExperiences = (
    userId: string,
    params: ListQueryParams
  ): Promise<Response<Experience[]>> => {
    return this.api.getExperiences(EXPERIENCE_TYPE.ONE_TO_ONE, userId, params);
  };

  getExperienceById = (
    payload: GetExperienceByIdRequest
  ): Promise<Response<Experience>> => {
    return this.api.getExperienceById(payload);
  };

  createExperience = (
    payload: Contents1To1Request
  ): Promise<Response<Experience>> => {
    return this.api.createExperience1To1(payload);
  };

  updateExperience = (
    payload: UpdateExperience1To1Request
  ): Promise<Response<Experience>> => {
    return this.api.updateExperience1To1(payload);
  };

  publishExperience = (payload: string): Promise<Response<any>> => {
    return this.api.publishExperience1To1(payload);
  };
}
