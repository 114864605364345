import { couponReducer } from "./Coupon/reducer";
import { BookingState } from "./Booking/types";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { combineReducers } from "redux";
import { activationCodeReducer } from "./ActivationCode/reducer";
import { ActivationCodeState } from "./ActivationCode/types";
import { applicationReducer } from "./Application/reducer";
import { ApplicationState } from "./Application/types";
import { categoryReducer } from "./Category/reducer";
import { CategoryState } from "./Category/types";
import { consumerReducer } from "./Consumer/reducer";
import { ConsumerState } from "./Consumer/types";
import { requestorReducer } from "./Requestor/reducer";
import { RequestorState } from "./Requestor/types";
import { talentReducer } from "./Talent/reducer";
import { TalentState } from "./Talent/types";
import { userReducer } from "./User/reducer";
import { UserState } from "./User/types";
import { bookingReducer } from "./Booking/reducer";
import { RefundState } from "./Refund/types";
import { refundReducer } from "./Refund/reducer";
import { PayoutState } from "./Payout/types";
import { payoutReducer } from "./Payout/reducer";
import { CouponState } from "./Coupon/types";
import { SpecialRequestState } from "./SpecialRequest/types";
import { specialRequestReducer } from "./SpecialRequest/reducer";
import { ExperienceState } from "./Experience/types";
import { experienceReducer } from "./Experience/reducer";
import { CommonState } from "./Common/types";
import { commonReducer } from "./Common/reducer";
import { ProductState } from "./Product/types";
import { productReducer } from "./Product/reducer";
import { TagState } from "./Tags/types";
import { tagReducer } from "./Tags/reducer";
import { TimeRangeState } from "./TimeRange/types";
import { timeRangeReducer } from "./TimeRange/reducer";

type IRootState = {
  userState: UserState;
  talentState: TalentState;
  requestorState: RequestorState;
  activationCodeState: ActivationCodeState;
  applicationState: ApplicationState;
  categoryState: CategoryState;
  consumerState: ConsumerState;
  bookingState: BookingState;
  refundState: RefundState;
  payoutState: PayoutState;
  couponState: CouponState;
  specialRequestState: SpecialRequestState;
  experienceState: ExperienceState;
  commonState: CommonState;
  productState: ProductState;
  tagState: TagState;
  timeRangeState: TimeRangeState;
};

// Add all reducers here.
const rootReducer = combineReducers<IRootState>({
  userState: userReducer,
  talentState: talentReducer,
  requestorState: requestorReducer,
  activationCodeState: activationCodeReducer,
  applicationState: applicationReducer,
  categoryState: categoryReducer,
  consumerState: consumerReducer,
  bookingState: bookingReducer,
  refundState: refundReducer,
  payoutState: payoutReducer,
  couponState: couponReducer,
  specialRequestState: specialRequestReducer,
  experienceState: experienceReducer,
  commonState: commonReducer,
  productState: productReducer,
  tagState: tagReducer,
  timeRangeState: timeRangeReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
