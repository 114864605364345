import Cookies from "js-cookie";
import { SignInRequest, SignInResponse, User } from "./../redux/User/types";
import { Api } from "./api/api";
import { Response } from "redux/Common/types";
import config from "config";

export const KOMI_AUTH = "KOMI_AUTH";
export const KOMI_SESSION = "KOMI_SESSION";
export const KOMI_CSRF = "KOMI_CSRF"

export interface IAuthService {}

export interface IToken {
  exp: number;
  iat: number;
  userId: string;
}

export default class AuthService implements IAuthService {
  private _authenticated = false;
  private _id: string | null = null;

  constructor(private readonly api: Api) {
    this.api = api;
    this._authenticated = Cookies.get(KOMI_SESSION) === 'true';
  }

  private set authenticated (value: boolean) {
    this._authenticated = value;
    Cookies.set(KOMI_SESSION, `${value}`, {
      domain: `.${config.talent.domain}`
    })
  }

  get authenticated (): boolean {
    return this._authenticated;
  }

  get id(): string | null {
    return this._id;
  }

  private authenticate<T> (resp: Response<T>) {
    const value = resp as any;
    this.authenticated = true;
    this._id = value.ok && value.response?.user?.id;
    return resp;
  }

  logout = (): Promise<Response<any>> => {
    return this.api.signout()
      .then(value => {
        this._id = null;
        this.authenticated = false;
        return value;
      });
  };

  signin = (payload: SignInRequest): Promise<Response<SignInResponse>> => {
    return this.api.signin(payload)
      .then(r => this.authenticate<SignInResponse>(r));
  };
  signInWithGoogle = (accessToken: string): Promise<Response<User>> => {
    return this.api.signInWithGoogle(accessToken)
      .then(r => this.authenticate<User>(r));
  };
}
