import React from "react";
import { Row, Spin } from "antd";
import "./Loading.scss";

interface IProps {
  size?: "large" | "default" | "small";
  showFull?: boolean;
  className?: string;
}

const Loading = ({
  size = "default",
  showFull = false,
  className = "",
}: IProps) => {
  return (
    <Row
      className={
        showFull ? `loading loading--full ${className}` : `loading ${className}`
      }
      justify="center"
      align="middle"
    >
      <Spin size={size} />
    </Row>
  );
};

export default Loading;
