import DashboardLayout from "layouts/DashboardLayout";
import EmptyLayout from "layouts/EmptyLayout";
import React, { ComponentType, lazy, ReactNode } from "react";
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { useTypedSelector } from "redux/rootReducer";

import { authService } from "services";
import ExperienceLayout from "layouts/ExperienceLayout";
import { selectUserData } from "../redux/User/selector";
import { CreatedBy } from "../constants/talent";

const CouponDetail = lazy(() => import("pages/CouponDetail"));
const PurchaseOrderDetail = lazy(() => import("pages/PurchaseOrderDetail"));
const Coupons = lazy(() => import("pages/Coupons"));
// const PayoutDetail = lazy(() => import("pages/PayoutDetail"));
const RefundDetail = lazy(() => import("pages/RefundDetail"));
// const PayoutRequests = lazy(() => import("pages/PayoutRequests"));
const RefundRequests = lazy(() => import("pages/RefundRequests"));
const SpecialRequests = lazy(() => import("pages/SpecialRequests"));
const SpecialRequestDetail = lazy(() => import("pages/SpecialRequestDetail"));
const Experiences = lazy(() => import("pages/Experiences"));
const ErrorPage = lazy(() => import("pages/ErrorPage"));
const SignIn = lazy(() => import("pages/SignIn/SignIn"));
const ListTalents = lazy(() => import("pages/ListTalents"));
const TeamMembers = lazy(() => import("pages/TeamMembers"));
const CallbackGoogle = lazy(() => import("pages/CallbackGoogle/google"));
// const ActivationCode = lazy(() => import("pages/ActivationCode"));
// const ListTalentsApplication = lazy(
//   () => import("pages/ListTalentsApplication")
// );
const TalentDetail = lazy(() => import("pages/TalentDetail"));
const EditTalent = lazy(() => import("pages/EditTalent"));
const ListConsumer = lazy(() => import("pages/ListConsumer"));
const ConsumerDetail = lazy(() => import("pages/ConsumerDetail"));
const PurchaseOrder = lazy(() => import("pages/PurchaseOrder"));
const Invitation = lazy(() => import("pages/InvitationPage"));

const CreateExperienceContent = lazy(
  () => import("pages/CreateExperienceContent")
);
const CreateExperience1To1 = lazy(() => import("pages/CreateExperience1To1"));
const CreateECommerce = lazy(() => import("pages/CreateECommerce"));
const CreateExperienceLiveClass = lazy(
  () => import("pages/CreateExperienceLiveClass")
);
const CreateExperienceInterActiveLiveClass = lazy(
  () => import("pages/CreateExperienceInterActiveLiveClass")
);
const CreateExclusiveContent = lazy(
  () => import("pages/CreateExclusiveContent")
);
const CreateExperienceCourse = lazy(
  () => import("pages/CreateExperienceCourse")
);
const CreateBundle = lazy(() => import("pages/CreateExperienceBundle"));
const CreateNewTalent = lazy(() => import("pages/CreateNewTalent"));
// const Trending = lazy(() => import("pages/Trending"));
// const Categories = lazy(() => import("pages/Categories"));

interface ILayoutProps {
  children: ReactNode;
  showSidebar?: boolean;
  isExperienceFlow?: boolean;
  className?: string;
}

interface IProps extends RouteProps {
  loading?: boolean;
  component: ComponentType<RouteProps>;
  layout: ComponentType<ILayoutProps>;
  showSidebar?: boolean;
  isExperienceFlow?: boolean;
  className?: string;
}

export const ProtectedRoute = ({
  component: Component,
  layout: Layout,
  isExperienceFlow,
  showSidebar,
  className,
  ...rest
}: IProps) => {
  const hasUser = authService.authenticated;

  return (
    <Route
      {...rest}
      render={(props) =>
        hasUser ? (
          <Layout
            showSidebar={showSidebar}
            isExperienceFlow={isExperienceFlow}
            className={className}
          >
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );
};

export const PublicRoute = ({
  component: Component,
  layout: Layout,
  showSidebar,
  isExperienceFlow,
  className,
  ...rest
}: IProps) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout
        showSidebar={showSidebar}
        isExperienceFlow={isExperienceFlow}
        className={className}
      >
        <Component {...props} />
      </Layout>
    )}
  />
);

const ExperienceRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/create/one_to_one`}
        layout={ExperienceLayout}
        component={CreateExperience1To1}
      />

      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/create/live_class`}
        layout={ExperienceLayout}
        component={CreateExperienceLiveClass}
      />

      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/create/interactive_live_class`}
        layout={ExperienceLayout}
        component={CreateExperienceInterActiveLiveClass}
      />

      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/create/course`}
        layout={ExperienceLayout}
        component={CreateExperienceCourse}
      />

      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/create/exclusive_content`}
        layout={ExperienceLayout}
        component={CreateExclusiveContent}
      />

      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/create/e_commerce`}
        layout={ExperienceLayout}
        component={CreateECommerce}
      />

      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/create/bundle`}
        layout={ExperienceLayout}
        component={CreateBundle}
      />

      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/create`}
        layout={ExperienceLayout}
        component={CreateExperienceContent}
      />
      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/one_to_one/:id`}
        layout={ExperienceLayout}
        component={CreateExperience1To1}
      />

      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/live_class/:id`}
        layout={ExperienceLayout}
        component={CreateExperienceLiveClass}
      />

      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/interactive_live_class/:id`}
        layout={ExperienceLayout}
        component={CreateExperienceInterActiveLiveClass}
      />

      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/exclusive_content/:id`}
        layout={ExperienceLayout}
        component={CreateExclusiveContent}
      />

      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/course/:id`}
        layout={ExperienceLayout}
        component={CreateExperienceCourse}
      />

      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/e_commerce/:id`}
        layout={ExperienceLayout}
        component={CreateECommerce}
      />

      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/bundle/:id`}
        layout={ExperienceLayout}
        component={CreateBundle}
      />
    </Switch>
  );
};

const Routes = () => {
  const user = useTypedSelector(selectUserData);
  const isAdmin = user?.roles?.some((item) => item.name === CreatedBy.ADMIN);

  return (
    <Switch>
      <ProtectedRoute
        exact
        path="/"
        layout={DashboardLayout}
        component={() => <Redirect to="/talents" />}
      />
      <ProtectedRoute
        exact
        path="/talents/create"
        layout={DashboardLayout}
        component={CreateNewTalent}
      />

      <ProtectedRoute
        exact
        path="/coupons/create"
        layout={DashboardLayout}
        component={CouponDetail}
      />

      <ProtectedRoute
        exact
        path="/coupons/:id/edit"
        layout={DashboardLayout}
        component={CouponDetail}
      />

      <ProtectedRoute
        exact
        path="/coupons/:id"
        layout={DashboardLayout}
        component={CouponDetail}
      />

      <ProtectedRoute
        exact
        path="/coupons"
        layout={DashboardLayout}
        component={Coupons}
      />

      <ProtectedRoute
        exact
        path="/talents/:id"
        layout={DashboardLayout}
        component={EditTalent}
      />

      <ProtectedRoute
        exact
        path="/talents"
        layout={DashboardLayout}
        component={ListTalents}
      />

      <ProtectedRoute
        exact
        path="/consumers/:id/edit"
        layout={DashboardLayout}
        component={ConsumerDetail}
      />

      <ProtectedRoute
        exact
        path="/consumers/:id"
        layout={DashboardLayout}
        component={ConsumerDetail}
      />

      <ProtectedRoute
        exact
        path="/consumers"
        layout={DashboardLayout}
        component={ListConsumer}
      />

      <ProtectedRoute
        exact
        path="/purchase-order/:id"
        layout={DashboardLayout}
        component={PurchaseOrderDetail}
      />

      <ProtectedRoute
        exact
        path="/purchase-order"
        layout={DashboardLayout}
        component={PurchaseOrder}
      />

      <ProtectedRoute
        exact
        path="/refund-requests/:id/edit"
        layout={DashboardLayout}
        component={RefundDetail}
      />

      <ProtectedRoute
        exact
        path="/refund-requests/:id"
        layout={DashboardLayout}
        component={RefundDetail}
      />

      <ProtectedRoute
        exact
        path="/refund-requests"
        layout={DashboardLayout}
        component={RefundRequests}
      />

      <ProtectedRoute
        exact
        path="/special-requests/:id/edit"
        layout={DashboardLayout}
        component={SpecialRequestDetail}
      />

      <ProtectedRoute
        exact
        path="/special-requests/:id"
        layout={DashboardLayout}
        component={SpecialRequestDetail}
      />

      <ProtectedRoute
        exact
        path="/special-requests"
        layout={DashboardLayout}
        component={SpecialRequests}
      />

      <ProtectedRoute
        exact
        path="/experiences"
        layout={DashboardLayout}
        component={Experiences}
      />
      <ProtectedRoute
        exact
        path="/team-members"
        layout={DashboardLayout}
        component={isAdmin ? TeamMembers : () => <Redirect to="/" />}
      />

      <Route path="/experience" component={ExperienceRoutes} />

      <PublicRoute
        exact
        path="/signin"
        layout={EmptyLayout}
        component={SignIn}
      />
      <PublicRoute
        exact
        path="/invitation"
        layout={EmptyLayout}
        component={Invitation}
      />
      <PublicRoute
        exact
        path="/api/auth/callback/google"
        layout={EmptyLayout}
        component={CallbackGoogle}
      />

      <PublicRoute path="*" component={ErrorPage} layout={EmptyLayout} />
    </Switch>
  );
};

export default Routes;
