import {
  GetRefundsRequest,
  Refund,
  DeclineRefundRequest,
} from "./../redux/Refund/types";
import { Pagination, Response } from "./../redux/Common/types";
import { Api } from "./api";

export interface IRefundService {
  getListRefunds(
    params: GetRefundsRequest
  ): Promise<Response<Pagination<Refund[]>>>;
  getRefundById(id: string): Promise<Response<Refund>>;
  acceptRefund(id: string): Promise<Response<Refund>>;
  declineRefund(payload: DeclineRefundRequest): Promise<Response<Refund>>;
}
export default class RefundService implements IRefundService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getListRefunds = (
    params: GetRefundsRequest
  ): Promise<Response<Pagination<Refund[]>>> => {
    return this.api.getListRefunds(params);
  };

  getRefundById = (id: string): Promise<Response<Refund>> => {
    return this.api.getRefundById(id);
  };

  acceptRefund = (id: string): Promise<Response<Refund>> => {
    return this.api.acceptRefund(id);
  };

  declineRefund = (
    payload: DeclineRefundRequest
  ): Promise<Response<Refund>> => {
    return this.api.declineRefund(payload);
  };
}
