import { EXPERIENCE_TYPE, Experience } from "redux/Experience/types";

export enum EVENT_WEBVIEW_MESSAGE {
  DONE = "done",
  STEP_TRACK = "step_track",
  LOADING = "loading",
  SELECT_COTENT_TYPE = "select_content_type",
  NOTIFICATION = "notification",
  REGISTRATION_TALENT_COMPLETED = "registration_talent_completed",
  OPEN_WEB_BROWSER = "open_web_browser",
  ALERT = "alert",
  RECORD_EXP_DATA = "record_experience_data",
  LOG_ERROR_MESSAGE = "log_error_message",
}

export enum WEBVIEW_MESSAGE_TYPE {
  SUCCESS,
  ERROR,
  WARNING,
}

export type NOTIFICATION_WEBVIEW = {
  type: WEBVIEW_MESSAGE_TYPE;
  message: string;
  isOffDuration?: boolean;
};

export enum ALERT_ACCEPT {
  SAVE_AND_DRAFT_EXPERIENCE = "SAVE_AND_DRAFT_EXPERIENCE",
}

export enum WEBVIEW_ERROR_MESSAGE_TYPE {
  CREATE_AND_UPDATE_EXP = "CREATE_AND_UPDATE_EXPERIENCE",
}

export enum WEBVIEW_ERROR_MESSAGE_TX {
  MISSING_CONTENT_VIDEO = "missingContentVideo",
}

export enum ALERT_TYPE_WEBVIEW {
  NOT_FILL_TIME_SLOT = "NOT_FILL_TIME_SLOT",
  UPLOAD_VIDEO_SIZE_BIG = "UPLOAD_VIDEO_SIZE_BIG",
}

export type ALERT_PARAMS = {
  title?: string;
  type: ALERT_TYPE_WEBVIEW;
  subTitle?: string;
  button1?: string;
  button2?: string;
  data?: {
    experienceId?: string;
    experienceType?: EXPERIENCE_TYPE;
  };
};

export type EVENT_WEBVIEW_DATA = {
  step?: number;
  isLoading?: boolean;
  contentType?: EXPERIENCE_TYPE | undefined;
  notification?: NOTIFICATION_WEBVIEW;
  webBrowser?: string;
  alert?: ALERT_PARAMS;
  experienceData?: {
    id: string | undefined;
    data: Experience;
  };
  errorMessage?: {
    [key in WEBVIEW_ERROR_MESSAGE_TYPE]?: WEBVIEW_ERROR_MESSAGE_TX | undefined;
  };
};
