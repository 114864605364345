import { Action, handleActions } from "redux-actions";

import { CouponState } from "./types";
import {
  getListCouponActions,
  getListCouponTypes,
  getUsersOfCouponActions,
  getCouponByIdTypes,
  getCouponByIdActions,
  updateCouponByIdActions,
  updateCouponByIdTypes,
  createCouponActions,
  createCouponTypes,
} from "./actions";

const handlers = {
  // get list coupon code
  [getListCouponActions.REQUEST]: (state: CouponState): CouponState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [getListCouponActions.SUCCESS]: (
    state: CouponState,
    { payload }: Action<any>
  ): CouponState => ({
    ...state,
    listCoupon: payload,
    loading: false,
    type: getListCouponTypes.SUCCESS,
  }),
  [getListCouponActions.FAILURE]: (
    state: CouponState,
    { payload }: Action<any>
  ): CouponState => ({
    ...state,
    error: payload,
    loading: false,
    type: getListCouponTypes.FAILURE,
  }),
  // get list user use coupon code
  [getUsersOfCouponActions.REQUEST]: (state: CouponState): CouponState => ({
    ...state,
    error: undefined,
    loadingUsers: true,
  }),
  [getUsersOfCouponActions.SUCCESS]: (
    state: CouponState,
    { payload }: Action<any>
  ): CouponState => ({
    ...state,
    users: payload,
    loadingUsers: false,
  }),
  [getUsersOfCouponActions.FAILURE]: (
    state: CouponState,
    { payload }: Action<any>
  ): CouponState => ({
    ...state,
    error: payload,
    loadingUsers: false,
  }),
  // get coupon code by id
  [getCouponByIdActions.REQUEST]: (state: CouponState): CouponState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [getCouponByIdActions.SUCCESS]: (
    state: CouponState,
    { payload }: Action<any>
  ): CouponState => ({
    ...state,
    coupon: payload,
    type: getCouponByIdTypes.SUCCESS,
    loading: false,
  }),
  [getCouponByIdActions.FAILURE]: (
    state: CouponState,
    { payload }: Action<any>
  ): CouponState => ({
    ...state,
    error: payload,
    loading: false,
    type: getCouponByIdTypes.FAILURE,
  }),
  // update coupon code by id
  [updateCouponByIdActions.REQUEST]: (state: CouponState): CouponState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [updateCouponByIdActions.SUCCESS]: (
    state: CouponState,
    { payload }: Action<any>
  ): CouponState => ({
    ...state,
    coupon: payload,
    loading: false,
    type: updateCouponByIdTypes.SUCCESS,
  }),
  [updateCouponByIdActions.FAILURE]: (
    state: CouponState,
    { payload }: Action<any>
  ): CouponState => ({
    ...state,
    error: payload,
    loading: false,
    type: updateCouponByIdTypes.FAILURE,
  }),
  // create coupon code by id
  [createCouponActions.REQUEST]: (state: CouponState): CouponState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [createCouponActions.SUCCESS]: (
    state: CouponState,
    { payload }: Action<any>
  ): CouponState => ({
    ...state,
    coupon: payload,
    loading: false,
    type: createCouponTypes.SUCCESS,
  }),
  [createCouponActions.FAILURE]: (
    state: CouponState,
    { payload }: Action<any>
  ): CouponState => ({
    ...state,
    error: payload,
    loading: false,
    type: createCouponTypes.FAILURE,
  }),
};

const initialState: CouponState = {
  listCoupon: undefined,
  coupon: undefined,
  users: undefined,
  error: undefined,
  loading: false,
  loadingUsers: false,
};

export const couponReducer = handleActions<CouponState>(handlers, initialState);
