import { ListQueryParams, Pagination } from "redux/Common/types";
import { Experience } from "redux/Experience/types";
import { Talent } from "redux/Talent/types";
import { User } from "redux/User/types";

export enum PURCHASE_TYPE {
  ALL_PURCHASES = "all_purchase",
}

type Video = {
  createdAt: string;
  id: string;
  status: string;
  updatedAt: string;
  videoOriginalSource: string;
  zencoderJobId: string;
  metadata: {
    duration_in_ms: number;
    file_size_in_bytes: number;
    format: string;
    height: number;
    id: number;
    state: string;
    url: string;
    width: number;
  }[];
};

export type Booking = {
  createdAt?: string;
  updatedAt?: string;
  isExpired?: boolean;
  id?: string;
  forMe?: boolean;
  from?: string | null;
  to?: string | null;
  message?: string;
  isPublic?: boolean;
  status?: string;
  videos?: Video[];
  requestor?: User;
  talent?: Talent;
  price?: number;
  occasion?: {
    id: number;
    name: string;
  };
  experience?: Experience;
  paymentStatus?: string;
  payment?: {
    id?: string;
    purchaseOn?: string;
    paymentMethodDetails?: {
      card?: {
        brand: string;
        country: string;
        last4: string;
      };
      type?: string;
    };
  };
  date?: string;
  duration?: number;
  room?: {
    id: number;
    roomId: string;
    name: string;
  };
  notes?: string;
  rating?: {
    id: number;
    value: number;
    comment: string;
  };
  comment?: string;
  tips?: number;
  rejectNotes?: string;
  shortenId?: string;
  transactions?: {
    amount: string;
    braintreeTransactions: any;
    createdAt: string;
    currency: string;
    id: string;
    paymentMethodNonce: string | null;
    pricePercent: string | null;
    transactionStatus: TransactionStatus;
    transactionType: string;
    updatedAt: string;
    paymentGatewayProvider: string;
  }[];
  tip?: number;
  payout?: string;
  description?: string;
  amount?: number;
  type?: string;
};

export enum TransactionStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  REFUNDED = "REFUNDED",
  REFUND_PENDING = "REFUND_PENDING",
  REFUND_FAILED = "REFUND_FAILED",
}

export enum PaymentMethodType {
  CREDIT_CARD = "credit_card",
  APPLE_PAY = "apple_pay",
  PAYPAL = "paypal",
  PAYPAL_ACCOUNT = "paypal_account",
}

export type GetBookingRequest = {
  userId: User["id"];
  params: ListQueryParams;
};

export type GetBookingDetailRequest = {
  userId: User["id"];
  bookingId: Booking["id"];
};

export type RejectBookingRequest = {
  bookingId: Booking["id"];
  notes: string;
};

export type BookingState = {
  bookings1To1?: Pagination<Booking[]> | null;
  booking1To1Data?: Booking | null;
  error?: string | null;
  loading: boolean;
};

export enum BookingStatusType {
  ALL = "all",
  ADMIN_APPROVAL_PENDING = "admin_approval_pending", //waiting for admin approval
  TALENT_APPROVAL_PENDING = "talent_approval_pending", //waiting for talent approval
  PENDING = "pending", //waiting for payment
  ACCEPTED = "accepted", //Payment success and talent accepted
  ADMIN_DECLINED = "admin_declined", //Admin rejected
  DECLINED = "declined", //Talent rejected
  CANCELED = "canceled", //Canceled
  COMPLETED = "completed",
  EXPIRED = "expired",
  UNCOMPLETED = "uncompleted",
  REFUNDED = "REFUNDED",
  REFUND_PENDING = "REFUND_PENDING",
  REFUND_FAILED = "REFUND_FAILED",
  PAYMENT_FAILED = "payment_failed", //No success payment until required completion date
}
