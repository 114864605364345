import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { ListQueryParams } from "redux/Common/types";
import { resetGalleryActions } from "redux/Talent/actions";
import { talentService } from "services";
import {
  getApplicationByIdActions,
  getApplicationsActions,
  updateApplicationByIdActions,
} from "./actions";
import { ApplicationType } from "./types";

function* getApplications({ payload }: Action<ListQueryParams>) {
  const result: any = yield* call(talentService.getApplications, payload);
  if (result.ok) {
    yield* put(getApplicationsActions.SUCCESS(result.response));
  } else {
    yield* put(getApplicationsActions.FAILURE(result.message));
  }
}

function* getApplicationById({ payload }: Action<string>) {
  const result: any = yield* call(talentService.getApplicationById, payload);
  yield* put(resetGalleryActions.SUCCESS(null));
  if (result.ok) {
    yield* put(getApplicationByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getApplicationByIdActions.FAILURE(result.message));
  }
}

function* updateApplicationById({ payload }: Action<ApplicationType>) {
  const result: any = yield* call(talentService.updateApplicationById, payload);
  if (result.ok) {
    yield* put(updateApplicationByIdActions.SUCCESS(result.response));
  } else {
    yield* put(updateApplicationByIdActions.FAILURE(result.message));
  }
}

export default function* applicationSagas(): SagaIterator {
  yield* takeLatest(getApplicationsActions.REQUEST, getApplications);
  yield* takeLatest(getApplicationByIdActions.REQUEST, getApplicationById);
  yield* takeLatest(
    updateApplicationByIdActions.REQUEST,
    updateApplicationById
  );
}
