import { createGenericTypes, createGenericActions } from "utils/createAction";

export const getTalentsTypes = createGenericTypes("GET_TALENTS");
export const getTalentsActions = createGenericActions(getTalentsTypes);

export const getTalentsInCategoryTypes = createGenericTypes(
  "GET_TALENTS_IN_CATEGORY"
);
export const getTalentsInCategoryActions = createGenericActions(
  getTalentsInCategoryTypes
);

export const getTrendingTalentsTypes = createGenericTypes(
  "GET_TRENDING_TALENTS"
);
export const getTrendingTalentsActions = createGenericActions(
  getTrendingTalentsTypes
);

export const getTrendingCategoryTalentsTypes = createGenericTypes(
  "GET_TRENDING_CATEGORY_TALENTS"
);
export const getTrendingCategoryTalentsActions = createGenericActions(
  getTrendingCategoryTalentsTypes
);

export const getTalentByIdTypes = createGenericTypes("GET_TALENT_BY_ID");
export const getTalentByIdActions = createGenericActions(getTalentByIdTypes);

export const updateTalentByIdTypes = createGenericTypes("UPDATE_TALENT_BY_ID");
export const updateTalentByIdActions = createGenericActions(
  updateTalentByIdTypes
);

export const updateTalentGalleryTypes = createGenericTypes(
  "UPDATE_TALENT_GALLERY"
);
export const updateTalentGalleryActions = createGenericActions(
  updateTalentGalleryTypes
);

export const getTalentAnalyticsTypes = createGenericTypes(
  "GET_TALENT_ANALYTICS"
);
export const getTalentAnalyticsActions = createGenericActions(
  getTalentAnalyticsTypes
);

export const resetGalleryTypes = createGenericTypes("RESET_GALLERY");
export const resetGalleryActions = createGenericActions(resetGalleryTypes);

export const resetTalentsInCategoryResultsTypes = createGenericTypes(
  "RESET_TALENT_IN_CATEGORY_RESULTS"
);
export const resetTalentsInCategoryResultsActions = createGenericActions(
  resetTalentsInCategoryResultsTypes
);

export const bulkUpdateTalentsTypes = createGenericTypes("BULK_UPDATE_TALENTS");
export const bulkUpdateTalentsActions = createGenericActions(
  bulkUpdateTalentsTypes
);
export const createTalentsTypes = createGenericTypes("CREATE_TALENT");
export const createTalentsActions = createGenericActions(createTalentsTypes);

export const deleteTalentsTypes = createGenericTypes("DELETE_TALENT");
export const deleteTalentsActions = createGenericActions(deleteTalentsTypes);
