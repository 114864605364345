import { createGenericTypes, createGenericActions } from "utils/createAction";

export const getListPayoutTypes = createGenericTypes("GET_LIST_PAYOUT");
export const getListPayoutActions = createGenericActions(getListPayoutTypes);

export const getPayoutByIdTypes = createGenericTypes("GET_PAYOUT_DETAIL_BY_ID");
export const getPayoutByIdActions = createGenericActions(getPayoutByIdTypes);

export const updatePayoutByIdTypes = createGenericTypes("UPDATE_PAYOUT_BY_ID");
export const updatePayoutByIdActions = createGenericActions(
  updatePayoutByIdTypes
);
