import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { authService, userService } from "services";
import { call, put, takeLatest } from "typed-redux-saga";
import { UserRole } from "../../constants/user";
import notification from "../../utils/notification";
import {
  acceptCollaboratorInviteActions,
  cancelInviteCollaboratorActions,
  getCollaboratorInvitationsActions,
  getUserProfileActions,
  inviteCollaboratorActions,
  logoutActions,
  revokeInviteCollaboratorActions,
  signInActions,
  signInWithGoogleActions,
} from "./actions";
import {
  AcceptCollaboratorInvitationPayout,
  CancelInviteCollaboratorRequest,
  InviteCollaboratorRequest,
  RevokeInviteCollaboratorRequest,
  SignInGoogleRequest,
  SignInRequest,
} from "./types";

function* signin({ payload }: Action<SignInRequest>) {
  const result: any = yield* call(authService.signin, payload);
  if (result.ok) {
    const { response } = result;
    if (
      response.user?.roles &&
      response.user.roles.map((role: any) => role.name).includes("admin")
    ) {
      // authService.jwt = response.accessToken;
      yield* put(signInActions.SUCCESS(response.user));
    } else {
      yield* put(signInActions.FAILURE("Please login as admin"));
    }
  } else {
    yield* put(
      signInActions.FAILURE("Your email and/or password do not match.")
    );
  }
}

function* getUserProfile() {
  const result: any = yield* call(userService.getUserProfile);
  if (result.ok) {
    const { response } = result;
    if (
      response?.roles?.some((role: any) =>
        [UserRole.ADMIN, UserRole.EXTERNAL, UserRole.MODERATOR].includes(
          role.name
        )
      )
    ) {
      yield* put(getUserProfileActions.SUCCESS(response));
    } else {
      yield* put(getUserProfileActions.FAILURE("Invalid token"));
    }
  } else {
    yield* put(getUserProfileActions.FAILURE("Invalid token"));
  }
}

function* logout() {
  try {
    yield* call(authService.logout);
    yield* put(logoutActions.SUCCESS());
  } catch (error) {
    yield* put(logoutActions.FAILURE("Cannot logout"));
  }
}

function* getCollaboratorInvitations() {
  const result: any = yield* call(userService.getCollaborator);
  if (result.ok) {
    yield* put(getCollaboratorInvitationsActions.SUCCESS(result.response));
  } else {
    yield* put(getCollaboratorInvitationsActions.FAILURE(result.message));
  }
}
function* inviteCollaborator(action?: Action<InviteCollaboratorRequest>) {
  const result: any = yield* call(
    userService.inviteCollaborator,
    action?.payload as InviteCollaboratorRequest
  );
  if (result.ok) {
    yield* put(inviteCollaboratorActions.SUCCESS(result.response));
    yield* call(getCollaboratorInvitations);
  } else {
    yield* put(inviteCollaboratorActions.FAILURE(result.message));
  }
}

function* cancelInviteCollaborator(
  action?: Action<CancelInviteCollaboratorRequest>
) {
  const result: any = yield* call(
    userService.cancelInviteCollaborator,
    action?.payload as InviteCollaboratorRequest
  );
  if (result.ok) {
    yield* put(cancelInviteCollaboratorActions.SUCCESS(result.response));
    yield* call(getCollaboratorInvitations);
  } else {
    yield* put(cancelInviteCollaboratorActions.FAILURE(result.message));
  }
}

function* revokeInviteCollaborator(
  action?: Action<RevokeInviteCollaboratorRequest>
) {
  const result: any = yield* call(
    userService.revokeInviteCollaborator,
    action?.payload as RevokeInviteCollaboratorRequest
  );
  if (result.ok) {
    yield* put(revokeInviteCollaboratorActions.SUCCESS(result.response));
    yield* call(getCollaboratorInvitations);
  } else {
    yield* put(revokeInviteCollaboratorActions.FAILURE(result.message));
  }
}
function* acceptCollaboratorInvite({
  payload,
}: Action<AcceptCollaboratorInvitationPayout>) {
  const result: any = yield* call(
    userService.acceptCollaboratorInvitation,
    payload.form
  );
  if (result.ok) {
    const { response } = result;
    notification.success({
      message: "The invitation has been accepted successfully",
    });
    yield* put(acceptCollaboratorInviteActions.SUCCESS(response));
    payload.callback?.();
  } else {
    notification.error({ message: result.message });
    yield* put(acceptCollaboratorInviteActions.FAILURE(result.message));
    payload.callback?.();
  }
}
function* signInWithGoogle({ payload }: Action<SignInGoogleRequest>) {
  const result: any = yield* call(authService.signInWithGoogle, payload.token);
  const { response } = result;
  if (result.ok) {
    // authService.jwt = response.accessToken;
    yield* put(signInActions.SUCCESS(response.user));
  } else {
    yield* put(signInWithGoogleActions.FAILURE(result.message));
  }
}

export default function* userSagas(): SagaIterator {
  yield* takeLatest(signInActions.REQUEST, signin);
  yield* takeLatest(getUserProfileActions.REQUEST, getUserProfile);
  yield* takeLatest(logoutActions.REQUEST, logout);
  yield* takeLatest(
    getCollaboratorInvitationsActions.REQUEST,
    getCollaboratorInvitations
  );

  yield* takeLatest(inviteCollaboratorActions.REQUEST, inviteCollaborator);

  yield* takeLatest(
    cancelInviteCollaboratorActions.REQUEST,
    cancelInviteCollaborator
  );

  yield* takeLatest(
    revokeInviteCollaboratorActions.REQUEST,
    revokeInviteCollaborator
  );
  yield* takeLatest(
    acceptCollaboratorInviteActions.REQUEST,
    acceptCollaboratorInvite
  );
  yield* takeLatest(signInWithGoogleActions.REQUEST, signInWithGoogle);
}
