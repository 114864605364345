import { createAction } from "redux-actions";
import { createGenericTypes, createGenericActions } from "utils/createAction";

export const getCategoriesTypes = createGenericTypes("GET_CATEGORIES");
export const getCategoriesActions = createGenericActions(getCategoriesTypes);

export const createCategoryTypes = createGenericTypes("CREATE_CATEGORY");
export const createCategoryActions = createGenericActions(createCategoryTypes);

export const updateCategoryTypes = createGenericTypes("UPDATE_CATEGORY");
export const updateCategoryActions = createGenericActions(updateCategoryTypes);

export const bulkUpdateCategoriesTypes = createGenericTypes(
  "BULK_UPDATE_CATEGORIES"
);
export const bulkUpdateCategoriesActions = createGenericActions(
  bulkUpdateCategoriesTypes
);

export const deleteCategoryTypes = createGenericTypes("DELETE_CATEGORY");
export const deleteCategoryActions = createGenericActions(deleteCategoryTypes);

export const resetCategoryState = "RESET_CATEGORY_STATE";
export const resetCategoryStateAction = createAction(resetCategoryState);
