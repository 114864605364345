export enum UserRole {
  MODERATOR = "moderator",
  ADMIN = "admin",
  USER = "user",
  EXTERNAL = "external",
}
export const userRoleOptions = [
  {
    type: UserRole.ADMIN,
    title: "Administrator",
  },
  {
    type: UserRole.MODERATOR,
    title: "Editor",
  },
  {
    type: UserRole.EXTERNAL,
    title: "External",
  },
];
export enum MemberStatus {
  PENDING = "PENDING",
  EXPIRED = "EXPIRED",
  ACCEPTED = "ACCEPTED",
}
