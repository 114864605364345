import { EXPERIENCE_TYPE } from "redux/Experience/types";
import ActivationCodeService from "./ActivationCodeService";
import { Api } from "./api/api";
import AuthService from "./AuthService";
import BookingFactoryService from "./booking/BookingFactoryService";
import CategoryService from "./CategoryService";
import AWSService from "./externals/AWSService";
import PayoutService from "./PayoutService";
import PhotoService from "./PhotoService";
import RefundService from "./RefundService";
import TalentService from "./TalentService";
import UserService from "./UserService";
import CouponService from "./CouponService";
import SpecialRequestService from "./SpecialRequestService";
import ExperienceFactoryService from "./experience/ExperienceFactoryService";
import ProductService from "./ProductService";
import TagService from "./TagService";
import TimeRangeService from "./TimeRangeService";
import IAPService from "./IAPService";
import ExperienceCommonService from "./experience/ExperienceCommonService";

export const Apisauce = new Api();
Apisauce.setup();

export const authService = new AuthService(Apisauce);
export const talentService = new TalentService(Apisauce);
export const userService = new UserService(Apisauce);
export const photoService = new PhotoService(Apisauce);
export const activationCodeService = new ActivationCodeService(Apisauce);
export const categoryService = new CategoryService(Apisauce);
export const awsService = AWSService.getInstance();
export const booking1To1Service = BookingFactoryService.createService(
  Apisauce,
  EXPERIENCE_TYPE.ONE_TO_ONE
);
export const refundService = new RefundService(Apisauce);
export const specialRequestService = new SpecialRequestService(Apisauce);
export const payoutService = new PayoutService(Apisauce);
export const couponService = new CouponService(Apisauce);

export const experience1To1Service = ExperienceFactoryService.createService(
  Apisauce,
  EXPERIENCE_TYPE.ONE_TO_ONE
);
export const experienceLiveClassService = ExperienceFactoryService.createService(
  Apisauce,
  EXPERIENCE_TYPE.LIVE_CLASS
);
export const experienceInteractiveLiveClassService = ExperienceFactoryService.createService(
  Apisauce,
  EXPERIENCE_TYPE.INTERACTIVE_LIVE_CLASS
);
export const exclusiveContentService = ExperienceFactoryService.createService(
  Apisauce,
  EXPERIENCE_TYPE.EXCLUSIVE_CONTENT
);
export const courseService = ExperienceFactoryService.createService(
  Apisauce,
  EXPERIENCE_TYPE.COURSE
);
export const experienceService = new ExperienceCommonService(Apisauce);
export const productService = new ProductService(Apisauce);
export const tagService = new TagService(Apisauce);
export const timeRangeService = new TimeRangeService(Apisauce);
export const iapService = new IAPService(Apisauce);
