import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { ListQueryParams } from "redux/Common/types";
import { couponService } from "services";
import {
  createCouponActions,
  getCouponByIdActions,
  getListCouponActions,
  getUsersOfCouponActions,
  updateCouponByIdActions,
} from "./actions";
import { CreateCouponType, GetUsersOfCouponType } from "./types";

function* getListCoupon({ payload }: Action<ListQueryParams>) {
  const result: any = yield* call(couponService.getCoupons, payload);
  if (result.ok) {
    yield* put(getListCouponActions.SUCCESS(result.response));
  } else {
    yield* put(getListCouponActions.FAILURE(result.message));
  }
}

function* getCouponById({ payload }: Action<string>) {
  const result: any = yield* call(couponService.getCouponById, payload);
  if (result.ok) {
    yield* put(getCouponByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getCouponByIdActions.FAILURE(result.message));
  }
}

function* updateCouponById({ payload }: Action<CreateCouponType>) {
  const result: any = yield* call(couponService.updateCouponById, payload);
  if (result.ok) {
    yield* put(updateCouponByIdActions.SUCCESS(result.response));
  } else {
    yield* put(updateCouponByIdActions.FAILURE(result.message));
  }
}

function* createCoupon({ payload }: Action<CreateCouponType>) {
  const result: any = yield* call(couponService.createCoupon, payload);
  if (result.ok) {
    yield* put(createCouponActions.SUCCESS(result.response));
  } else {
    yield* put(createCouponActions.FAILURE(result.message));
  }
}

function* getTalentsOfCoupon({ payload }: Action<GetUsersOfCouponType>) {
  const result: any = yield* call(couponService.getUsersOfCoupon, payload);
  if (result.ok) {
    yield* put(getUsersOfCouponActions.SUCCESS(result.response));
  } else {
    yield* put(getUsersOfCouponActions.FAILURE(result.message));
  }
}

export default function* couponSagas(): SagaIterator {
  yield* takeLatest(getListCouponActions.REQUEST, getListCoupon);
  yield* takeLatest(getCouponByIdActions.REQUEST, getCouponById);
  yield* takeLatest(updateCouponByIdActions.REQUEST, updateCouponById);
  yield* takeLatest(createCouponActions.REQUEST, createCoupon);
  yield* takeLatest(getUsersOfCouponActions.REQUEST, getTalentsOfCoupon);
}
