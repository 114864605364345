import sortBy from "lodash/sortBy";
import { Action, handleActions } from "redux-actions";
import {
  bulkUpdateCategoriesActions,
  createCategoryActions,
  deleteCategoryActions,
  getCategoriesActions,
  resetCategoryState,
  updateCategoryActions,
} from "./actions";
import { Category, CategoryState } from "./types";

const handlers = {
  // get list categories
  [getCategoriesActions.REQUEST]: (state: CategoryState): CategoryState => ({
    ...state,
    error: undefined,
    loading: true,
    type: null,
  }),
  [getCategoriesActions.SUCCESS]: (
    state: CategoryState,
    { payload }: Action<any>
  ): CategoryState => {
    const subCategories = payload?.items
      ?.map((category: Category) => category?.subCategories)
      ?.flat(1)
      ?.filter((category: Category) => category?.trendingOrder);

    return {
      ...state,
      categories: payload,
      trendingSubCategories: sortBy(subCategories, [
        function (o) {
          return o?.trendingOrder;
        },
      ]),
      loading: false,
      type: getCategoriesActions.SUCCESS,
    };
  },
  [getCategoriesActions.FAILURE]: (
    state: CategoryState,
    { payload }: Action<any>
  ): CategoryState => ({
    ...state,
    error: payload,
    loading: false,
    type: getCategoriesActions.FAILURE,
  }),
  // create new category
  [createCategoryActions.REQUEST]: (state: CategoryState): CategoryState => ({
    ...state,
    error: undefined,
    loading: true,
    type: null,
  }),
  [createCategoryActions.SUCCESS]: (
    state: CategoryState,
    { payload }: Action<any>
  ): CategoryState => ({
    ...state,
    category: payload,
    loading: false,
    type: createCategoryActions.SUCCESS,
  }),
  [createCategoryActions.FAILURE]: (
    state: CategoryState,
    { payload }: Action<any>
  ): CategoryState => ({
    ...state,
    error: payload,
    loading: false,
    type: createCategoryActions.FAILURE,
  }),
  // update category
  [updateCategoryActions.REQUEST]: (state: CategoryState): CategoryState => ({
    ...state,
    error: undefined,
    loading: true,
    type: null,
  }),
  [updateCategoryActions.SUCCESS]: (
    state: CategoryState,
    { payload }: Action<any>
  ): CategoryState => ({
    ...state,
    category: payload,
    loading: false,
    type: updateCategoryActions.SUCCESS,
  }),
  [updateCategoryActions.FAILURE]: (
    state: CategoryState,
    { payload }: Action<any>
  ): CategoryState => ({
    ...state,
    error: payload,
    loading: false,
    type: updateCategoryActions.FAILURE,
  }),
  // delete category
  [deleteCategoryActions.REQUEST]: (state: CategoryState): CategoryState => ({
    ...state,
    error: undefined,
    loading: true,
    type: null,
  }),
  [deleteCategoryActions.SUCCESS]: (
    state: CategoryState,
    { payload }: Action<any>
  ): CategoryState => ({
    ...state,
    category: payload,
    loading: false,
    type: deleteCategoryActions.SUCCESS,
  }),
  [deleteCategoryActions.FAILURE]: (
    state: CategoryState,
    { payload }: Action<any>
  ): CategoryState => ({
    ...state,
    error: payload,
    loading: false,
    type: deleteCategoryActions.FAILURE,
  }),
  // bulk update category
  [bulkUpdateCategoriesActions.REQUEST]: (
    state: CategoryState
  ): CategoryState => ({
    ...state,
    error: undefined,
    loading: true,
    type: null,
  }),
  [bulkUpdateCategoriesActions.SUCCESS]: (
    state: CategoryState
  ): CategoryState => ({
    ...state,
    loading: false,
    type: bulkUpdateCategoriesActions.SUCCESS,
  }),
  [bulkUpdateCategoriesActions.FAILURE]: (
    state: CategoryState,
    { payload }: Action<any>
  ): CategoryState => ({
    ...state,
    error: payload,
    loading: false,
    type: bulkUpdateCategoriesActions.FAILURE,
  }),
  [resetCategoryState]: (state: CategoryState): CategoryState => ({
    ...state,
    type: null,
    loading: false,
    error: undefined,
  }),
};

const initialState: CategoryState = {
  categories: undefined,
  trendingSubCategories: undefined,
  category: undefined,
  error: undefined,
  loading: false,
  type: null,
};

export const categoryReducer = handleActions<CategoryState>(
  handlers,
  initialState
);
