import { Booking, GetBookingRequest } from "redux/Booking/types";
import { Pagination } from "redux/Common/types";
import { Response } from "../../redux/Common/types";
import { RejectBookingRequest } from "./../../redux/Booking/types";
import BookingService from "./BookingService";

export default class Booking1To1Service extends BookingService {
  getBooking = (
    payload: GetBookingRequest
  ): Promise<Response<Pagination<Booking[]>>> => {
    return this.api.getBookings1To1(payload);
  };

  getBookingById = (payload: string): Promise<Response<Booking>> => {
    return this.api.getBooking1To1ById(payload);
  };

  rejectBooking = (
    payload: RejectBookingRequest
  ): Promise<Response<Booking>> => {
    return this.api.rejectBooking(payload);
  };
}
