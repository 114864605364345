import sortBy from "lodash/sortBy";
import { Action, handleActions } from "redux-actions";
import {
  getTalentByIdActions,
  getTalentsActions,
  updateTalentByIdActions,
  updateTalentGalleryActions,
  getTalentAnalyticsActions,
  resetGalleryActions,
  getTrendingTalentsActions,
  bulkUpdateTalentsActions,
  getTrendingCategoryTalentsActions,
  getTalentsInCategoryActions,
  resetTalentsInCategoryResultsActions,
  createTalentsActions,
  deleteTalentsActions,
} from "./actions";
import { TalentState } from "./types";

const handlers = {
  // get list talents
  [getTalentsActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [getTalentsActions.SUCCESS]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    talents: payload,
    loading: false,
    type: getTalentsActions.SUCCESS,
  }),
  [getTalentsActions.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    error: payload,
    loading: false,
    type: getTalentsActions.FAILURE,
  }),
  // get list talents in category
  [getTalentsInCategoryActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [getTalentsInCategoryActions.SUCCESS]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    talentsInCategory: payload,
    loading: false,
    type: getTalentsInCategoryActions.SUCCESS,
  }),
  [getTalentsInCategoryActions.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    error: payload,
    loading: false,
    type: getTalentsInCategoryActions.FAILURE,
  }),
  // get list trending talents
  [getTrendingTalentsActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [getTrendingTalentsActions.SUCCESS]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    trendingTalents: sortBy(payload?.items, [
      function (o) {
        return o.talentProfile?.trendingOrder;
      },
    ]),
    loading: false,
    type: getTrendingTalentsActions.SUCCESS,
  }),
  [getTrendingTalentsActions.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    error: payload,
    loading: false,
    type: getTrendingTalentsActions.FAILURE,
  }),
  // get list trending talents of category
  [getTrendingCategoryTalentsActions.REQUEST]: (
    state: TalentState
  ): TalentState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [getTrendingCategoryTalentsActions.SUCCESS]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    trendingCategoryTalents: sortBy(payload?.items, [
      function (o) {
        return o.talentProfile?.categoryTrendingOrder;
      },
    ]),
    loading: false,
    type: getTrendingCategoryTalentsActions.SUCCESS,
  }),
  [getTrendingCategoryTalentsActions.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    error: payload,
    loading: false,
    type: getTrendingCategoryTalentsActions.FAILURE,
  }),
  // get talent by id
  [getTalentByIdActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    error: undefined,
    loading: true,
    talent: undefined,
  }),
  [getTalentByIdActions.SUCCESS]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    talent: payload,
    loading: false,
    type: getTalentsActions.SUCCESS,
    gallery: {},
  }),
  [getTalentByIdActions.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    error: payload,
    loading: false,
    type: getTalentsActions.FAILURE,
  }),
  // update talent by id
  [updateTalentByIdActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [updateTalentByIdActions.SUCCESS]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    talent: payload,
    loading: false,
    type: updateTalentByIdActions.SUCCESS,
    gallery: {},
  }),
  [updateTalentByIdActions.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    error: payload,
    loading: false,
    type: updateTalentByIdActions.FAILURE,
  }),
  // bulk update talents
  [bulkUpdateTalentsActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [bulkUpdateTalentsActions.SUCCESS]: (state: TalentState): TalentState => ({
    ...state,
    loading: false,
    type: bulkUpdateTalentsActions.SUCCESS,
  }),
  [bulkUpdateTalentsActions.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    error: payload,
    loading: false,
    type: bulkUpdateTalentsActions.FAILURE,
  }),
  // update talent gallery
  [updateTalentGalleryActions.REQUEST]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    gallery: {
      ...state.gallery,
      [payload.uploadItemIndex]: payload.photo,
    },
  }),
  // get talent analytics
  [getTalentAnalyticsActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    error: undefined,
    loading: true,
    analytics: undefined,
  }),
  [getTalentAnalyticsActions.SUCCESS]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    analytics: payload,
    loading: false,
    type: getTalentAnalyticsActions.SUCCESS,
  }),
  [getTalentAnalyticsActions.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    error: payload,
    loading: false,
    type: getTalentAnalyticsActions.FAILURE,
  }),
  // reset gallery
  [resetGalleryActions.SUCCESS]: (state: TalentState): TalentState => ({
    ...state,
    gallery: {},
  }),
  // reset talents in category results
  [resetTalentsInCategoryResultsActions.SUCCESS]: (
    state: TalentState
  ): TalentState => ({
    ...state,
    talentsInCategory: undefined,
  }),
  // create talent
  [createTalentsActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [createTalentsActions.SUCCESS]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    loading: false,
    error: undefined,
    type: createTalentsActions.SUCCESS,
  }),
  [createTalentsActions.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    error: payload,
    loading: false,
    type: createTalentsActions.FAILURE,
  }),
  // delete talent
  [deleteTalentsActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    error: undefined,
  }),
  [deleteTalentsActions.SUCCESS]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    error: undefined,
    type: deleteTalentsActions.SUCCESS,
  }),
  [deleteTalentsActions.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => ({
    ...state,
    error: payload,
    type: deleteTalentsActions.FAILURE,
  }),
};

const initialState: TalentState = {
  talents: undefined,
  talent: undefined,
  error: undefined,
  loading: false,
  gallery: {},
  type: undefined,
  analytics: undefined,
};

export const talentReducer = handleActions<TalentState>(handlers, initialState);
