import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, debounce } from "typed-redux-saga";
import { tagService } from "./../../services/index";
import { getTagsActions } from "./actions";
import { ListQueryParams } from "redux/Common/types";

function* getTags({
  payload,
}: Action<ListQueryParams & Required<{ search: string }>>) {
  const data: any = yield* call(tagService.getTags, payload);
  if (data.ok) {
    yield* put(getTagsActions.SUCCESS(data.response));
  } else {
    yield* put(getTagsActions.FAILURE(data?.message || null));
  }
}

export default function* tagSagas(): SagaIterator {
  yield* debounce(100, getTagsActions.REQUEST, getTags);
}
