import { Action, handleActions } from "redux-actions";
import {
  acceptCollaboratorInviteActions,
  acceptCollaboratorInviteTypes,
  cancelInviteCollaboratorActions,
  getCollaboratorInvitationsActions,
  getCollaboratorInvitationsTypes,
  getUserProfileActions,
  inviteCollaboratorActions,
  inviteCollaboratorTypes,
  logoutActions,
  revokeInviteCollaboratorActions,
  signInActions,
  signInWithGoogleActions,
  signInWithGoogleTypes,
} from "./actions";
import { UserState } from "./types";

const initialState: UserState = {
  error: null,
  errorType: null,
  loading: false,
  user: undefined,
  type: null,
  collaborators: [],
};

const handlers = {
  // sign in
  [signInActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    errorType: null,
    loading: true,
  }),
  [signInActions.SUCCESS]: (
    state: UserState,
    { payload }: Action<any>
  ): UserState => ({
    ...state,
    user: payload,
    loading: false,
    errorType: null,
  }),
  [signInActions.FAILURE]: (
    state: UserState,
    { payload }: Action<any>
  ): UserState => ({
    ...state,
    error: payload,
    loading: false,
    errorType: signInActions.FAILURE,
  }),
  // get user by id
  [getUserProfileActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    errorType: null,
    loading: true,
  }),
  [getUserProfileActions.SUCCESS]: (
    state: UserState,
    { payload }: Action<any>
  ): UserState => ({
    ...state,
    user: payload,
    loading: false,
    errorType: null,
  }),
  [getUserProfileActions.FAILURE]: (
    state: UserState,
    { payload }: Action<any>
  ): UserState => ({
    ...state,
    error: payload,
    loading: false,
    errorType: getUserProfileActions.FAILURE,
  }),
  // logout
  [logoutActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    errorType: null,
    loading: true,
  }),
  [logoutActions.SUCCESS]: (state: UserState): UserState => ({
    ...state,
    user: undefined,
    loading: false,
    errorType: null,
  }),
  [logoutActions.FAILURE]: (
    state: UserState,
    { payload }: Action<any>
  ): UserState => ({
    ...state,
    error: payload,
    loading: false,
    errorType: logoutActions.FAILURE,
  }),
  // get talent collaborator
  [getCollaboratorInvitationsActions.REQUEST]: (
    state: UserState
  ): UserState => {
    return {
      ...state,
      error: null,
      loading: true,
      type: null,
    };
  },
  [getCollaboratorInvitationsActions.SUCCESS]: (
    state: UserState,
    { payload }: Action<any>
  ): UserState => {
    return {
      ...state,
      error: null,
      loading: false,
      collaborators: payload,
      type: getCollaboratorInvitationsTypes.SUCCESS,
    };
  },
  [getCollaboratorInvitationsActions.FAILURE]: (
    state: UserState
  ): UserState => {
    return {
      ...state,
      error: null,
      loading: false,
      type: getCollaboratorInvitationsTypes.FAILURE,
    };
  },

  // invite talent collaborator
  [inviteCollaboratorActions.REQUEST]: (state: UserState): UserState => {
    return {
      ...state,
      error: null,
      errorType: null,
      loading: true,
      type: null,
    };
  },
  // invite talent collaborator
  [inviteCollaboratorActions.REQUEST]: (state: UserState): UserState => {
    return {
      ...state,
      error: null,
      loading: true,
      type: inviteCollaboratorActions.SUCCESS,
    };
  },
  [inviteCollaboratorActions.FAILURE]: (
    state: UserState,
    { payload }: Action<any>
  ): UserState => {
    return {
      ...state,
      loading: false,
      error: payload,
      errorType: inviteCollaboratorActions.FAILURE,
    };
  },

  // cancel talent collaborator
  [cancelInviteCollaboratorActions.REQUEST]: (state: UserState): UserState => {
    return {
      ...state,
      error: null,
      errorType: null,
      loading: true,
      type: null,
    };
  },
  // cancel talent collaborator
  [cancelInviteCollaboratorActions.SUCCESS]: (state: UserState): UserState => {
    return {
      ...state,
      error: null,
      loading: true,
      type: cancelInviteCollaboratorActions.SUCCESS,
    };
  },

  // revoke talent collaborator
  [cancelInviteCollaboratorActions.FAILURE]: (
    state: UserState,
    { payload }: Action<any>
  ): UserState => {
    return {
      ...state,
      loading: false,
      error: payload,
      errorType: cancelInviteCollaboratorActions.FAILURE,
    };
  },
  [revokeInviteCollaboratorActions.REQUEST]: (state: UserState): UserState => {
    return {
      ...state,
      error: null,
      loading: true,
      type: null,
      errorType: null,
    };
  },

  [revokeInviteCollaboratorActions.SUCCESS]: (state: UserState): UserState => {
    return {
      ...state,
      error: null,
      loading: true,
      type: revokeInviteCollaboratorActions.SUCCESS,
    };
  },

  [revokeInviteCollaboratorActions.FAILURE]: (
    state: UserState,
    { payload }: Action<any>
  ): UserState => {
    return {
      ...state,
      loading: false,
      error: payload,
      errorType: revokeInviteCollaboratorActions.FAILURE,
    };
  },
  // accept collaborator invite
  [acceptCollaboratorInviteActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    errorType: null,
    type: null,
    loading: true,
  }),
  [acceptCollaboratorInviteActions.SUCCESS]: (state: UserState): UserState => ({
    ...state,
    loading: false,
    user: undefined,
    type: acceptCollaboratorInviteTypes.SUCCESS,
  }),
  [acceptCollaboratorInviteActions.FAILURE]: (
    state: UserState,
    { payload }: Action<any>
  ): UserState => ({
    ...state,
    loading: false,
    user: undefined,
    error: payload,
    type: acceptCollaboratorInviteTypes.FAILURE,
  }),
  // signIn with Google
  [signInWithGoogleActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    loading: true,
    errorType: null,
  }),
  [signInWithGoogleActions.SUCCESS]: (
    state: UserState,
    { payload }: Action<any>
  ): UserState => ({
    ...state,
    loading: false,
    user: payload,
    errorType: null,
  }),
  [signInWithGoogleActions.FAILURE]: (
    state: UserState,
    { payload }: Action<any>
  ): UserState => ({
    ...state,
    error: payload,
    loading: false,
    errorType: signInWithGoogleTypes.FAILURE,
  }),
};

export const userReducer = handleActions<UserState>(handlers, initialState);
