import sortBy from "lodash/sortBy";
import { Action, handleActions } from "redux-actions";
import {
  createExperience1To1Actions,
  getExperiencesActions,
  updateExperience1To1Actions,
  updateExperienceProcessStatusActions,
  updateStagingCoversActions,
  getExperienceByIdActions,
  createExperienceActions,
  publishExperienceActions,
  updateProductImagesActions,
  createExperienceLiveClassActions,
  updateExperienceLiveClassActions,
  createExclusiveContentActions,
  updateExclusiveContentActions,
  updateLessonsActions,
  updateAttachmentsActions,
  createExperienceCourseActions,
  updateExperienceCourseActions,
  getTrendingExperiencesActions,
  bulkUpdateExperiencesActions,
  getTrendingCategoryExperiencesActions,
  getExperiencesInCategoryActions,
  resetExperienceErrorState,
  updateExperienceInteractiveLiveClassActions,
  createExperienceInteractiveLiveClassActions,
} from "./actions";
import { ExperienceState, EXPERIENCE_PROCESS_STATUS } from "./types";

const handlers = {
  // get experiences
  [getExperiencesActions.REQUEST]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    error: null,
    publishError: null,
    loading: true,
    experience: null,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.EMPTY,
  }),
  [getExperiencesActions.SUCCESS]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    experience: {
      ...payload,
    },
    error: null,
    loading: false,
  }),
  [getExperiencesActions.FAILURE]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // get experiences in category
  [getExperiencesInCategoryActions.REQUEST]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    experience: null,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.EMPTY,
  }),
  [getExperiencesInCategoryActions.SUCCESS]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    experiencesInCategory: {
      ...payload,
    },
    error: null,
    loading: false,
  }),
  [getExperiencesInCategoryActions.FAILURE]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // get list trending experiences
  [getTrendingExperiencesActions.REQUEST]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [getTrendingExperiencesActions.SUCCESS]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    trendingExperiences: sortBy(payload?.items, [
      function (o) {
        return o?.trendingOrder;
      },
    ]),
    loading: false,
    type: getTrendingExperiencesActions.SUCCESS,
  }),
  [getTrendingExperiencesActions.FAILURE]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
    type: getTrendingExperiencesActions.FAILURE,
  }),
  // get list trending experiences of category
  [getTrendingCategoryExperiencesActions.REQUEST]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [getTrendingCategoryExperiencesActions.SUCCESS]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    trendingCategoryExperiences: sortBy(payload?.items, [
      function (o) {
        return o?.categoryTrendingOrder;
      },
    ]),
    loading: false,
    type: getTrendingCategoryExperiencesActions.SUCCESS,
  }),
  [getTrendingCategoryExperiencesActions.FAILURE]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
    type: getTrendingCategoryExperiencesActions.FAILURE,
  }),
  // get experience by id
  [getExperienceByIdActions.REQUEST]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    experienceDetail: null,
    error: null,
    loading: true,
  }),
  [getExperienceByIdActions.SUCCESS]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    experienceDetail: payload,
    fetchExperienceDetail: payload,
    loading: false,
  }),
  [getExperienceByIdActions.FAILURE]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // create experience
  [createExperienceActions.REQUEST]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    ...payload,
  }),
  // create experience 1 to 1
  [createExperience1To1Actions.REQUEST]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.DRAFTED,
  }),
  [createExperience1To1Actions.SUCCESS]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    experienceDetail: payload,
    loading: false,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.CREATED,
  }),
  [createExperience1To1Actions.FAILURE]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // update experience 1 to 1
  [updateExperience1To1Actions.REQUEST]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.CREATED,
  }),
  [updateExperience1To1Actions.SUCCESS]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    experienceDetail: {
      ...payload,
      isBooked: state?.experienceDetail?.isBooked || false,
    },
    fetchExperienceDetail: {
      ...payload,
      isBooked: state?.experienceDetail?.isBooked || false,
    },
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.UPDATED,
    loading: false,
  }),
  [updateExperience1To1Actions.FAILURE]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // create experience Live Class
  [createExperienceLiveClassActions.REQUEST]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.DRAFTED,
  }),
  [createExperienceLiveClassActions.SUCCESS]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    experienceDetail: payload,
    loading: false,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.CREATED,
  }),
  [createExperienceLiveClassActions.FAILURE]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // update experience live class
  [updateExperienceLiveClassActions.REQUEST]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.CREATED,
  }),
  [updateExperienceLiveClassActions.SUCCESS]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    experienceDetail: {
      ...payload,
      isBooked: state?.experienceDetail?.isBooked || false,
    },
    fetchExperienceDetail: {
      ...payload,
      isBooked: state?.experienceDetail?.isBooked || false,
    },
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.UPDATED,
    loading: false,
  }),
  [updateExperienceLiveClassActions.FAILURE]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // create experience Interactive Live Class
  [createExperienceInteractiveLiveClassActions.REQUEST]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.DRAFTED,
  }),
  [createExperienceInteractiveLiveClassActions.SUCCESS]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    experienceDetail: payload,
    loading: false,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.CREATED,
  }),
  [createExperienceInteractiveLiveClassActions.FAILURE]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // update experience interactive live class
  [updateExperienceInteractiveLiveClassActions.REQUEST]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.CREATED,
  }),
  [updateExperienceInteractiveLiveClassActions.SUCCESS]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    experienceDetail: {
      ...payload,
      isBooked: state?.experienceDetail?.isBooked || false,
    },
    fetchExperienceDetail: {
      ...payload,
      isBooked: state?.experienceDetail?.isBooked || false,
    },
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.UPDATED,
    loading: false,
  }),
  [updateExperienceInteractiveLiveClassActions.FAILURE]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // create experience exclusive content
  [createExclusiveContentActions.REQUEST]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.DRAFTED,
  }),
  [createExclusiveContentActions.SUCCESS]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    experienceDetail: payload,
    loading: false,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.CREATED,
  }),
  [createExclusiveContentActions.FAILURE]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // update experience exclusive content
  [updateExclusiveContentActions.REQUEST]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.CREATED,
  }),
  [updateExclusiveContentActions.SUCCESS]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    experienceDetail: {
      ...payload,
      isBooked: state?.experienceDetail?.isBooked || false,
    },
    fetchExperienceDetail: {
      ...payload,
      isBooked: state?.experienceDetail?.isBooked || false,
    },
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.UPDATED,
    loading: false,
  }),
  [updateExclusiveContentActions.FAILURE]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // update experience process status
  [updateExperienceProcessStatusActions.REQUEST]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    contentProcessStatus: payload,
  }),
  // update photos and videos of experience
  [updateStagingCoversActions.REQUEST]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => {
    let stagingCovers = state.stagingCovers;

    if (payload?.uploadItemIndex) {
      stagingCovers = {
        ...stagingCovers,
        [payload.uploadItemIndex]:
          stagingCovers &&
          stagingCovers[payload.uploadItemIndex] &&
          payload.photo.percent
            ? { ...stagingCovers[payload.uploadItemIndex], ...payload.photo }
            : payload.photo,
      };
    } else {
      Object.keys(stagingCovers).map((key) => {
        stagingCovers[key] = { id: stagingCovers[key].id };
        return null;
      });
    }
    return {
      ...state,
      stagingCovers,
    };
  },

  // create experience course
  [createExperienceCourseActions.REQUEST]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.DRAFTED,
  }),
  [createExperienceCourseActions.SUCCESS]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    experienceDetail: payload,
    loading: false,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.CREATED,
  }),
  [createExperienceCourseActions.FAILURE]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // update experience exclusive content
  [updateExperienceCourseActions.REQUEST]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.CREATED,
  }),
  [updateExperienceCourseActions.SUCCESS]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    experienceDetail: {
      ...payload,
      isBooked: state?.experienceDetail?.isBooked || false,
    },
    fetchExperienceDetail: {
      ...payload,
      isBooked: state?.experienceDetail?.isBooked || false,
    },
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.UPDATED,
    loading: false,
  }),
  [updateExperienceCourseActions.FAILURE]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // update experience process status
  [updateExperienceProcessStatusActions.REQUEST]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    contentProcessStatus: payload,
  }),

  // update photos product images
  [updateProductImagesActions.REQUEST]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => {
    let productImages = state.productImages;

    if (payload?.uploadItemIndex) {
      productImages = {
        ...productImages,
        [payload.uploadItemIndex]: payload.photo,
      };
    } else {
      Object.keys(productImages).map((key) => {
        productImages[key] = { id: productImages[key].id };
        return null;
      });
    }
    return {
      ...state,
      productImages,
    };
  },
  // update lessons list
  [updateLessonsActions.REQUEST]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => {
    let lessons = state.lessons;

    if (payload?.uploadItemIndex) {
      lessons = {
        ...lessons,
        [payload.uploadItemIndex]:
          lessons && lessons[payload.uploadItemIndex] && payload.photo.percent
            ? { ...lessons[payload.uploadItemIndex], ...payload.photo }
            : payload.photo,
      };
    } else {
      Object.keys(lessons).map((key) => {
        lessons[key] = { id: lessons[key].id };
        return null;
      });
    }
    return {
      ...state,
      lessons,
    };
  },
  // update attachments list
  [updateAttachmentsActions.REQUEST]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => {
    let attachments = state.attachments;

    if (payload?.uploadItemIndex) {
      attachments = {
        ...attachments,
        [payload.uploadItemIndex]:
          attachments &&
          attachments[payload.uploadItemIndex] &&
          payload.photo.percent
            ? { ...attachments[payload.uploadItemIndex], ...payload.photo }
            : payload.photo,
      };
    } else {
      Object.keys(attachments).map((key) => {
        attachments[key] = { id: attachments[key].id };
        return null;
      });
    }
    return {
      ...state,
      attachments,
    };
  },
  [publishExperienceActions.REQUEST]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    error: null,
    publishError: null,
    loading: true,
  }),
  [publishExperienceActions.SUCCESS]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    publishError: null,
    error: null,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.PUBLISHED,
    loading: false,
  }),
  [publishExperienceActions.FAILURE]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    publishError: payload,
    loading: false,
  }),

  // bulk update experiences
  [bulkUpdateExperiencesActions.REQUEST]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    error: undefined,
    loading: true,
  }),
  [bulkUpdateExperiencesActions.SUCCESS]: (
    state: ExperienceState
  ): ExperienceState => ({
    ...state,
    loading: false,
    type: bulkUpdateExperiencesActions.SUCCESS,
  }),
  [bulkUpdateExperiencesActions.FAILURE]: (
    state: ExperienceState,
    { payload }: Action<any>
  ): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
    type: bulkUpdateExperiencesActions.FAILURE,
  }),
  [resetExperienceErrorState]: (state: ExperienceState): ExperienceState => ({
    ...state,
    error: null,
    publishError: null,
    loading: false,
  }),
};

const initialState: ExperienceState = {
  error: null,
  loading: false,
  experience: null,
  experiencesInCategory: null,
  experienceDetail: null,
  fetchExperienceDetail: null,
  stagingCovers: {},
  productImages: {},
  attachments: {},
  lessons: {},
  contentProcessStatus: EXPERIENCE_PROCESS_STATUS.EMPTY,
  trendingExperiences: null,
  trendingCategoryExperiences: null,
  type: null,
};

export const experienceReducer = handleActions<ExperienceState>(
  handlers,
  initialState
);
