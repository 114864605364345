import { Layout } from "antd";
import classNames from "classnames";
import Loading from "components/Loading";
import MainHeader from "components/MainHeader";
import Sidebar from "components/Sidebar";
import React, { ReactNode, Suspense } from "react";
import "./ExperienceLayout.scss";

interface IProps {
  children?: ReactNode;
  showSidebar?: boolean;
  isExperienceFlow?: boolean;
  className?: string;
}

const { Content, Header } = Layout;

const ExperienceLayout: React.FC<IProps> = ({
  children,
  showSidebar,
  isExperienceFlow = false,
  className = "",
}) => {
  const layoutClassnames = classNames({
    "experience-layout": true,
    [className]: true,
    "experience-layout--experience": isExperienceFlow
  });

  return (
    <>
      <Layout className={layoutClassnames}>
        <Header className="experience-layout__header">
          <MainHeader isExperienceFlow={isExperienceFlow} />
        </Header>
        <Layout className="experience-layout__content" id="scrollableTarget">
          {showSidebar && <Sidebar />}
          <Content className="container">
            <Suspense fallback={<Loading showFull={isExperienceFlow} />}>
              {children}
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default ExperienceLayout;
