import { createGenericTypes, createGenericActions } from "utils/createAction";

export const signInTypes = createGenericTypes("SIGN_IN");
export const signInActions = createGenericActions(signInTypes);

export const logoutTypes = createGenericTypes("LOGOUT");
export const logoutActions = createGenericActions(logoutTypes);

export const getUserProfileTypes = createGenericTypes("GET_USER_PROFILE");
export const getUserProfileActions = createGenericActions(getUserProfileTypes);

// admin collaborator
export const getCollaboratorInvitationsTypes = createGenericTypes(
  "GET_COLLABORATOR_INVITATIONS"
);
export const getCollaboratorInvitationsActions = createGenericActions(
  getCollaboratorInvitationsTypes
);

export const inviteCollaboratorTypes = createGenericTypes(
  "INVITE_COLLABORATOR"
);
export const inviteCollaboratorActions = createGenericActions(
  inviteCollaboratorTypes
);

export const cancelInviteCollaboratorTypes = createGenericTypes(
  "CANCEL_COLLABORATOR"
);
export const cancelInviteCollaboratorActions = createGenericActions(
  cancelInviteCollaboratorTypes
);

export const revokeInviteCollaboratorTypes = createGenericTypes(
  "REVOKE_COLLABORATOR"
);
export const revokeInviteCollaboratorActions = createGenericActions(
  revokeInviteCollaboratorTypes
);

export const getCollaboratorProfileTypes = createGenericTypes(
  "GET_COLLABORATOR_PROFILE"
);
export const getCollaboratorProfileActions = createGenericActions(
  getCollaboratorProfileTypes
);
export const updateCollaboratorProfileTypes = createGenericTypes(
  "UPDATE_CONSUMER_PROFILE"
);
export const updateCollaboratorProfileActions = createGenericActions(
  updateCollaboratorProfileTypes
);

export const acceptCollaboratorInviteTypes = createGenericTypes(
  "ACCEPT_COLLABORATOR_INVITE"
);
export const acceptCollaboratorInviteActions = createGenericActions(
  acceptCollaboratorInviteTypes
);

export const signInWithGoogleTypes = createGenericTypes("SIGN_IN_GOOGLE");
export const signInWithGoogleActions = createGenericActions(
  signInWithGoogleTypes
);
