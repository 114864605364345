import { createGenericTypes, createGenericActions } from "utils/createAction";

export const getListSpecialRequestsTypes = createGenericTypes(
  "GET_LIST_SPECIAL_REQUESTS"
);
export const getListSpecialRequestsActions = createGenericActions(
  getListSpecialRequestsTypes
);

export const getSpecialRequestByIdTypes = createGenericTypes(
  "GET_SPECIAL_REQUEST_DETAIL_BY_ID"
);
export const getSpecialRequestByIdActions = createGenericActions(
  getSpecialRequestByIdTypes
);

export const acceptSpecialRequestTypes = createGenericTypes(
  "ACCEPT_SPECIAL_REQUEST"
);
export const acceptSpecialRequestActions = createGenericActions(
  acceptSpecialRequestTypes
);

export const declineSpecialRequestTypes = createGenericTypes(
  "DECLINE_SPECIAL_REQUEST"
);
export const declineSpecialRequestActions = createGenericActions(
  declineSpecialRequestTypes
);
