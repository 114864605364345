import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "typed-redux-saga";
import { selectUserId } from "redux/User/selector";
import {
  experience1To1Service,
  experienceLiveClassService,
  exclusiveContentService,
  courseService,
  experienceService,
  experienceInteractiveLiveClassService,
} from "services";
import {
  createExperience1To1Actions,
  getExperiencesActions,
  updateExperience1To1Actions,
  getExperienceByIdActions,
  publishExperienceActions,
  updateExperienceLiveClassActions,
  createExperienceLiveClassActions,
  createExperienceInteractiveLiveClassActions,
  updateExperienceInteractiveLiveClassActions,
  updateExclusiveContentActions,
  createExclusiveContentActions,
  createExperienceCourseActions,
  updateExperienceCourseActions,
  getTrendingExperiencesActions,
  bulkUpdateExperiencesActions,
  getTrendingCategoryExperiencesActions,
  getExperiencesInCategoryActions,
} from "./actions";
import {
  Contents1To1Request,
  EXPERIENCE_TYPE,
  GetExperiencePayload,
  UpdateExperience1To1Request,
  GetExperienceByIdRequest,
  PublishExperienceRequest,
  Experience,
} from "./types";
import { selectExperienceDetailId } from "./selector";
import { ListQueryParams } from "redux/Common/types";

function* getExperiences({ payload }: Action<GetExperiencePayload>) {
  let result: any;

  const userId = yield* select(selectUserId);

  switch (payload.type) {
    case EXPERIENCE_TYPE.ONE_TO_ONE:
      result = yield* call(
        experience1To1Service.getExperiences as any,
        userId,
        payload.params
      );
      break;
    case EXPERIENCE_TYPE.LIVE_CLASS:
      result = yield* call(
        experienceLiveClassService.getExperiences as any,
        userId,
        payload.params
      );
      break;
    case EXPERIENCE_TYPE.INTERACTIVE_LIVE_CLASS:
      result = yield* call(
        experienceInteractiveLiveClassService.getExperiences as any,
        userId,
        payload.params
      );
      break;
    case EXPERIENCE_TYPE.EXCLUSIVE_CONTENT:
      result = yield* call(
        exclusiveContentService.getExperiences as any,
        userId,
        payload.params
      );
      break;
    case EXPERIENCE_TYPE.COURSE:
      result = yield* call(
        courseService.getExperiences as any,
        userId,
        payload.params
      );
      break;

    default:
      result = yield* call(
        experience1To1Service.getAllExperiences as any,
        userId,
        payload.params
      );
      break;
  }

  if (result?.ok) {
    yield* put(getExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(getExperiencesActions.FAILURE(result.message));
  }
}

function* getExperiencesInCategory({ payload }: Action<GetExperiencePayload>) {
  let result: any;

  const userId = yield* select(selectUserId);

  result = yield* call(
    experience1To1Service.getAllExperiences as any,
    userId,
    payload.params
  );

  if (result?.ok) {
    yield* put(getExperiencesInCategoryActions.SUCCESS(result.response));
  } else {
    yield* put(getExperiencesInCategoryActions.FAILURE(result.message));
  }
}

function* getTrendingExperiences() {
  let result: any;

  const userId = yield* select(selectUserId);

  result = yield* call(experience1To1Service.getAllExperiences as any, userId, {
    page: 1,
    limit: 100,
    trending: true,
  });

  if (result?.ok) {
    yield* put(getTrendingExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(getTrendingExperiencesActions.FAILURE(result.message));
  }
}

function* getTrendingCategoryExperiences({ payload }: Action<ListQueryParams>) {
  let result: any;

  const userId = yield* select(selectUserId);

  result = yield* call(
    experience1To1Service.getAllExperiences as any,
    userId,
    payload
  );

  if (result?.ok) {
    yield* put(getTrendingCategoryExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(getTrendingCategoryExperiencesActions.FAILURE(result.message));
  }
}

function* getExperienceById({ payload }: Action<string>) {
  const requestData: GetExperienceByIdRequest = {
    expId: payload,
  };

  const result: any = yield* call(
    experience1To1Service.getExperienceById,
    requestData
  );
  if (result.ok) {
    yield* put(getExperienceByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getExperienceByIdActions.FAILURE(result.message));
  }
}

function* createExperience1To1({ payload }: Action<Contents1To1Request>) {
  const id = yield* select(selectExperienceDetailId);
  let result: any;

  if (id)
    result = yield* call(experience1To1Service.updateExperience, {
      requestBody: payload,
      id,
    });
  else result = yield* call(experience1To1Service.createExperience, payload);

  if (result.ok && id) {
    yield* put(updateExperience1To1Actions.SUCCESS(result.response));
  } else if (result.ok) {
    yield* put(createExperience1To1Actions.SUCCESS(result.response));
  } else {
    yield* put(createExperience1To1Actions.FAILURE(result.message));
  }
}

function* updateExperience1To1({
  payload,
}: Action<UpdateExperience1To1Request>) {
  const result: any = yield* call(
    experience1To1Service.updateExperience,
    payload
  );
  if (result.ok) {
    yield* put(updateExperience1To1Actions.SUCCESS(result.response));
  } else {
    yield* put(updateExperience1To1Actions.FAILURE(result.message));
  }
}

function* createExperienceLiveClass({ payload }: Action<Contents1To1Request>) {
  const id = yield* select(selectExperienceDetailId);
  let result: any;

  if (id)
    result = yield* call(experienceLiveClassService.updateExperience, {
      requestBody: payload,
      id,
    });
  else
    result = yield* call(experienceLiveClassService.createExperience, payload);

  if (result.ok && id) {
    yield* put(updateExperienceLiveClassActions.SUCCESS(result.response));
  } else if (result.ok) {
    yield* put(createExperienceLiveClassActions.SUCCESS(result.response));
  } else {
    yield* put(createExperienceLiveClassActions.FAILURE(result.message));
  }
}

function* updateExperienceLiveClass({
  payload,
}: Action<UpdateExperience1To1Request>) {
  const result: any = yield* call(
    experienceLiveClassService.updateExperience,
    payload
  );
  if (result.ok) {
    yield* put(updateExperienceLiveClassActions.SUCCESS(result.response));
  } else {
    yield* put(updateExperienceLiveClassActions.FAILURE(result.message));
  }
}

function* createExclusiveContent({ payload }: Action<Contents1To1Request>) {
  const id = yield* select(selectExperienceDetailId);
  let result: any;

  if (id)
    result = yield* call(exclusiveContentService.updateExperience, {
      requestBody: payload,
      id,
    });
  else result = yield* call(exclusiveContentService.createExperience, payload);

  if (result.ok && id) {
    yield* put(updateExclusiveContentActions.SUCCESS(result.response));
  } else if (result.ok) {
    yield* put(createExclusiveContentActions.SUCCESS(result.response));
  } else {
    yield* put(createExclusiveContentActions.FAILURE(result.message));
  }
}

function* createExperienceInteractiveLiveClass({
  payload,
}: Action<Contents1To1Request>) {
  const id = yield* select(selectExperienceDetailId);
  let result: any;

  if (id)
    result = yield* call(
      experienceInteractiveLiveClassService.updateExperience,
      {
        requestBody: { ...payload, interactive: true },
        id,
      }
    );
  else
    result = yield* call(
      experienceInteractiveLiveClassService.createExperience,
      {
        ...payload,
        interactive: true,
      }
    );

  if (result.ok && id) {
    yield* put(
      updateExperienceInteractiveLiveClassActions.SUCCESS(result.response)
    );
  } else if (result.ok) {
    yield* put(
      createExperienceInteractiveLiveClassActions.SUCCESS(result.response)
    );
  } else {
    yield* put(
      createExperienceInteractiveLiveClassActions.FAILURE(result.message)
    );
  }
}

function* updateExperienceInteractiveLiveClass({
  payload,
}: Action<UpdateExperience1To1Request>) {
  const result: any = yield* call(
    experienceInteractiveLiveClassService.updateExperience,
    payload
  );
  if (result.ok) {
    yield* put(
      updateExperienceInteractiveLiveClassActions.SUCCESS(result.response)
    );
  } else {
    yield* put(
      updateExperienceInteractiveLiveClassActions.FAILURE(result.message)
    );
  }
}

function* updateExclusiveContent({
  payload,
}: Action<UpdateExperience1To1Request>) {
  const result: any = yield* call(
    exclusiveContentService.updateExperience,
    payload
  );
  if (result.ok) {
    yield* put(updateExclusiveContentActions.SUCCESS(result.response));
  } else {
    yield* put(updateExclusiveContentActions.FAILURE(result.message));
  }
}

function* createExperienceCourse({ payload }: Action<Contents1To1Request>) {
  const id = yield* select(selectExperienceDetailId);
  let result: any;

  if (id)
    result = yield* call(courseService.updateExperience, {
      requestBody: payload,
      id,
    });
  else result = yield* call(courseService.createExperience, payload);

  if (result.ok && id) {
    yield* put(updateExperienceCourseActions.SUCCESS(result.response));
  } else if (result.ok) {
    yield* put(createExperienceCourseActions.SUCCESS(result.response));
  } else {
    yield* put(createExperienceCourseActions.FAILURE(result.message));
  }
}

function* updateExperienceCourse({
  payload,
}: Action<UpdateExperience1To1Request>) {
  const result: any = yield* call(courseService.updateExperience, payload);
  if (result.ok) {
    yield* put(updateExperienceCourseActions.SUCCESS(result.response));
  } else {
    yield* put(updateExperienceCourseActions.FAILURE(result.message));
  }
}

function* publishExperience({ payload }: Action<PublishExperienceRequest>) {
  let result: any;

  switch (payload.type) {
    case EXPERIENCE_TYPE.ONE_TO_ONE:
      result = yield* call(experience1To1Service.publishExperience, payload.id);
      break;
    case EXPERIENCE_TYPE.LIVE_CLASS:
      result = yield* call(
        experienceLiveClassService.publishExperience,
        payload.id
      );
      break;
    case EXPERIENCE_TYPE.INTERACTIVE_LIVE_CLASS:
      result = yield* call(
        experienceInteractiveLiveClassService.publishExperience,
        payload.id
      );
      break;
    case EXPERIENCE_TYPE.EXCLUSIVE_CONTENT:
      result = yield* call(
        exclusiveContentService.publishExperience,
        payload.id
      );
      break;
    case EXPERIENCE_TYPE.COURSE:
      result = yield* call(courseService.publishExperience, payload.id);
      break;
  }

  if (result.ok) {
    yield* put(publishExperienceActions.SUCCESS(result.response));
  } else {
    yield* put(publishExperienceActions.FAILURE(result?.message || "error"));
  }
}

function* bulkUpdateExperiences({ payload }: Action<Experience[]>) {
  const result: any = yield* call(
    experienceService.bulkUpdateExperiences,
    payload
  );
  if (result.ok) {
    yield* put(bulkUpdateExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(bulkUpdateExperiencesActions.FAILURE(result.message));
  }
}

export default function* experienceSagas(): SagaIterator {
  yield* takeLatest(getExperiencesActions.REQUEST, getExperiences);
  yield* takeLatest(
    getExperiencesInCategoryActions.REQUEST,
    getExperiencesInCategory
  );
  yield* takeLatest(
    getTrendingExperiencesActions.REQUEST,
    getTrendingExperiences
  );
  yield* takeLatest(
    getTrendingCategoryExperiencesActions.REQUEST,
    getTrendingCategoryExperiences
  );
  yield* takeLatest(getExperienceByIdActions.REQUEST, getExperienceById);
  // 1 to 1
  yield* takeLatest(createExperience1To1Actions.REQUEST, createExperience1To1);
  yield* takeLatest(updateExperience1To1Actions.REQUEST, updateExperience1To1);
  // live class
  yield* takeLatest(
    createExperienceLiveClassActions.REQUEST,
    createExperienceLiveClass
  );
  yield* takeLatest(
    updateExperienceLiveClassActions.REQUEST,
    updateExperienceLiveClass
  );
  // interactive live class
  yield* takeLatest(
    createExperienceInteractiveLiveClassActions.REQUEST,
    createExperienceInteractiveLiveClass
  );
  yield* takeLatest(
    updateExperienceInteractiveLiveClassActions.REQUEST,
    updateExperienceInteractiveLiveClass
  );
  // exclusive content
  yield* takeLatest(
    createExclusiveContentActions.REQUEST,
    createExclusiveContent
  );
  yield* takeLatest(
    updateExclusiveContentActions.REQUEST,
    updateExclusiveContent
  );
  // course
  yield* takeLatest(
    createExperienceCourseActions.REQUEST,
    createExperienceCourse
  );
  yield* takeLatest(
    updateExperienceCourseActions.REQUEST,
    updateExperienceCourse
  );
  yield* takeLatest(publishExperienceActions.REQUEST, publishExperience);
  yield* takeLatest(
    bulkUpdateExperiencesActions.REQUEST,
    bulkUpdateExperiences
  );
}
