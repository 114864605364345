import { Response } from "./../redux/Common/types";
import { Api } from "./api";

interface IIAPService {
  getIAPSkus(): Promise<Response<any>>;
}

export default class IAPService implements IIAPService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getIAPSkus = (): Promise<Response<any>> => {
    return this.api.getIAPSkus();
  };
}
