import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./locales/en.json";

const namespace = "translation";

// the translations
const resources = {
  en: {
    translation: en,
  },
};

export function initI18n() {
  i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: false,
      resources,
      whitelist: Object.keys(resources),
      react: {
        useSuspense: false,
      },
      fallbackLng: "en",
      saveMissing: true,
      ns: namespace,
      defaultNS: namespace,
      nsSeparator: false,
      interpolation: {
        escapeValue: false,
      },
    });
}

export const changeLanguage = (lang: string) => {
  i18next.changeLanguage(lang);
};

export const getLanguage = () => i18next.language;

export const i18n = initI18n();

export default i18n;
