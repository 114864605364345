import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
// import logger from "redux-logger";

import mySaga from "./sagas";
import reducer from "./rootReducer";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
// const middleware =
//   process.env.NODE_ENV === "development"
//     ? applyMiddleware(sagaMiddleware, logger)
//     : applyMiddleware(sagaMiddleware)
const middleware = applyMiddleware(sagaMiddleware);

// mount it on the Store
const store = createStore(reducer, middleware);

// then run the saga
sagaMiddleware.run(mySaga);

export default store;
