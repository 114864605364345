import {
  EXPERIENCE_TYPE,
  Contents1To1Request,
  Experience,
  UpdateExperience1To1Request,
  GetExperienceByIdRequest,
} from "./../../redux/Experience/types";
import { ApiResponse, ApisauceInstance, create } from "apisauce";
import { ActivationCodeType } from "redux/ActivationCode/types";
import { ApplicationType } from "redux/Application/types";
import {
  Booking,
  GetBookingRequest,
  RejectBookingRequest,
} from "redux/Booking/types";
import { Category, CreateCategoryRequest } from "redux/Category/types";
import { ListQueryParams, Response, SearchResult } from "redux/Common/types";
import { CreateTalentData, Talent, TalentAnalytics } from "redux/Talent/types";
import {
  AcceptCollaboratorInvitationRequest,
  CancelInviteCollaboratorRequest,
  CheckUserExistedRequest,
  CheckUserExistedResult,
  InviteCollaboratorRequest,
  RevokeInviteCollaboratorRequest,
  User,
} from "redux/User/types";
import { authService } from "../index";
import { CreateActivationCodeType } from "./../../redux/ActivationCode/types";
import { Pagination } from "./../../redux/Common/types";
import { SignInRequest, SignInResponse } from "./../../redux/User/types";
import { ApiConfig, DEFAULT_API_CONFIG } from "./api.config";
import { getGeneralApiProblem } from "./apiProblem";
import {
  GetRefundsRequest,
  Refund,
  DeclineRefundRequest,
} from "redux/Refund/types";
import { GetPayoutsRequest, Payout } from "redux/Payout/types";
import { ConsumerAnalytics } from "redux/Consumer/types";
import { CouponType, CreateCouponType } from "redux/Coupon/types";
import {
  SpecialRequest,
  DeclineSpecialRequest,
} from "redux/SpecialRequest/types";
import { Product } from "redux/Product/types";
import {
  CreateTimeRangeRequest,
  CopyTimeRangeRequest,
  UpdateTimeRangeRequest,
  CreateDateOffRequest,
  DeleteTimeRangeRequest,
  DeleteDateOffRequest,
  DeleteWeekDayRequest,
  RepeatTimeRangeRequest,
} from "redux/TimeRange/types";
import config from "config";
import { KOMI_CSRF } from "services/AuthService";
import Cookies from "js-cookie";

export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauceSecure: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.apisauceSecure = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
      },
      withCredentials: true,
    });
  }

  /**
   * Get instance of apisauceSecure.
   */
  get instanceSecure(): ApisauceInstance {
    return this.apisauceSecure;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    this.apisauceSecure.axiosInstance.interceptors.request.use(
      async (options) => {
        const configValue = options;
        const csrfToken = Cookies.get(KOMI_CSRF);

        configValue.headers = {
          ...configValue.headers,
          "x-service-name": config.service.name,
          "x-service-version": config.service.version,
          "x-komi-csrf": csrfToken ? csrfToken : null,
        }

        return configValue;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  }

  private requestAPI<P, R>(
    url: string,
    method: "get" | "delete" | "delete-payload" | "put" | "post" | "patch"
  ) {
    return async (payload?: P): Promise<Response<R>> => {
      let response: ApiResponse<any>;
      switch (method) {
        case "get":
          response = await this.apisauceSecure.get(url, payload);
          break;
        case "post":
          response = await this.apisauceSecure.post(url, payload);
          break;
        case "put":
          response = await this.apisauceSecure.put(url, payload);
          break;
        case "delete":
          response = await this.apisauceSecure.delete(url, payload);
          break;
        case "delete-payload":
          response = await this.apisauceSecure.delete(
            url,
            {},
            { data: payload }
          );
          break;
        case "patch":
          response = await this.apisauceSecure.patch(url, payload);
          break;
        default:
          response = await this.apisauceSecure.get(url, payload);
      }
      if (!response.ok) {
        return getGeneralApiProblem(response);
      }

      return { ok: response.ok, response: response.data };
    };
  }
  async postRequest<P = any | undefined, R = any>(
    url: string,
    payload?: P
  ): Promise<Response<R>> {
    return this.requestAPI<P, R>(url, "post")(payload);
  }

  getRequest = async <P, R = any>(
    url: string,
    payload?: P
  ): Promise<Response<R>> => {
    return this.requestAPI<P, R>(url, "get")(payload);
  };
  deleteRequestWithPayload<P, R = any>(
    url: string,
    payload?: P
  ): Promise<Response<R>> {
    return this.requestAPI<P, R>(url, "delete-payload")(payload);
  }

  async putRequest<P, R = any>(url: string, payload?: P): Promise<Response<R>> {
    return this.requestAPI<P, R>(url, "put")(payload);
  }

  async patchRequest<P, R = any>(
    url: string,
    payload?: P
  ): Promise<Response<R>> {
    return this.requestAPI<P, R>(url, "patch")(payload);
  }

  async deleteRequest<P, R = any>(
    url: string,
    payload?: P
  ): Promise<Response<R>> {
    return this.requestAPI<P, R>(url, "delete")(payload);
  }

  setAuthToken(token: string): void {
    this.apisauceSecure.setHeader("Authorization", `Bearer ${token}`);
  }

  removeAuthToken(): void {
    this.apisauceSecure.deleteHeader("Authorization");
  }

  async signin(payload: SignInRequest): Promise<Response<SignInResponse>> {
    const response: ApiResponse<any> = await this.apisauceSecure.post(
      `/auth/admin/login`,
      payload
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async signout(): Promise<Response<any>> {
    const response: ApiResponse<any> = await this.apisauceSecure.post(
      `/auth/logout`,
      {}
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async getAccessToken(id: string): Promise<Response<SignInResponse>> {
    const response: ApiResponse<any> = await this.apisauceSecure.get(
      `/auth/admin/talents/${id}/credentials`
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async getUserProfile(): Promise<Response<User>> {
    const response: ApiResponse<any> = await this.apisauceSecure.get(
      `/users/me`
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async getTalentById(id: string): Promise<Response<Talent>> {
    const response: ApiResponse<any> = await this.apisauceSecure.get(
      `/users/${id}`
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async bulkUpdateTalents(
    payload: Talent["talentProfile"][]
  ): Promise<Response<Talent["talentProfile"][]>> {
    const response: ApiResponse<any> = await this.apisauceSecure.put(
      `/talent-profiles/bulk-update`,
      payload
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async getTalentAnalytics(id: string): Promise<Response<TalentAnalytics>> {
    const response: ApiResponse<any> = await this.apisauceSecure.get(
      `/analytic/${id}/talent`
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async getUserAnalytics(id: User["id"]): Promise<Response<ConsumerAnalytics>> {
    const response: ApiResponse<any> = await this.apisauceSecure.get(
      `/analytic/${id}/consumer`
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async createPresignedUrl(
    fileExtension: string,
    fileName: string,
    contentType: string
  ): Promise<Response<any>> {
    return this.postRequest<{
      fileExtension: string;
      fileName: string;
      contentType: string;
    }>(`/files/presigned-url`, {
      fileExtension,
      fileName,
      contentType,
    });
  }

  async createPresignedPhotoUrl(
    fileExtension: string,
    contentType: string
  ): Promise<Response<any>> {
    return this.postRequest<{ fileExtension: string; contentType: string }>(
      `/files/photos/presigned-url`,
      { fileExtension, contentType }
    );
  }

  async createPresignedVideoUrl(fileExtension: string): Promise<Response<any>> {
    return this.postRequest<{ fileExtension: string }>(
      `/files/videos/presigned-url`,
      { fileExtension }
    );
  }

  async handleSearch(keyword: string): Promise<Response<SearchResult>> {
    return this.getRequest(`/search?term=${keyword || ""}`);
  }

  async getCoupons(
    params: ListQueryParams
  ): Promise<Response<Pagination<CouponType[]>>> {
    const response: ApiResponse<any> = await this.apisauceSecure.get(
      `/coupons-codes`,
      params
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async getUsersOfCoupon(
    params: ListQueryParams
  ): Promise<Response<Pagination<User[]>>> {
    const response: ApiResponse<any> = await this.apisauceSecure.get(
      `/activation-codes/talents`,
      params
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async getCouponById(id: string): Promise<Response<CouponType>> {
    const response: ApiResponse<any> = await this.apisauceSecure.get(
      `/coupon-codes/${id}`
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async createCoupon(payload: CreateCouponType): Promise<Response<CouponType>> {
    const response: ApiResponse<any> = await this.apisauceSecure.post(
      `/coupons-codes`,
      payload
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async updateCouponById(
    payload: CreateCouponType
  ): Promise<Response<CouponType>> {
    const response: ApiResponse<any> = await this.apisauceSecure.put(
      `/coupons-codes/${payload.id}`,
      payload
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async getTalentsOfActivationCode(
    params: ListQueryParams
  ): Promise<Response<Pagination<Talent[]>>> {
    const response: ApiResponse<any> = await this.apisauceSecure.get(
      `/activation-codes/talents`,
      params
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async getActivationCodes(
    params: ListQueryParams
  ): Promise<Response<Pagination<ActivationCodeType[]>>> {
    const response: ApiResponse<any> = await this.apisauceSecure.get(
      `/activation-codes`,
      params
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async getActivationCodeById(
    id: string
  ): Promise<Response<ActivationCodeType>> {
    const response: ApiResponse<any> = await this.apisauceSecure.get(
      `/activation-codes/${id}`
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async createActivationCode(
    payload: CreateActivationCodeType
  ): Promise<Response<ActivationCodeType>> {
    const response: ApiResponse<any> = await this.apisauceSecure.post(
      `/activation-codes`,
      payload
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async updateActivationCodeById(
    payload: ActivationCodeType
  ): Promise<Response<ActivationCodeType>> {
    const { id, ...rest } = payload;
    const response: ApiResponse<any> = await this.apisauceSecure.patch(
      `/activation-codes/${id}`,
      rest
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async getApplications(
    params: ListQueryParams
  ): Promise<Response<Pagination<ApplicationType[]>>> {
    const response: ApiResponse<any> = await this.apisauceSecure.get(
      `/talent-profiles`,
      params
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async getTalents(
    params: ListQueryParams
  ): Promise<Response<Pagination<Talent[]>>> {
    const response: ApiResponse<any> = await this.apisauceSecure.get(
      `/users/talents`,
      params
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }
  async createTalent(data: CreateTalentData): Promise<Response<Talent>> {
    const response: ApiResponse<any> = await this.apisauceSecure.post(
      `/users/talents`,
      data
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }
  async deleteTalent(id: string): Promise<Response<any>> {
    const response: ApiResponse<any> = await this.apisauceSecure.delete(
      `/users/talents/${id}`
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async getApplicationById(id: string): Promise<Response<Talent>> {
    const response: ApiResponse<any> = await this.apisauceSecure.get(
      `/talent-profiles/${id}`
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async updateApplicationById(
    payload: ApplicationType
  ): Promise<Response<ApplicationType>> {
    const { id, ...rest } = payload;
    const response: ApiResponse<any> = await this.apisauceSecure.patch(
      `/talent-profiles/${id}`,
      rest
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async getCategories(
    params: ListQueryParams
  ): Promise<Response<Pagination<Category[]>>> {
    const response: ApiResponse<any> = await this.apisauceSecure.get(
      `/categories`,
      params
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async createCategory(
    payload: CreateCategoryRequest
  ): Promise<Response<Category>> {
    const response: ApiResponse<any> = await this.apisauceSecure.post(
      `/categories`,
      payload
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async bulkUpdateCategories(
    payload: Category[]
  ): Promise<Response<Category[]>> {
    const response: ApiResponse<any> = await this.apisauceSecure.put(
      `/categories/bulk-update`,
      payload
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async updateCategory(payload: Category): Promise<Response<Category>> {
    const { id, ...rest } = payload;
    const response: ApiResponse<any> = await this.apisauceSecure.patch(
      `/categories/${id}`,
      rest
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async deleteCategory(id: Category["id"]): Promise<Response<Category>> {
    const response: ApiResponse<any> = await this.apisauceSecure.delete(
      `/categories/${id}`
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async getBookings1To1(
    payload: GetBookingRequest
  ): Promise<Response<Pagination<Booking[]>>> {
    const { userId, ...rest } = payload;
    return this.getRequest(`/bookings`, rest);
  }

  async getBooking1To1ById(id: string): Promise<Response<Booking>> {
    return this.getRequest(`/bookings/${id}`);
  }

  async getConsumers(
    params: ListQueryParams
  ): Promise<Response<Pagination<User[]>>> {
    return this.getRequest(`/users/consumers`, params);
  }

  async getConsumerById(id: string): Promise<Response<User>> {
    return this.getRequest(`/users/consumers/${id}`);
  }

  async updateConsumerById(payload: User): Promise<Response<User>> {
    const { id, ...rest } = payload;
    return this.patchRequest(`/users/consumers/${id}`, rest);
  }

  async rejectBooking(
    payload: RejectBookingRequest
  ): Promise<Response<Booking>> {
    const { bookingId, notes } = payload;

    return this.postRequest(`bookings/${bookingId}/reject`, { notes });
  }

  async getListRefunds(
    params: GetRefundsRequest
  ): Promise<Response<Pagination<Refund[]>>> {
    const { userId, ...rest } = params;
    return this.getRequest(`/refund-requests`, rest);
  }

  async getRefundById(id: string): Promise<Response<Refund>> {
    return this.getRequest(`/refund-requests/${id}`);
  }

  async acceptRefund(id: string): Promise<Response<Refund>> {
    return this.putRequest(`/refund-requests/${id}/accept`);
  }

  async declineRefund(
    payload: DeclineRefundRequest
  ): Promise<Response<Refund>> {
    return this.putRequest(`/refund-requests/${payload.id}/declined`, {
      declinedReason: payload.reason,
    });
  }

  async getListSpecialRequests(
    params: GetRefundsRequest
  ): Promise<Response<Pagination<SpecialRequest[]>>> {
    const { userId, ...rest } = params;
    return this.getRequest(`/bookings`, {
      ...rest,
      type: [EXPERIENCE_TYPE.SPECIAL_REQUEST],
    });
  }

  async updateBooking(payload: Booking): Promise<Response<Booking>> {
    const { id } = payload;
    return this.putRequest(`/bookings/${id}`, payload);
  }

  async getSpecialRequestById(id: string): Promise<Response<SpecialRequest>> {
    return this.getRequest(`/bookings/${id}`);
  }

  async acceptSpecialRequest(id: string): Promise<Response<SpecialRequest>> {
    return this.putRequest(`/special-requests/${id}/accept`);
  }

  async declineSpecialRequest(
    payload: DeclineSpecialRequest
  ): Promise<Response<SpecialRequest>> {
    return this.putRequest(`/special-requests/${payload.id}/declined`, {
      declinedReason: payload.reason,
    });
  }

  async getListPayout(
    params: GetPayoutsRequest
  ): Promise<Response<Pagination<Payout[]>>> {
    return this.getRequest(`/payouts/requests`, params);
  }

  async getPayoutById(id: string): Promise<Response<Payout>> {
    return this.getRequest(`/payouts/requests/${id}`);
  }

  async updatePayoutById(payload: Payout): Promise<Response<Payout>> {
    const { id, status, note } = payload;
    const data = note ? { status, note } : { status };
    return this.putRequest(`/payouts/requests/${id}`, data);
  }

  async getExperiences(
    type: EXPERIENCE_TYPE | null,
    userId: string,
    params: ListQueryParams
  ): Promise<Response<Experience[]>> {
    return this.getRequest<ListQueryParams & { type: EXPERIENCE_TYPE | null }>(
      `/experiences`,
      {
        ...params,
        type,
      }
    );
  }

  async getExperienceById(
    payload: GetExperienceByIdRequest
  ): Promise<Response<Experience>> {
    return this.getRequest<GetExperienceByIdRequest>(
      `/experiences/${payload.expId}`
    );
  }

  async bulkUpdateExperiences(
    payload: Experience[]
  ): Promise<Response<Experience[]>> {
    const response: ApiResponse<any> = await this.apisauceSecure.put(
      `/experiences/bulk-update`,
      payload
    );
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async createExperience1To1(
    payload: Contents1To1Request
  ): Promise<Response<Experience>> {
    return this.postRequest<Contents1To1Request>(
      "/experiences/one-to-one-tutorials",
      payload
    );
  }

  async updateExperience1To1(
    payload: UpdateExperience1To1Request
  ): Promise<Response<Experience>> {
    return this.patchRequest<Contents1To1Request>(
      `/experiences/one-to-one-tutorials/${payload.id}`,
      payload.requestBody
    );
  }

  async publishExperience1To1(payload: string): Promise<Response<any>> {
    return this.patchRequest<Contents1To1Request>(
      `/experiences/${payload}/publish`
    );
  }

  async createCourse(
    payload: Contents1To1Request
  ): Promise<Response<Experience>> {
    return this.postRequest<Contents1To1Request>(
      "/experiences/courses",
      payload
    );
  }

  async updateCourse(
    payload: UpdateExperience1To1Request
  ): Promise<Response<Experience>> {
    return this.patchRequest<Contents1To1Request>(
      `/experiences/courses/${payload.id}`,
      payload.requestBody
    );
  }

  async publishCourse(payload: string): Promise<Response<any>> {
    return this.patchRequest<Contents1To1Request>(
      `/experiences/${payload}/publish`
    );
  }

  async createExperienceLiveClass(
    payload: Contents1To1Request
  ): Promise<Response<Experience>> {
    return this.postRequest<Contents1To1Request>(
      "/experiences/live-classes",
      payload
    );
  }

  async updateExperienceLiveClass(
    payload: UpdateExperience1To1Request
  ): Promise<Response<Experience>> {
    return this.patchRequest<Contents1To1Request>(
      `/experiences/live-classes/${payload.id}`,
      payload.requestBody
    );
  }

  async publishExperienceLiveClass(payload: string): Promise<Response<any>> {
    return this.patchRequest<Contents1To1Request>(
      `/experiences/${payload}/publish`
    );
  }

  async createExclusiveContent(
    payload: Contents1To1Request
  ): Promise<Response<Experience>> {
    return this.postRequest<Contents1To1Request>(
      "/experiences/exclusive-contents",
      payload
    );
  }

  async updateExclusiveContent(
    payload: UpdateExperience1To1Request
  ): Promise<Response<Experience>> {
    return this.patchRequest<Contents1To1Request>(
      `/experiences/exclusive-contents/${payload.id}`,
      payload.requestBody
    );
  }

  async publishExclusiveContent(payload: string): Promise<Response<any>> {
    return this.patchRequest<Contents1To1Request>(
      `/experiences/${payload}/publish`
    );
  }

  async createProduct(payload: Product): Promise<Response<any>> {
    return this.postRequest("/products", payload);
  }

  async updateProduct(
    productId: string,
    payload: Product
  ): Promise<Response<any>> {
    return this.patchRequest(`/products/${productId}`, payload);
  }

  async getProductById(productId: string): Promise<Response<any>> {
    return this.getRequest(`/products/${productId}`);
  }

  async deleteProductById(productId: string): Promise<Response<any>> {
    return this.deleteRequest(`/products/${productId}`);
  }

  async getTags(
    payload: ListQueryParams & Required<{ search: string }>
  ): Promise<Response<any>> {
    return this.getRequest("tags", payload);
  }

  async createTimeRange(
    payload: CreateTimeRangeRequest
  ): Promise<Response<any>> {
    const { expId, ...rest } = payload;
    return this.postRequest(`/experiences/${expId}/time-ranges`, rest);
  }

  async copyTimeRange(payload: CopyTimeRangeRequest): Promise<Response<any>> {
    const { expId, ...rest } = payload;
    return this.postRequest(`/experiences/${expId}/time-ranges/copy`, rest);
  }

  async updateTimeRange(
    payload: UpdateTimeRangeRequest
  ): Promise<Response<any>> {
    const { expId, id, ...rest } = payload;
    return this.putRequest(`/experiences/${expId}/time-ranges/${id}`, rest);
  }

  async createDateOff(payload: CreateDateOffRequest): Promise<Response<any>> {
    const { expId, ...rest } = payload;
    return this.postRequest(`/experiences/${expId}/date-off`, rest);
  }

  async deleteTimeRange(
    payload: DeleteTimeRangeRequest
  ): Promise<Response<any>> {
    const { expId, id } = payload;
    return this.deleteRequest(`/experiences/${expId}/time-ranges/${id}`);
  }

  async deleteDateOff(payload: DeleteDateOffRequest): Promise<Response<any>> {
    const { expId, dateOffId } = payload;
    return this.deleteRequest(`/experiences/${expId}/date-off/${dateOffId}`);
  }

  async deleteWeekDay(payload: DeleteWeekDayRequest): Promise<Response<any>> {
    const { expId, ...rest } = payload;
    return this.postRequest(
      `/experiences/${expId}/time-ranges/delete-weekday`,
      rest
    );
  }

  async getIAPSkus(): Promise<Response<any>> {
    return this.getRequest(`/transactions/iap/skus`, {
      type: "APPLE_IAP",
    });
  }

  async setRepeatTimeRange(
    payload: RepeatTimeRangeRequest
  ): Promise<Response<any>> {
    const { expId, ...rest } = payload;
    return this.postRequest(
      `/experiences/${expId}/time-ranges/set-repeat`,
      rest
    );
  }

  async removeRepeatTimeRange(
    payload: RepeatTimeRangeRequest
  ): Promise<Response<any>> {
    const { expId, ...rest } = payload;
    return this.putRequest(
      `/experiences/${expId}/time-ranges/remove-repeat`,
      rest
    );
  }
  async checkUserExisted(
    params: CheckUserExistedRequest
  ): Promise<Response<CheckUserExistedResult>> {
    return this.getRequest(`users/check-is-existed`, params);
  }
  async getCollaborator(): Promise<Response<any>> {
    return this.getRequest("users/me/admin/collaborator-invitations");
  }

  async inviteCollaborator(
    payload: InviteCollaboratorRequest
  ): Promise<Response<any>> {
    return this.postRequest("users/me/admin/collaborator-invitations", payload);
  }

  async cancelInviteCollaborator(
    payload: CancelInviteCollaboratorRequest
  ): Promise<Response<any>> {
    return this.deleteRequestWithPayload(
      "users/me/admin/collaborator-invitations/cancel",
      payload
    );
  }

  async revokeInviteCollaborator(
    payload: RevokeInviteCollaboratorRequest
  ): Promise<Response<any>> {
    return this.deleteRequestWithPayload(
      "users/me/admin/collaborator-invitations/revoke",
      payload
    );
  }
  async acceptCollaboratorInvitation(
    payload: AcceptCollaboratorInvitationRequest
  ): Promise<Response<any>> {
    return this.putRequest(
      `/users/me/admin/collaborator-invitations/accept`,
      payload
    );
  }

  async signInWithGoogle(accessToken: string): Promise<Response<User>> {
    return this.getRequest(`/auth/admin-google-token/redirect`, {
      access_token: accessToken,
    });
  }
}
