import { createGenericTypes, createGenericActions } from "utils/createAction";

export const getConsumersTypes = createGenericTypes("GET_CONSUMERS");
export const getConsumersActions = createGenericActions(getConsumersTypes);

export const getConsumerByIdTypes = createGenericTypes("GET_CONSUMER_BY_ID");
export const getConsumerByIdActions = createGenericActions(
  getConsumerByIdTypes
);

export const updateConsumerTypes = createGenericTypes("UPDATE_CONSUMER");
export const updateConsumerActions = createGenericActions(updateConsumerTypes);

export const getConsumerAnalyticsTypes = createGenericTypes(
  "GET_CONSUMER_ANALYTICS"
);
export const getConsumerAnalyticsActions = createGenericActions(
  getConsumerAnalyticsTypes
);
