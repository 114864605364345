import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { ListQueryParams } from "redux/Common/types";
import {
  getConsumersActions,
  getConsumerByIdActions,
  updateConsumerActions,
  getConsumerAnalyticsActions,
} from "./actions";
import { userService } from "services";
import { User } from "redux/User/types";

function* getConsumers({ payload }: Action<ListQueryParams>) {
  const result: any = yield* call(userService.getConsumers, payload);
  if (result.ok) {
    yield* put(getConsumersActions.SUCCESS(result.response));
  } else {
    yield* put(getConsumersActions.FAILURE(result.message));
  }
}

function* getConsumerById({ payload }: Action<string>) {
  const result: any = yield* call(userService.getConsumerById, payload);
  if (result.ok) {
    yield* put(getConsumerByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getConsumerByIdActions.FAILURE(result.message));
  }
}

function* updateConsumer({ payload }: Action<User>) {
  const result: any = yield* call(userService.updateConsumer, payload);
  if (result.ok) {
    yield* put(updateConsumerActions.SUCCESS(result.response));
  } else {
    yield* put(updateConsumerActions.FAILURE(result.message));
  }
}

function* getConsumerAnalytics({ payload }: Action<User["id"]>) {
  const result: any = yield* call(userService.getUserAnalytics, payload);
  if (result.ok) {
    yield* put(getConsumerAnalyticsActions.SUCCESS(result.response));
  } else {
    yield* put(getConsumerAnalyticsActions.FAILURE(result.message));
  }
}

export default function* consumerSagas(): SagaIterator {
  yield* takeLatest(getConsumersActions.REQUEST, getConsumers);
  yield* takeLatest(getConsumerByIdActions.REQUEST, getConsumerById);
  yield* takeLatest(updateConsumerActions.REQUEST, updateConsumer);
  yield* takeLatest(getConsumerAnalyticsActions.REQUEST, getConsumerAnalytics);
}
