import moment from "moment";
import momentTZ from "moment-timezone";
import { DateOff } from "redux/Experience/types";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "a few secs",
    ss: "%d secs",
    m: "a min",
    mm: "%d mins",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

export const date = moment;
export const formatDate = (date: any, format: string) =>
  moment(date).format(format);
export const dayAgo = (date: any) => {
  const now = moment(new Date());
  const end = moment(date); // another date
  const duration = moment.duration(now.diff(end));
  const days = duration.asDays();
  return days;
};

export const formatMinuteSecond = (second: number) => {
  const time = moment.utc(second * 1000);
  return time.hours() > 0 ? time.format("hh:mm:ss") : time.format("mm:ss");
};

export const formatHourMinuteSecond = (second: number) => {
  return moment.utc(second * 1000).format("HH:mm:ss");
};

export const formatMinuteSecondWithUnit = (second: number) => {
  return moment.utc(second * 1000).format("mm[m] ss[s]");
};

export const formatTime = (date: Date) => moment(date).format("hh:mm a");

export const isDateOffs = (
  date: moment.Moment,
  dateOffs: DateOff[]
): DateOff | undefined => {
  const dateTz = momentTZ(date).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  return dateOffs?.find(({ date }) => {
    return moment(date).toISOString() === dateTz.toISOString();
  });
};

export const convertTZ = (date: any, tzString: string) => {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
};
